import React, { useState, useEffect } from "react";
import qs from "qs";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import moment from "moment";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Button, Modal, ModalHeader, ModalBody, Container, Row, Col, ModalFooter, Label, Input, Alert } from "reactstrap";
import Joyride from "react-joyride";
import homes_listed_icon from "../assets/icons/homes_listed.svg";
import closed_icon from "../assets/icons/closed.svg";
import executed_icon from "../assets/icons/executed.svg";
import not_accepted_icon from "../assets/icons/not_accepted.svg";
// import Graph from '../assets/icons/graph.svg';
import received_icon from "../assets/icons/received.svg";
import withdrawn_icon from "../assets/icons/withdrawn.svg";
import LastAcceptedOffer from "components/Offers/LastAcceptedOffer";
import DashboardAllOffers from "components/Offers/DashboardAllOffers";
import { getUsersSellers, getOffers, getDashboardCounts, getSellersById, bestMatchOffers } from "./../Services/SellerService";
import ReactPaginate from "react-paginate";
import firebase from "firebase/app";
import "firebase/firestore";
import "../assets/css/dashboard-page.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import shareOffersTemplate from "../templates/email/share-offers";
import Reminder from "./Reminder";
import { set } from "lodash";
import axios from "axios";
import dummyOffer from "../dummyOffer";

const filterOptions = {
  periodFilter: ["24 Hours", "Last Week", "Last Month", "6 Months", "1 Year"],
  listingPropertyFilter: ["Select property", "Attached", "Attached or Half-duplex", "Condo/Townhome", "Designated Historical Home", "Doublewide Mobile w/Land", "Farm/Ranch House", "Garden/Zero Lot Line", "Hi Rise", "Historical/Conservation Dist.", "Interval Ownership", "Lake House", "Log Cabin", "Manufactured (certificate exch)", "Resort Property", "Single Detached", "Singlewide Mobile w/Land", "Underground", "Vacation Home", "MUL-Apartment/5Plex+", "MUL-Fourplex", "MUL-Full Duplex", "MUL-Multiple Single Units", "MUL-Triplex", "LND-Commercial", "LND-Farm/Ranch", "LND-Residential"],
  listingStatusFilter: ["Select status", "In Review", "Active"],
  offerSortByFilter: ["Select close date", "Shortest Closing", "Longest Closing"],
  offerStatusFilter: ["Select status", "In Review", "Accepted", "Not Accepted", "Withdraw", "Terminated", "Executed", "Seller Review", "Countered"],
  offerAgentFilter: ["Select agent"],
  offerFinanceTypeFilter: ["Select type", "Cash", "Lease", "Conventional", "FHA", "VA", "Seller Finance"],
  listingsPerPageFilter: ["10", "15", "20", "25", "30", "35", "40"],
  offerPurchasePriceFilter: ["Select price", "Highest", "Lowest"],
  offerPropertyFilter: ["Select property"],
  offerBuyerFilter: ["Select buyer"],
};

const sliderSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerMode: false,
  initialSlide: 1,
  responsive: [
    {
      breakpoint: 1224,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

function Dashboard({ match, user, history, location }) {
  const { displayName } = user;
  const [periodFilter, setPeriodFilter] = useState("24 hour");
  const [listingPropertyFilter, setListingPropertyFilter] = useState(filterOptions.listingPropertyFilter[0]);
  const [listingStatusFilter, setListingStatusFilter] = useState(filterOptions.listingStatusFilter[2]);
  const [offerSortByFilter, setOfferSortByFilter] = useState(filterOptions.offerSortByFilter[0]);
  const [offerStatusFilter, setOfferStatusFilter] = useState(filterOptions.offerStatusFilter[0]);
  const [offerAgentFilter, setOfferAgentFilter] = useState(filterOptions.offerAgentFilter[0]);
  const [offerFinanceTypeFilter, setOfferFinanceTypeFilter] = useState(filterOptions.offerFinanceTypeFilter[0]);
  const [offerPurchasePriceFilter, setOfferPurchasePriceFilter] = useState(filterOptions.offerPurchasePriceFilter[0]);
  const [offerPropertyFilter, setOfferPropertyFilter] = useState(filterOptions.offerPropertyFilter[0]);
  const [sellers, setSellers] = useState([]);
  const [offers, setOffers] = useState([]);
  const [allSellersList, setAllSellersList] = useState([]);
  const [acceptedOffersList, setAcceptedOffersList] = useState([]);
  const [offerSellers, setOfferSellers] = useState([]);
  const [allOffersList, setAllOffersList] = useState([]);
  const [chartModal, setChartModal] = useState(false);
  const chartToggle = () => setChartModal(!chartModal);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [paginationOffset, setPaginationOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [listingsPerPageFilter, setListingsPerPageFilter] = useState(10);
  const [agent, setAgent] = useState(null);
  const [offersToShare, setOffersToShare] = useState([]);
  const [shareOffersModel, setShareOffersModel] = useState(false);
  const [shareOfferEmail, setShareOfferEmail] = useState(null);
  const [shareOfferName, setShareOfferName] = useState(null);
  const [sendingOffers, setSendingOffers] = useState(false);
  const [policyModal, setPolicyModal] = useState(false);
  const [stripeModal, setStripeModal] = useState(false);
  const [showStripeAlert, setShowStripeAlert] = useState(true);
  const [showCardFailedAlert, setShowCardFailedAlert] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [joyRide, setJoyRide] = useState(user.hasOwnProperty("dashboardJoyRide") ? user.dashboardJoyRide : true);
  const [options, setOptions] = useState({
    chart: {
      type: "column",
    },
    xAxis: {
      title: {
        text: "Offer",
      },
      labels: {
        align: "left",
      },
      categories: [],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      max: 0,
      title: {
        text: "Cost ($)",
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>${point.y:.1f}</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: "Purchase",
        data: [],
        color: "#90A69B",
      },
      {
        name: "Down payment",
        data: [],
        color: "#E5B536",
      },
      {
        name: "Earnest Money",
        data: [],
        color: "#3745C8",
      },
      {
        name: "Option Fee",
        data: [],
        color: "#038A4B",
      },
    ],
  });
  const [analytics, setAnalytics] = useState({
    homesListed: 0,
    offersReceived: 0,
    homeClosed: 0,
    offersNotAccepted: 0,
    offersWithdrawn: 0,
    offersAccepted: 0,
  });
  const [newUser, setNewUser] = useState({});
  const [steps] = useState([
    {
      target: "#sidebar-dashboard",
      content: (
        <>
          <h5>Dashboard</h5>
          <p>This is Your Dashboard. Here you can see analytics overview, your listings, and your offers.</p>
        </>
      ),
      disableBeacon: true,
      placement: "right",
    },
    {
      target: "#sidebar-analytics",
      content: (
        <>
          <h5>Analytics</h5>
          <p>This is Analytics section. Here you can see detailed analytics, my offers, and global offers. Access Find a Buyers agent from all property listings.</p>
        </>
      ),
      disableBeacon: true,
      placement: "right",
    },
    {
      target: "#sidebar-homes",
      content: (
        <>
          <h5>My Homes for Sale</h5>
          <p>Here are your Active Listings. You can see your Active MLS Listings that's ready to receive offers. View property profile page and Make an Offer button. Use this page to give instruction on make an offer.</p>
        </>
      ),
      disableBeacon: true,
      placement: "right",
    },
    {
      target: "#sidebar-offers",
      content: (
        <>
          <h5>My Offers</h5>
          <p>Here are you offers. You can see all of your offers, or filter them by status, agent, or property. Access multiple offer spreadsheets and Follow My Listing SMS.</p>
        </>
      ),
      disableBeacon: true,
      placement: "right",
    },
  ]);

  const queryParams = new URLSearchParams(location.search);
  const stripeCode = queryParams.get("code");

  const togglePolicyModal = () => {
    setPolicyModal(!policyModal);
  };

  useEffect(() => {
    getAnalyticsData();
    getAnalytics();
    updateGraph();
  }, []);

  useEffect(() => {
    const getSubscriptionDetails = firebase.functions().httpsCallable("getSubscriptionDetails");
    if (user?.subscriptionId) {
      getSubscriptionDetails({
        subscriptionId: user?.subscriptionId,
      }).then((res) => {
        setSubscriptionStatus(res.data.subscription.status);
        if (
          user?.subscriptionId &&
          res.data.subscription.status !== "active" &&
          // new Date(1693793600 * 1000) <= new Date(Date.now())
          new Date(user?.subscriptionEndDate * 1000) < new Date(Date.now())
        ) {
          setShowCardFailedAlert(true);
        }
      });
    }
  }, [user]);

  useEffect(() => {
    const db = firebase.firestore();
    const connectStripeAccount = firebase.functions().httpsCallable("connectStripeAccount");

    if (stripeCode && !user?.stripeAccountId) {
      try {
        connectStripeAccount({
          code: stripeCode,
          id: user?.uid,
        }).then((response) => {
          toast.success(response.data.message);
          db.collection("users")
            .doc(user?.uid)
            .update({
              stripeAccountId: response.data.accountId,
            })
            .then(() => {
              window.location.href = "/dashboard";
            });
        });
      } catch (err) {
        console.log(err);
      }
    }
  }, []);

  useEffect(() => {
    const db = firebase.firestore();
    // let data = qs.stringify({
    //   code: stripeCode,
    //   id: user?.uid,
    // });

    // let config = {
    //   method: "post",
    //   maxBodyLength: Infinity,
    //   url: "http://127.0.0.1:5001/homematchx-test/us-central1/connectStripeAccount",
    //   headers: {
    //     "Content-Type": "application/x-www-form-urlencoded",
    //   },
    //   data,
    // };
    // axios
    //   .request(config)
    //   .then((response) => {
    //     toast.success(response.data.message);
    //     db.collection("users").doc(user?.uid).update({
    //       stripeAccountId: response.data.stripeAccountId,
    //     });
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    // if (stripeConnectAccountID && !newUser?.stripeAccountId) {
    //   // add stripe account id to user
    //   db.collection("users")
    //     .where("user_email", "==", user?.email)
    //     .get()
    //     .then((querySnapshot) => {
    //       querySnapshot.forEach((doc) => {
    //         db.collection("users")
    //           .doc(doc.id)
    //           .update({
    //             stripeAccountId: stripeConnectAccountID,
    //           })
    //           .then(() => {
    //             toast.success("Stripe account connected successfully");
    //           });
    //       });
    //     });
    // }
    db.collection("users")
      .doc(user?.uid)
      .get()
      .then((querySnapshot) => {
        let obj = {
          ...querySnapshot.data(),
          id: querySnapshot.id,
        };
        setNewUser(obj);
      });

    if (!newUser?.id) {
      db.collection("realtors")
        .doc(user?.uid)
        .get()
        .then((querySnapshot) => {
          let obj = {
            ...querySnapshot.data(),
            id: querySnapshot.id,
          };
          setNewUser(obj);
        });
    }
    if (!newUser.initial_popup && !user?.initial_popup) {
      setPolicyModal(true);
    }
  }, [user]);

  const getAnalyticsData = async () => {
    let otherUser = null;
    if (match.params?.uid) {
      const db = firebase.firestore();
      otherUser = await db.collection("realtors").doc(match.params.uid).get();
      const userData = otherUser.data();
      otherUser = {
        ...userData,
        email: userData.realtor_email,
        uid: otherUser.id,
      };

      setAgent(otherUser);
    }

    getUsersSellers(otherUser || user, {
      status: "Active",
    })
      .then((sellers) => {
        setSellers(sellers);
      })
      .catch((error) => {
        console.error(error);
      });

    getOffers(otherUser || user, null, {
      inList: true,
    })
      .then((offers) => {
        setOffers(offers);

        let agents = ["Select agent"];
        offers.forEach((o) => {
          let name = o.agentName;
          if (!agents.includes(name)) agents.push(name);
        });

        filterOptions.offerAgentFilter = agents;
      })
      .catch((error) => {
        console.error(error);
      });

    getOffers(otherUser || user, null, {
      inList: true,
    })
      .then((offers) => {
        setTotalPages(offers.length / listingsPerPageFilter);
        setAllOffersList(offers);
      })
      .catch((err) => {
        console.log("Get All Offers Error: ", err);
      });

    getOffers(otherUser || user, null, {
      status: "Executed",
      inList: true,
    })
      .then(async (offers) => {
        setAcceptedOffersList(offers);
        const seller_ids = offers.map((offer) => offer.seller);
        const sellers = await getSellersById(seller_ids);
        setOfferSellers(sellers);
      })
      .catch((err) => {
        console.log("Get accepted offers error: ", err);
      });
  };

  const handleShareOffers = async () => {
    const filter = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (filter.test(shareOfferEmail)) {
      setSendingOffers(true);
      const sendEmail = firebase.functions().httpsCallable("sendMatchEmail");
      await sendEmail({
        subject: `Offers Shared With You - Homematchx`,
        email: shareOfferEmail,
        body: shareOffersTemplate(offersToShare, shareOfferName),
      });
      setSendingOffers(false);
      toast.success("Selected offers has been shared successfully.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        offersToShare: [],
      });
      setShareOffersModel(false);
    } else {
      alert("Please enter correct email address.");
    }
  };

  const updateGraph = (type) => {
    getOffers(user, null, {
      financeType: type,
    })
      .then((offers) => {
        let graphSeries = options.series;
        setOptions({
          ...options,
          series: options.series.map((s) => {
            s.data = [];
            return s;
          }),
        });

        for (const i in offers) {
          let o = offers[i];
          graphSeries = options.series.map((s) => {
            if (i === 0) s.data = [];

            if (s.name === "Purchase") s.data.push(parseInt(o.purchasePrice));
            if (s.name === "Down payment") s.data.push(parseInt(o.downPayment));
            if (s.name === "Earnest Money") s.data.push(parseInt(o.earnestMoney));
            if (s.name === "Option Fee") s.data.push(parseInt(o.optionFee));

            if (options.yAxis.max < parseInt(o.purchasePrice)) options.yAxis.max = parseInt(o.purchasePrice) + 1000;
            return s;
          });
        }

        setOptions({
          ...options,
          series: graphSeries,
        });
      })
      .catch((err) => {
        console.log("Get All Offers Error: ", err);
      });
  };

  const getAnalytics = (period = null) => {
    getDashboardCounts(agent || user, period)
      .then((lytics) => {
        setAnalytics(lytics);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateMyListing = (opts) => {
    getUsersSellers(agent || user, opts)
      .then((sellers) => {
        setSellers(sellers);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateAllOffers = () => {
    getOffers(agent || user, null, {
      inList: true,
      status: offerStatusFilter || undefined,
      financeType: offerFinanceTypeFilter || undefined,
      purchasePrice: offerPurchasePriceFilter || undefined,
      propertyAddress: offerPropertyFilter || undefined,
      agentName: offerAgentFilter || undefined,
    })
      .then((offers) => {
        setTotalPages(offers.length / listingsPerPageFilter);
        setAllOffersList(offers);
      })
      .catch((error) => {
        console.error("Filter all offers error: ", error);
      });
  };

  const handlePageClick = (data) => {
    setPaginationOffset(data.selected);
  };

  let propertyAddresses = [];
  offers.forEach((o) => {
    if (!propertyAddresses.includes(o.address)) propertyAddresses.push(o.address);
  });

  const bestMatches = bestMatchOffers(offers, sellers);
  const acceptedOffersHistory = acceptedOffersList.filter((offer) => !user.removedDashboardOffers?.includes(offer.id)).map((offer) => <LastAcceptedOffer bestMatch={bestMatches} user={user} key={offer.id} sellers={offerSellers} offer={offer} />);
  const allOffers = allOffersList.length === 0 ? <p style={{ marginTop: 20 }}>There are no offers currently listed right now</p> : allOffersList.slice(paginationOffset * listingsPerPageFilter, listingsPerPageFilter * (paginationOffset + 1)).map((o) => <DashboardAllOffers user={user} bestMatch={bestMatches} history={history} key={o.createdAt} offer={o} setOffersToShare={setOffersToShare} offersToShare={offersToShare} />);
  const allClientOffers =
    allOffersList.length === 0 ? (
      <p style={{ marginTop: 20 }}>There are no offers at that moment</p>
    ) : (
      allSellersList.map((e) => {
        return (
          <tr className="table-row" key={e.createdAt}>
            <td className={bestMatches && bestMatches[0] && bestMatches[0].id === e.id ? "address-td first-td" : "address-td"}>
              {bestMatches && bestMatches[0] && bestMatches[0].id === e.id && (
                <p className="my-listings-best-match-tag">
                  <i class="fa fa-heart"></i>BEST MATCH
                </p>
              )}
              {e.address}
            </td>
            <td className={bestMatches && bestMatches[0] && bestMatches[0].id === e.id && "td-in-btwn"}>
              {e.status === "In Review" ? (
                <span className="badge review-badge">
                  <i className="fa fa-repeat"></i>In Review
                </span>
              ) : e.status === "Seller Review" ? (
                <span className="badge seller-review-badge">
                  <i className="fa fa-repeat"></i>Seller Review
                </span>
              ) : e.status === "Countered" ? (
                <span className="badge badge-success status-badge">
                  <i className="fa fa-repeat"></i>Countered
                </span>
              ) : e.status === "Not Accepted" ? (
                <span className="badge not-accepted-badge">
                  <i className="fa fa-times"></i>Not Accepted
                </span>
              ) : e.status === "Withdraw" ? (
                <span className="badge badge-black status-badge">
                  <i className="fa fa-check"></i>Withdrawn
                </span>
              ) : e.status === "Terminated" ? (
                <span className="badge badge-black status-badge">
                  <i className="fa fa-check"></i>Terminated
                </span>
              ) : e.status === "Not Accepted" ? (
                <span className="badge not-accepted-badge status-badge">
                  <i className="fa fa-times"></i>Not Accepted
                </span>
              ) : e.status === "Executed" ? (
                <span className="badge executed-badge status-badge">
                  <i className="fa fa-check"></i>Executed
                </span>
              ) : (
                <span className="badge badge-success status-badge">
                  <i className="fa fa-check"></i>Accepted
                </span>
              )}
            </td>
            <td className={bestMatches && bestMatches[0] && bestMatches[0].id === e.id && "td-in-btwn"}>{moment(e.createdAt * 1000).format("DD/MM/YYYY")}</td>
            <td className={bestMatches && bestMatches[0] && bestMatches[0].id === e.id && "td-in-btwn"}>{e.purchasePrice ? "$" + e.purchasePrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "N/A"}</td>
            <td className={bestMatches && bestMatches[0] && bestMatches[0].id === e.id && "last-td"}>
              <Link to={`/offers/${e.id}`}>
                <Button className="go-to-offer-btn mb-0">Go to Offer</Button>
              </Link>
            </td>
          </tr>
        );
      })
    );
  return (
    <>
      {!user?.stripeAccountId && (
        <Alert isOpen={showStripeAlert} className="mb-0" toggle={() => setShowStripeAlert(false)} color="danger" style={{ borderRadius: "0", border: "none" }}>
          <div className="d-flex align-items-center">
            <i className="fa fa-exclamation-circle mr-2"></i>
            <p className="mb-0">
              You need to{" "}
              <span
                style={{
                  cursor: "pointer",
                  color: "#007bff",
                  fontWeight: "600",
                  textDecoration: "underline",
                }}
                onClick={() => {
                  setStripeModal(true);
                }}
              >
                {" "}
                connect
              </span>{" "}
              to stripe to be able to accept tippings.
            </p>
            <button
              className="btn btn-outline-success ml-3"
              onClick={() => {
                setStripeModal(true);
              }}
            >
              Connect
            </button>
          </div>
        </Alert>
      )}
      {/* {user?.subscriptionId &&
        subscriptionStatus !== "active" &&
        new Date(user?.subscriptionEndDate * 1000) > new Date(Date.now()) && ( */}
      <Alert isOpen={showCardFailedAlert} className="mb-0" toggle={() => setShowCardFailedAlert(false)} color="danger" style={{ borderRadius: "0", border: "none" }}>
        <div className="d-flex align-items-center">
          <i className="fa fa-exclamation-circle mr-2"></i>
          <p className="mb-0">
            You Subscription expired. Please{" "}
            <span
              style={{
                cursor: "pointer",
                color: "#007bff",
                fontWeight: "600",
                textDecoration: "underline",
              }}
              onClick={() => {
                window.location.href = "/my-subscriptions";
              }}
            >
              {" "}
              renew
            </span>{" "}
            your subscription.
          </p>
          <button
            className="btn btn-outline-success ml-3"
            onClick={() => {
              window.location.href = "/my-subscriptions";
            }}
          >
            Renew
          </button>
        </div>
      </Alert>
      {/* )} */}
      <div className="dashboard-main">
        <Joyride
          size={30}
          steps={steps}
          run={joyRide}
          callback={(data) => {
            const { action, status } = data;
            if (action === "close") {
              setJoyRide(false);
            }
            if (status === "finished") {
              setJoyRide(false);
              const db = firebase.firestore();
              db.collection("users").doc(user?.uid).update({
                dashboardJoyRide: false,
              });
            }
          }}
          continuous={true}
          showProgress={true}
          showSkipButton={true}
          styles={{
            buttonNext: {
              color: "#ffffff",
              lineHeight: "0.9",
              padding: "10px",
            },
          }}
        />

        <Reminder user={user} />
        <div className="dashboard-header">
          <h5 className="sections-title" id="step1">
            Hello, <span className="text-capitalize">{displayName}</span>
          </h5>

          <div className="header-second-row">
            <span className="header-subtitle">Welcome to your workspace!</span>
            <span className="d-flex">
              <i className="fa fa-clock-o activity-icon"></i>
              <Link to="/user-activities" className="link">
                Activity
              </Link>
            </span>
          </div>
        </div>

        <div className="period-filter d-flex justify-content-end align-items-center mb-4">
          <span className="period-filter-label">Period:</span>
          <div>
            <button className="cursor-pointer dropdown-toggle" type="button" id="periodFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span>{periodFilter}</span>
              <i className="fa fa-chevron-down dropdown-icon"></i>
            </button>
            <div className="dropdown-menu" aria-labelledby="periodFilter">
              {filterOptions.periodFilter.map((period) => (
                <div
                  className="dropdown-item"
                  key={period}
                  onClick={() => {
                    getAnalytics(period);
                    setPeriodFilter(period);
                  }}
                >
                  {period}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="properties tab-container w-100">
          <div className="card properties-card">
            <div className="properties-card-icon">
              <img src={homes_listed_icon} alt="Listings"></img>
            </div>
            <div className="properties-card-info">
              <h3>{analytics.homesListed}</h3>
              <span>Listings Offered</span>
            </div>
          </div>

          <div className="card properties-card">
            <div className="properties-card-icon">
              <img src={received_icon} alt="received"></img>
            </div>
            <div className="properties-card-info">
              <h3>{analytics.offersReceived}</h3>
              <span>Offers Received</span>
            </div>
          </div>

          <div className="card properties-card">
            <div className="properties-card-icon">
              <img src={closed_icon} alt="Offers Accepted"></img>
            </div>
            <div className="properties-card-info">
              <h3>{analytics.offersAccepted ? analytics.offersAccepted : "0"}</h3>
              <span>Offers Accepted</span>
            </div>
          </div>

          <div className="card properties-card">
            <div className="properties-card-icon">
              <img src={executed_icon} alt="Offers Submitted"></img>
            </div>
            <div className="properties-card-info">
              <h3>{analytics.offersSubmitted ? analytics.offersSubmitted : "0"}</h3>
              <span>Offers Submitted</span>
            </div>
          </div>

          <div className="card properties-card">
            <div className="properties-card-icon">
              <img src={not_accepted_icon} alt="Offers Not Accepted"></img>
            </div>
            <div className="properties-card-info">
              <h3>{analytics.offersDeclined ? analytics.offersDeclined : "0"}</h3>
              <span>Offers Not Accepted</span>
            </div>
          </div>

          <div className="card properties-card">
            <div className="properties-card-icon">
              <img src={withdrawn_icon} alt="offers withdrawn"></img>
            </div>
            <div className="properties-card-info">
              <h3>{analytics.offersWithdrawn}</h3>
              <span>Offers Withdrawn</span>
            </div>
          </div>
        </div>

        <div className="list-section">
          <div className="list-heading d-flex flex-column flex-md-row">
            <h5 className="sections-title">My Listings</h5>
            <div className="period-filter ml-auto d-flex flex-column-reverse flex-xl-row align-items-end align-items-xl-center">
              <span className="d-flex flex-column align-items-end flex-md-row align-items-md-center mt-0 mt-md-2 mt-xl-0">
                <div className="d-xl-inline-block mt-2 mt-md-0">
                  <span className="period-filter-label">Property Type:</span>
                  <div className="d-inline-block">
                    <button className="cursor-pointer dropdown-toggle mr-0 mr-md-4" type="button" id="listingPropertyFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span>{listingPropertyFilter}</span>
                      <i className="fa fa-chevron-down dropdown-icon"></i>
                    </button>
                    <div className="dropdown-menu" aria-labelledby="listingPropertyFilter">
                      {filterOptions.listingPropertyFilter.map((property) => (
                        <div
                          className="dropdown-item"
                          key={property}
                          onClick={() => {
                            // updateMyListing({ property })
                            setListingPropertyFilter(property);
                          }}
                        >
                          {property}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="d-xl-inline-block mt-2 mt-md-0">
                  <span className="period-filter-label">Status:</span>
                  <div className="d-inline-block">
                    <button className="cursor-pointer dropdown-toggle mr-0 mr-xl-4" type="button" id="listingStatusFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span>{listingStatusFilter}</span>
                      <i className="fa fa-chevron-down dropdown-icon"></i>
                    </button>
                    <div className="dropdown-menu" aria-labelledby="listingStatusFilter">
                      {filterOptions.listingStatusFilter.map((status) => (
                        <div
                          className="dropdown-item"
                          key={status}
                          onClick={() => {
                            updateMyListing({ status });
                            setListingStatusFilter(status);
                          }}
                        >
                          {status}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </span>

              <Button onClick={() => history.push(`/sellers`)} type="button" className="link view-more-button">
                View All Listings
              </Button>
            </div>
          </div>
        </div>
        <div className="my-properties1">
          <div className="list-slider1">
            {sellers.length ? (
              <Slider {...sliderSettings}>
                {sellers.map((m, i) => {
                  const seller = m.data();
                  const sellerId = m.id;
                  return (
                    <div key={i}>
                      <div className="properties-list-card">
                        <div className="properties-card-img">{seller.featured_image?.url ? <img src={seller.featured_image?.url} alt="Home image" /> : <img src="/images/house-placeholder.jpg" alt="Home image" />}</div>
                        <h3 className="sections-title">
                          {seller.street_number} {seller.street_name}, {seller.city}, {seller.state} {seller.zip_code}
                        </h3>
                        <span className={seller.MlsStatus === "Active" ? "badge badge-success status-badge" : "badge badge-review"}>{seller.MlsStatus}</span>
                        {/* <span className="badge badge-review">{seller.MlsStatus}</span> */}
                        {Object.values(allOffersList).filter((key) => key.seller === sellerId)?.length ? (
                          <div className="see-all-offers">
                            <button
                              type="button"
                              className="link"
                              onClick={() => {
                                setAllSellersList([]);
                                getOffers(user, null, {
                                  inList: true,
                                  sellerId: m.id,
                                })
                                  .then((offers) => {
                                    setAllSellersList(offers);
                                  })
                                  .catch((err) => {
                                    console.log("Get All Offers Error: ", err);
                                  });
                                toggle();
                              }}
                            >
                              See all {Object.values(allOffersList).filter((key) => key.seller === sellerId)?.length} offers
                            </button>
                            {/* <button type="button" className="link"><Link to={`/offers/${m.id}`} className="link">See all offers</Link></button> */}
                          </div>
                        ) : (
                          <></>
                        )}
                        <div className="accepted-detail">
                          <ul>
                            <li className="d-flex align-items-center">
                              <img src="" alt="" />
                              <p>Sellers Agent</p>
                              <Link to={`/sellers/${sellerId}`} className="link">
                                {seller.seller_name}
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="purchase-price">
                          <h4>Purchase Price</h4>
                          <p>{seller.listing_price || seller.current_value ? "$" + (seller.listing_price || seller.current_value)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "N/A"}</p>
                        </div>
                        <div className="house-detail">
                          <ul>
                            <li className="d-flex align-items-center">
                              <p>Bedrooms</p>
                              <p>{seller.bathrooms}</p>
                            </li>
                            <li className="d-flex align-items-center">
                              <p>Bathrooms</p>
                              <p>{seller.bathrooms}</p>
                            </li>
                            <li className="d-flex align-items-center">
                              <p>Sq ft</p>
                              <p>{seller.sqft} sqft</p>
                            </li>
                            <li className="d-flex align-items-center">
                              <p>Year Built</p>
                              <p>{seller.year_built}/Preowned</p>
                            </li>
                          </ul>
                          <Link to={`/sellers/${m.id}`} className="link">
                            <button type="button" className="btn btn-theme btn btn-secondary">
                              View Details
                            </button>
                          </Link>
                          <a href={`/sellers/${m.id}?trends=1`} target="_blank" className="text-center link text-black">
                            View OfferTrends
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            ) : (
              <div className="period-filter-label">Sorry! No listing found.</div>
            )}
          </div>
        </div>

        <div className="last-accepted-offers">
          <div className="list-heading d-flex align-items-center">
            <h5 className="sections-title">Last Executed Offer</h5>
            <div className="period-filter ml-auto">
              <Button onClick={() => history.push(`/offers?status=Executed`)} type="button" className="link view-more-button">
                View All Executed
              </Button>
            </div>
          </div>
          <div className="my-properties1">
            <div className="list-slider1">{acceptedOffersHistory.length ? <Slider {...sliderSettings}>{acceptedOffersHistory}</Slider> : <div className="period-filter-label">There are no accepted offers right now</div>}</div>
          </div>
        </div>

        <Modal isOpen={chartModal} size="lg" fade={false} toggle={chartToggle} className="offers-modal">
          <ModalHeader className="modal-header" toggle={chartToggle}></ModalHeader>
          <ModalBody className="modal-body">
            <Container>
              <Row>
                <Col>
                  <div className="row-space-between">
                    <h5 className="modal-title">Chart filters</h5>
                    {/* <button className="modal-chart-btn">
                    <img src={Bars} className="pr-10" />List view
                  </button> */}
                  </div>
                </Col>
              </Row>
              <Row className="mb-30">
                <Col>
                  <br />
                  <div className="d-inline-block mb-2">
                    <span className="period-filter-label">Finance Type:</span>
                    <div className="d-inline-block">
                      <select
                        name="offerStatusFilter"
                        value={offerFinanceTypeFilter}
                        onChange={(e) => {
                          updateGraph(e.target.value);
                          setOfferFinanceTypeFilter(e.target.value);
                        }}
                        className="search-fields offers-search-fields mr-0 mr-md-4"
                      >
                        {filterOptions.offerFinanceTypeFilter.map((m, i) => (
                          <option key={i} value={m}>
                            {m}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="highchart-container">
                    <HighchartsReact highcharts={Highcharts} options={options} />
                  </div>
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Modal>

        <Modal isOpen={modal} size="lg" fade={false} toggle={toggle} className="offers-modal">
          <ModalHeader className="modal-header" toggle={toggle}></ModalHeader>
          <ModalBody className="modal-body">
            <Container>
              <Row>
                <Col>
                  <div className="row-space-between">
                    <h5 className="modal-title">All client offers</h5>
                    {/* <button className="modal-chart-btn" onClick={chartToggle}>
                    <img src={Graph} className="pr-10" />Chart filters
                  </button> */}
                  </div>
                </Col>
              </Row>
              <Row className="mb-30">
                <Col>
                  <div className="table-responsive">
                    <table className="offers-table table-customized">
                      <tr className="modal-tr-header">
                        <th>Address</th>
                        <th>Status</th>
                        <th>Date</th>
                        <th>Purchase Price</th>
                        <th></th>
                      </tr>
                      {allClientOffers.length === 0 ? <p style={{ marginTop: 20 }}>There are no offers currently listed right now</p> : allClientOffers}
                    </table>
                  </div>
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Modal>

        <div className="all-offers no-shadow">
          <div className="list-heading">
            <h5 className="sections-title">All Offers</h5>
            <div className="period-filter d-md-block d-flex flex-column align-items-end">
              <div className="d-inline-block mb-2">
                <span className="period-filter-label">Status:</span>
                <div className="d-inline-block">
                  <select name="offerStatusFilter" value={offerStatusFilter} onChange={(e) => setOfferStatusFilter(e.target.value)} className="search-fields offers-search-fields mr-0 mr-md-4 ">
                    {filterOptions.offerStatusFilter.map((m, i) => (
                      <option key={i} value={m}>
                        {m}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="d-inline-block mb-2">
                <span className="period-filter-label">Close Date:</span>
                <div className="d-inline-block">
                  <select name="offerStatusFilter" value={offerSortByFilter} onChange={(e) => setOfferSortByFilter(e.target.value)} className="search-fields offers-search-fields mr-0 mr-md-4 ">
                    {filterOptions.offerSortByFilter.map((m, i) => (
                      <option key={i} value={m}>
                        {m}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="d-inline-block mb-2">
                <span className="period-filter-label">Agent:</span>
                <div className="d-inline-block">
                  <select name="offerStatusFilter" value={offerAgentFilter} onChange={(e) => setOfferAgentFilter(e.target.value)} className="search-fields offers-search-fields mr-0 mr-md-4">
                    {filterOptions.offerAgentFilter.map((m, i) => (
                      <option key={i} value={m}>
                        {m}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="d-inline-block mb-2">
                <span className="period-filter-label">Finance Type:</span>
                <div className="d-inline-block">
                  <select name="offerStatusFilter" value={offerFinanceTypeFilter} onChange={(e) => setOfferFinanceTypeFilter(e.target.value)} className="search-fields offers-search-fields mr-0 mr-md-4">
                    {filterOptions.offerFinanceTypeFilter.map((m, i) => (
                      <option key={i} value={m}>
                        {m}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="d-inline-block mb-2">
                <span className="period-filter-label">Purchase Price:</span>
                <div className="d-inline-block">
                  <select name="offerStatusFilter" value={offerPurchasePriceFilter} onChange={(e) => setOfferPurchasePriceFilter(e.target.value)} className="search-fields offers-search-fields mr-0 mr-md-4">
                    {filterOptions.offerPurchasePriceFilter.map((m, i) => (
                      <option key={i} value={m}>
                        {m}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="d-inline-block mb-2">
                <span className="period-filter-label">Property:</span>
                <div className="d-inline-block">
                  <select name="offerStatusFilter" value={offerPropertyFilter} onChange={(e) => setOfferPropertyFilter(e.target.value)} className="search-fields offers-search-fields mr-0 mr-md-4 ">
                    <option value="">Select property</option>
                    {propertyAddresses.map((m, i) => (
                      <option key={i} value={m}>
                        {m}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="d-inline-block mb-2">
                <Button className="go-to-offer-btn" onClick={updateAllOffers}>
                  Apply
                </Button>
              </div>
            </div>
            <div className="period-filter d-flex justify-content-end align-items-center mb-4">
              <span className="period-filter-label result-per-page-label">Result Per Page:</span>
              <div className="d-inline-block">
                <select
                  name="offerStatusFilter"
                  value={listingsPerPageFilter}
                  onChange={(e) => {
                    setListingsPerPageFilter(parseInt(e.target.value));
                    setTotalPages(allOffersList.length / parseInt(e.target.value));
                    handlePageClick({
                      selected: 0,
                    });
                  }}
                  className="search-fields offers-search-fields mr-0 mr-md-4"
                >
                  {filterOptions.listingsPerPageFilter.map((m, i) => (
                    <option key={i} value={m}>
                      {m}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="table-responsive margin-top-20">
              <table className="table table-customized">
                <thead>
                  <tr>
                    <th></th>
                    <th>Address</th>
                    <th>Status</th>
                    <th>Agent Name</th>
                    <th>Finance Type</th>
                    <th>Purchase Price</th>
                    <th>Date</th>
                    <th className="text-center">
                      {offersToShare.length ? (
                        <Button className="btn btn-theme btn btn-secondary share-btn-dashboard" onClick={() => setShareOffersModel(true)}>
                          Share
                        </Button>
                      ) : null}
                    </th>
                  </tr>
                </thead>
                <tbody>{allOffers}</tbody>
              </table>
            </div>
            <div className="text-center">
              <ReactPaginate previousLabel={"previous"} nextLabel={"next"} breakLabel={"..."} breakClassName={"break-me"} pageCount={totalPages} marginPagesDisplayed={2} pageRangeDisplayed={1} onPageChange={handlePageClick} containerClassName={"pagination"} activeClassName={"active"} />
            </div>
          </div>

          <Modal isOpen={shareOffersModel} toggle={() => setShareOffersModel(!shareOffersModel)} className="modal-danger">
            <ModalHeader>Confirmation</ModalHeader>
            <ModalBody>
              <Label>Name</Label>
              <Input type="text" name="name" className="input mb-2" placeholder="Name" onChange={(e) => setShareOfferName(e.target.value)} />

              <Label>Email</Label>
              <Input type="text" name="email" className="input mb-4" placeholder="Email address" onChange={(e) => setShareOfferEmail(e.target.value)} />
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={() => setShareOffersModel(!shareOffersModel)}>
                Cancel
              </Button>{" "}
              <Button color="success" disabled={sendingOffers} onClick={handleShareOffers}>
                {sendingOffers ? "Sending..." : "Send"}
              </Button>
            </ModalFooter>
          </Modal>

          {/* Modal policy */}

          <Modal isOpen={policyModal} toggle={togglePolicyModal} size="md" backdrop="static">
            {/* <ModalHeader toggle={this.toggle}>Offer Deadline</ModalHeader> */}
            <ModalBody className="px-4 py-5 text-center">
              <img src="/img/logos/black-logo.png" alt="black-logo" className="cm-logo" width={"250px"} />
              <p className="mt-4 dashboard-modal-text">My Listings is on Dashboard Page ( All MLS Active listing will be added to this section. Property listing May take 2-4 hours to appear). Please make sure your MLS listing goes through all syndicated IDX websites.</p>
              <div className="mt-4">
                <Button
                  style={{ width: "100px" }}
                  className="btn btn-danger mx-2"
                  onClick={(e) => {
                    togglePolicyModal();
                  }}
                >
                  Close
                </Button>

                <Button
                  style={{ width: "100px" }}
                  className="btn btn-success mx-2"
                  onClick={(e) => {
                    const db = firebase.firestore();
                    const { id } = newUser;
                    console.log(newUser, user?.realtor_email, user?.email, "[][][]");
                    if (user?.realtor_email) {
                      db.collection("users")
                        .doc(id)
                        .update({
                          initial_popup: true,
                        })
                        .then(() => {
                          togglePolicyModal();
                        })
                        .catch((error) => {
                          console.error("Error updating document: ", error);
                        });
                    } else {
                      db.collection("realtors")
                        // .where("realtor_email", "==", user?.email)
                        .doc(id)
                        .update({
                          initial_popup: true,
                        })
                        .then(() => {
                          togglePolicyModal();
                        })
                        .catch((error) => {
                          console.error("Error updating document: ", error);
                        });
                    }
                  }}
                >
                  Accept
                </Button>
              </div>
            </ModalBody>
          </Modal>
        </div>
        {/* Modal for stripe connect */}
        <Modal isOpen={stripeModal} toggle={() => setStripeModal(false)} size="md" backdrop="static">
          <ModalBody className="px-4 py-5 text-center">
            <img src="/img/logos/black-logo.png" alt="black-logo" className="cm-logo" width={"250px"} />
            <p className="mt-4 dashboard-modal-text-left">Connect to Stripe to receive tipping from agents and brokers accessing your data.</p>
            <p className="dashboard-modal-text-red">
              Tipping provides access to view all{" "}
              <span>
                <a
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "#dc3545",
                  }}
                  target="_blank"
                  href={`https://homematchx.com/otherAgent/offer/${dummyOffer.id}`}
                >
                  offers
                </a>
              </span>{" "}
              from other listings in Analytics Global Data.
            </p>
            <div className="mt-4">
              <a href={"https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_HgaDtfsuAhvDy2biSncWikkPAoZYYufT&scope=read_write"} target="_blank">
                <Button className="btn btn-danger mx-2" style={{ width: "100px" }}>
                  Connect
                </Button>
              </a>
              <Button
                style={{ width: "100px" }}
                onClick={(e) => {
                  setStripeModal(false);
                }}
              >
                Close
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
}

export default Dashboard;
