import React, { Component } from "react";
import { Map, GoogleApiWrapper } from "google-maps-react";
import ReactDOMServer from "react-dom/server";
import moment from "moment";
import Loader from "react-loader-spinner";
import firebase from "firebase/app";
import ImageGallery from "react-image-gallery";
import "firebase/firestore";
import "firebase/analytics";
import BuyerTile from "components/BuyerTile";
import RealtorInfo from "components/RealtorInfo";
import { Tooltip, Button, Container, Row, Col } from "reactstrap";
import PartitionedForm from "components/PartitionedForm";
import PageNotFound from "components/Common/PageNotFound";
import ShareSellerModal from "components/Seller/ShareSellerModal";
import InstantOfferModal from "components/Seller/InstantOfferModal";
import { firebaseConfig } from "./../components/Firebase/firebase";
import { Link } from "react-router-dom";
import FAQ from "../components/FAQ";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Progress,
  Table,
} from "reactstrap";
import PostDetailModal from "components/Blog/PostDetailModal";
import tourEmail from "../templates/email/tourEmail";
import NumberFormat from "react-number-format";
import ShareModal from "../components/ShareModal";
import bedrooms_icon from "../assets/icons/bedrooms.svg";
import bathrooms_icon from "../assets/icons/bathrooms.svg";
import dining_areas_icon from "../assets/icons/dining_areas.svg";
import garage_icon from "../assets/icons/garage.svg";
import half_baths_icon from "../assets/icons/half_baths.svg";
import living_areas_icon from "../assets/icons/living_areas.svg";
import story_icon from "../assets/icons/story.svg";
import trend_icon from "../assets/icons/trend-line.png";
import trendIcon from "../assets/icons/trend-icon.svg";
import shareIcon from "../assets/icons/share.svg";
import verified_icon from "../assets/icons/verified-icon.svg";
import up_arrow from "../assets/icons/up-arrow.svg";
import infoIcon from "../assets/icons/info-icon.svg";
import { all } from "underscore";
import AgentInformationModal from "components/Analytics/AgentInformationModal";
import VerifiedListing from "../assets/icons/verified-icon.svg";
import { toast } from "react-toastify";
import qs from "qs";
import axios from "axios";
import arrowLeft from "../assets/icons/arrow-left.png";
import mastercard from "../assets/icons/mastercard.png";
import thankme from "../assets/icons/thankme-usd.png";

let { logEvent } = firebase.analytics();

const { apiKey } = firebaseConfig;

export const tourFormFields = {
  lender_approval_available: {
    label: "Lender Approval or Proof of Funds available",
    required: true,
    options: ["Yes", "No"],
  },
  buyer: {
    label: "Select Buyer",
    required: true,
    options: [],
  },
  tour_date: {
    label: "Select convinent day",
    required: true,
    type: "date",
  },
  tour_time: {
    required: true,
    options: [
      "6:00AM",
      "6:30AM",
      "7:00AM",
      "7:30AM",
      "8:00AM",
      "8:30AM",
      "9:00AM",
      "9:30AM",
      "10:00AM",
      "10:30AM",
      "11:00AM",
      "11:30AM",
      "12:00PM",
      "12:30PM",
      "1:00PM",
      "1:30PM",
      "2:00PM",
      "2:30PM",
      "3:00PM",
      "3:30PM",
      "4:00PM",
      "4:30PM",
      "5:00PM",
      "5:30PM",
      "6:00PM",
      "6:30PM",
      "7:30PM",
      "8:00PM",
      "8:30PM",
      "9:00PM",
      "9:30PM",
      "10:00PM",
      "10:30PM",
      "11:00PM",
      "11:30PM",
    ],
  },
};

const sections = [
  {
    name: "Request tour form",
    fields: ["buyer", "lender_approval_available", "tour_date", "tour_time"],
  },
];

class PropertyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shareModal: false,
      inputs: {},
      alreadyTourRequested: false,
      loading: false,
      tooltipOpen: false,
      questionTooltipOpen: false,
      associatedBuyers: [],
      buyerProfile: null,
      blvModal: false,
      offerTrendsModal: false,
      showDeadlinePopup: false,
      sellerProfileUrl: "/images/face-placeholder.gif",
      statusColors: {
        Active: "success",
        "Active Kick Out": "primary",
        "Active Option Contract": "primary",
        "Active Contingent": "primary",
        Pending: "warning",
        Sold: "danger",
        Closed: "danger",
        Cancelled: "dark",
        "Temp Off Market": "dark",
        Expired: "dark",
        Leased: "dark",
        Withdraw: "dark",
      },
      amentities: [
        "Boat Ramp",
        "Campground",
        "Club House",
        "Comm. Sprinkler System",
        "Common Elevator",
        "Community Dock",
        "Community Pool",
        "Elec Car Charging Station",
        "Gated Entrance",
        "Golf",
        "Pool",
        "Greenbelt",
        "Guarded Entrance",
        "Hangar",
        "Horse Facilities",
        "Jogging Path/Bike Path",
        "Landing Strip",
        "Laundry",
        "Marina",
        "Other",
        "Park",
        "Perimeter Fencing",
        "Playground",
        "Private Lake/Pond",
        "Public Hangar",
        "Racquet Ball",
        "RV Parking",
        "Sauna",
        "Spa",
        "Tennis",
      ],
      tags: [],
      followers: [],
      groups: [],
      posts: [],
      unReadOffers: 0,
      totalPurchasePrice: 0,
      financingType: "",
      totalOptionFee: "",
      totalOptionDays: "",
      totalExpenseType: "",
      totalPosseions: "",
      totalSaleOfProperty: "",
      totalRightToTerminate: "",
      totalEarnestMoney: "",
      totalDownPayment: "",
      closeingDate: "",
      totalOffers: 0,
      verifiedOffers: 0,
      isTooltip: false,
      haveAccepted: false,
      allOffers: [],
      showOffersModal: false,
      bestMatches: [],
      globalAgents: [],
      showTooltip: false,
      usersAllAllowedToViewProperties:
        this.props.user?.allowedToViewProperties?.map((e) => e.property) || [],
      showTooltip2: false,
      thankedPropertiesThisMonth:
        this.props.user?.allowedToViewProperties?.filter(
          (e) =>
            new Date(e.boughtAt).getMonth() === new Date().getMonth() &&
            new Date(e.boughtAt).getFullYear() === new Date().getFullYear()
        ).length || 0,

      thankMeModal: false,
      thankMeAmount: 0,
      selectedAmount: 0,
      minThankMeAmount: 0,
      thankMeLoading: false,
      afterThankMeModal: false,
      cardEmail: "",
      cardName: "",
      cardNumber: "",
      expDate: "",
      cvv: "",
      errorMessage: {},
      paymentModal: false,
      isLoggedin: this.props.user ? true : false,
      // seller: {},
      sellerUser: {},
    };
    this.mainForm = React.createRef();
  }

  toggle = (key = "tooltipOpen") => {
    this.setState({
      [key]: !this.state[key],
    });
  };

  id() {
    return this.props.match.params.id;
  }

  formatCardNumber = (input) => {
    // Format card number as XXXX-XXXX-XXXX-XXXX
    const formattedInput = input.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    let formattedNumber = "";
    for (let i = 0; i < formattedInput.length; i += 4) {
      formattedNumber += formattedInput.slice(i, i + 4) + " ";
    }
    return formattedNumber.trim();
  };

  formatExpDate = (input) => {
    // Format expiration date as MM/YYYY
    const formattedInput = input.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    if (formattedInput.length > 2) {
      return `${formattedInput.slice(0, 2)}/${formattedInput.slice(2)}`;
    }
    return formattedInput;
  };

  // handle thank me clicked
  handleThankMe = (locationsOffer, sellerId) => {
    const { allOffers } = this.state;
    let numberOfOffers = allOffers.filter(
      (e) => e.verified === true && e.status != "In Review"
    ).length;

    const db = firebase.firestore();
    db.collection("users")
      .doc(sellerId)
      .get()
      .then((doc) => {
        this.setState({
          tippedUser: {
            id: doc.id,
            ...doc.data(),
          },
        });
        if (!doc.data().stripeAccountId) {
          return toast.error("This user is not connected with stripe account");
        }
        this.setState({
          tippedProperty: locationsOffer.seller,
          thankMeModal: true,
        });
      });
    this.setState({
      noOfOffers: numberOfOffers,
    });
    this.setState({
      selectedAmount:
        numberOfOffers <= 6
          ? 29
          : numberOfOffers > 6 && numberOfOffers <= 24
          ? 49
          : numberOfOffers > 24 && numberOfOffers <= 48
          ? 79
          : 119,
      thankMeAmount:
        numberOfOffers <= 6
          ? 29
          : numberOfOffers > 6 && numberOfOffers <= 24
          ? 49
          : numberOfOffers > 24 && numberOfOffers <= 48
          ? 79
          : 119,
      minThankMeAmount:
        numberOfOffers <= 6
          ? 29
          : numberOfOffers > 6 && numberOfOffers <= 24
          ? 49
          : numberOfOffers > 24 && numberOfOffers <= 48
          ? 79
          : 119,
    });
  };

  // handle payment
  handlePayment = async () => {
    const {
      cardName,
      cardNumber,
      expDate,
      cvv,
      selectedAmount,
      tippedUser,
      tippedProperty,
      cardEmail,
      thankedPropertiesThisMonth,
      usersAllAllowedToViewProperties,
      isLoggedin,
    } = this.state;
    if (!isLoggedin) {
      return this.props.history.push("/login");
    }
    if (!cardNumber) {
      return this.setState({
        errorMessage: {
          message: "Please enter card number",
          type: "cardNumber",
        },
      });
    }
    if (!expDate) {
      return this.setState({
        errorMessage: {
          message: "Please enter card expiration date",
          type: "expDate",
        },
      });
    }
    if (!cvv) {
      return this.setState({
        errorMessage: {
          message: "Please enter card cvv",
          type: "cvv",
        },
      });
    }
    this.setState({
      thankMeLoading: true,
    });
    const createStripeTippingCharge = firebase
      .functions()
      .httpsCallable("createStripeTippingCharge");

    let data = qs.stringify({
      "card[number]": cardNumber.replace(/\s/g, ""),
      "card[exp_month]": expDate.split("/")[0],
      "card[exp_year]": expDate.split("/")[1],
      "card[cvc]": cvv,
      // key: "pk_test_RO9Jp6dNdbOOLlxVx4sLOKB300KXRYY0gn",
      key: "pk_live_txUDcEIQu5Ijd8jMpMIiSjPd00QKj9yMpL",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.stripe.com/v1/tokens",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        try {
          createStripeTippingCharge({
            amount: selectedAmount * 100,
            token: response.data.id,
            email: cardEmail,
            name: cardName,
            tippedUserAccountId: tippedUser?.stripeAccountId,
            isSubscribed:
              tippedUser?.subscriptionStatus === "active" &&
              tippedUser.subscriptionEndDate > Date.now()
                ? true
                : false,
          })
            .then((response) => {
              if (thankedPropertiesThisMonth + 1 <= 5)
                this.setState({
                  thankedPropertiesThisMonth: thankedPropertiesThisMonth + 1,
                  usersAllAllowedToViewProperties: [
                    ...usersAllAllowedToViewProperties,
                    tippedProperty,
                  ],
                });
              const db = firebase.firestore();
              db.collection("users")
                .doc(this.props.user?.uid)
                .update({
                  allowedToViewProperties:
                    firebase.firestore.FieldValue.arrayUnion({
                      property: tippedProperty,
                      boughtAt: Date.now(),
                    }),
                });
              db.collection("sellers")
                .doc(tippedProperty)
                .update({
                  totalThankMePurchase:
                    firebase.firestore.FieldValue.increment(1),
                  totalThankMeRevenue:
                    tippedProperty?.subscriptionId &&
                    tippedUser?.subscriptionStatus === "active" &&
                    tippedUser.subscriptionEndDate > Date.now()
                      ? firebase.firestore.FieldValue.increment(selectedAmount)
                      : firebase.firestore.FieldValue.increment(
                          (selectedAmount * 80) / 100
                        ),
                });
              this.setState({
                thankMeModal: false,
                paymentModal: false,
                cardEmail: "",
                cardName: "",
                cardNumber: "",
                expDate: "",
                cvv: "",
                errorMessage: {},
                thankMeLoading: false,
                afterThankMeModal: true,
              });
            })
            .catch((err) => {
              console.log(err);
              this.setState({
                thankMeLoading: false,
              });
            });
        } catch (err) {
          console.log(err);
        }

        // let chargeData = qs.stringify({
        //   amount: selectedAmount * 100,
        //   token: response.data.id,
        //   email: cardEmail,
        //   name: cardName,
        //   tippedUserAccountId: tippedUser?.stripeAccountId,
        // });

        // let chargeConfig = {
        //   method: "post",
        //   maxBodyLength: Infinity,
        //   url: "http://127.0.0.1:5001/homematchx-test/us-central1/createStripeTippingCharge",
        //   headers: {
        //     "Content-Type": "application/x-www-form-urlencoded",
        //   },
        //   data: chargeData,
        // };
        // axios
        //   .request(chargeConfig)
        //   .then((response) => {
        //     console.log(JSON.stringify(response.data));
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //   });
      })
      .catch((error) => {
        toast.error("Invalid card details");
        this.setState({
          thankMeLoading: false,
        });
        console.log(error);
      });
  };

  loadSellerImage = (sellerEmail) => {
    const db = firebase.firestore();

    const publicProfileQueries = [
      db.collection("users").where("user_email", "==", sellerEmail).get(),
      db.collection("realtors").where("realtor_email", "==", sellerEmail).get(),
      db.collection("lenders").where("lender_email", "==", sellerEmail).get(),
      db.collection("builders").where("builder_email", "==", sellerEmail).get(),
    ];

    const storage = firebase.storage();
    const storageRef = storage.ref();

    Promise.all(publicProfileQueries).then((snapshots) => {
      const docSets = snapshots.map((s) => s.docs);
      let docs = [];
      for (const docSet of docSets) {
        for (const doc of docSet) {
          if (!docs.some((d) => d.id === doc.id)) {
            docs.push({ id: doc.id, ...doc.data() });
          }
        }
      }
      if (docs.length > 0) {
        const sellerProfile = docs[0];
        const { builder_name, id } = sellerProfile;
        let imageRef = storageRef.child(`images/users/${id}.jpg`);
        if (builder_name) {
          imageRef = storageRef.child(`images/logos/${id}.jpg`);
        }
        imageRef.getDownloadURL().then((url) => {
          this.setState({
            sellerProfileUrl: url,
          });
        });
      }
    });
  };

  offerTrendsModalToggle = () => {
    this.setState({
      offerTrendsModal: !this.state.offerTrendsModal,
    });
  };

  shareOfferTrends = () => {
    this.setState({
      shareModal: !this.state.shareModal,
    });
  };

  getAssociatedBuyers = async () => {
    const { user } = this.props;
    if (!user) return;

    const db = firebase.firestore();
    const { email, uid } = user;

    const q = db.collection("buyers").where("draft", "==", false);
    let followers = [];
    let groupsData = [];

    await db
      .collection("network")
      .doc(uid)
      .get()
      .then((doc) => {
        const data = doc.data();
        followers = data;
      });

    db.collection("network")
      .doc(uid)
      .collection("groups")
      .onSnapshot((snaps) => {
        const groups = snaps.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });

        groupsData = groups;
      });

    const queries = [
      q.where("buyer_email", "==", email),
      q.where("lender_email", "==", email),
      q.where("represendtedBy", "==", email),
      q.where("owner", "==", uid),
    ];
    const promises = queries.map((q) => q.get());
    Promise.all(promises)
      .then((snapshots) => {
        if (this.mounted) {
          const docSets = snapshots.map((s) => s.docs);
          let docs = [];
          for (const docSet of docSets) {
            for (const doc of docSet) {
              if (!docs.some((d) => d.id === doc.id)) {
                docs.push({ id: doc.id, ...doc.data() });
              }
            }
          }

          const storage = firebase.storage();
          const storageRef = storage.ref();
          const buyers = docs;

          for (const buyer of buyers) {
            const buyerImageRef = storageRef.child(
              `images/users/${buyer.id}.jpg`
            );
            buyerImageRef
              .getDownloadURL()
              .then((url) => {
                buyer.photo = url;
                if (this.mounted) {
                  this.setState({ associatedBuyers: buyers });
                }
              })
              .catch((error) => {});
          }

          if (this.mounted) {
            this.setState({
              associatedBuyers: buyers,
              groups: groupsData,
              followers,
            });
          }
        }
      })
      .catch((error) => {
        if (this.mounted) {
          this.setState({ loading: false, error: error });
          console.error(error);
        }
      });
  };

  loadLinkedBuyerProfile = async ({ buyerProfileId }) => {
    if (buyerProfileId) {
      const db = firebase.firestore();
      const doc = await db.collection("buyers").doc(buyerProfileId).get();
      this.setState({
        buyerProfile: doc,
      });
    }
  };

  marketingTools = () => {
    let { seller } = this.state;

    let marketingTools = {};

    let toolIndex = 0;

    while (seller[`tool_${toolIndex}`]) {
      const toolData = seller[`tool_${toolIndex}`];
      marketingTools[toolData.name] = [
        ...(marketingTools[toolData.name] || []),
        toolData,
      ];
      toolIndex++;
    }

    if (seller.type !== "MLS" && toolIndex === 0) {
      return null;
    }

    const tools = [];

    Object.keys(marketingTools).forEach((key) => {
      const toolSet = marketingTools[key];
      tools.push(
        <>
          <h5 ref={key}>{key}</h5>
          {toolSet.map((t) => {
            if (t.type === "textblob") {
              return (
                <div
                  className="embed-container"
                  dangerouslySetInnerHTML={{ __html: t.value }}
                />
              );
            }
            return (
              <p>
                {key}, please visit website for{" "}
                <a
                  style={{ color: "rgb(255, 33, 79)" }}
                  href={t.value}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {key}
                </a>
              </p>
            );
          })}
        </>
      );
    });

    return (
      <>
        <h2>Marketing Tools</h2>
        {tools}
      </>
    );
  };

  onBuilderUpdate = (builder) => {
    this.setState({ builder });
  };

  renderBuilderQAs = () => {
    const { builder } = this.state;
    if (builder) {
      const {
        lotSizeRestrictions,
        amenities,
        taxAssessment,
        earnestMoneyAmount,
        buyerIncentives,
        agentIncentives,
        constructionTimeline,
        upgradesSelections,
        designCenter,
        contingencyRequirements,
        availableLots,
        preSelling,
      } = builder;
      return (
        <>
          <h4>Community Information</h4>
          <FAQ
            term={
              "Are there any restrictions on lot sizes, floor plans, look-alike, or elevation repetition in this community?"
            }
            defination={lotSizeRestrictions}
          />
          <FAQ
            term={"What amenities does this community offer?"}
            defination={amenities}
          />
          <FAQ
            term={"Is there a MUD tax, PID tax, or special tax assessment?"}
            defination={taxAssessment}
          />
          <FAQ
            term={"What is the required amount of Earnest Money Deposit?"}
            defination={earnestMoneyAmount}
          />
          <FAQ
            term={"Are there any buyer incentives?"}
            defination={buyerIncentives}
          />
          <FAQ
            term={"What are the real estate agent incentives?"}
            defination={agentIncentives}
          />
          <FAQ
            term={"What is the construction timeline?"}
            defination={constructionTimeline}
          />
          <FAQ
            term={
              "Can you select your home exterior elevation, finishes, and upgrades?"
            }
            defination={upgradesSelections}
          />
          <FAQ
            term={"Is the design center onsite or offsite?"}
            defination={designCenter}
          />
          <FAQ
            term={
              "Are there any contingency requirements for the sale of other home?"
            }
            defination={contingencyRequirements}
          />
          <FAQ
            term={"What is the current phase and how many lots are available?"}
            defination={availableLots}
          />
          <FAQ
            term={"When will the next phase start pre-selling?"}
            defination={preSelling}
          />
          <hr />
        </>
      );
    }
  };

  getGlobalAgents = () => {
    const db = firebase.firestore();
    db.collection("users")
      .doc(this.props.user?.uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.setState({
            globalAgents: doc.data().myGlobalAgents,
          });
        }
      });
  };

  async componentWillMount() {
    const { loading, sellerUser } = this.state;
    this.setState({
      loading: !loading,
    });
    const id = this.id();

    if (!id) {
      this.props.history.goBack();
      return;
    }

    const db = firebase.firestore();

    const storage = firebase.storage();
    const storageRef = storage.ref();
    const sellerRef = db.collection("sellers").doc(id);
    const sellerImageRef = storageRef.child(`images/properties/${id}.jpg`);

    if (this.state.isLoggedin) this.getGlobalAgents();

    db.collection("meta")
      .doc(id)
      .set(
        {
          views: firebase.firestore.FieldValue.arrayUnion(moment().unix()),
          property_id: id,
        },
        { merge: true }
      );

    const blogMessageSnap = await db
      .collection("blogMessage")
      .where("resourceId", "==", id)
      .get();
    const posts = (blogMessageSnap.docs || []).map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });

    this.setState({
      posts,
    });

    sellerRef
      .get()
      .then((doc) => {
        const data = doc.data();
        if (data.seller_email) {
          this.loadSellerImage(data.seller_email);
        }
        data.projectedPrice = 0;
        this.loadLinkedBuyerProfile(data);
        sellerImageRef
          .getDownloadURL()
          .then((url) => {
            if (this.mounted) {
              data.photo = url;
              this.setState({ seller: data, realtorEmail: data.seller_email });
            }
            this.setState((s) => ({ loading: !s.loading }));
          })
          .catch((error) => {});

        db.collection("users")
          .doc(data.seller_id)
          .get()
          .then((doc) => {
            if (doc.exists) {
              this.setState({
                sellerUser: {
                  id: doc.id,
                  ...doc.data(),
                },
              });
            }
          });

        if (this.mounted) {
          this.setState({
            seller: data,
            representedBy: data.owner,
            realtorEmail: data.seller_email,
          });
        }
      })
      .catch((error) => {
        this.setState((s) => ({ loading: !s.loading }));
        console.error(error);
      });

    db.collection("matches")
      .where("sellerId", "==", id)
      .orderBy("score", "desc")
      .get()
      .then(
        (snapshot) => {
          if (this.mounted) {
            let { docs } = snapshot;

            const buyerPromises = docs.map((d) =>
              db.doc(`buyers/${d.data().buyerId}`).get()
            );
            Promise.all(buyerPromises).then((docs) => {
              if (this.mounted) {
                this.setState({ buyers: docs.filter((doc) => doc.exists) });
              }
            });
          }
        },
        (error) => {
          console.error(error);
        }
      );
    this.getAssociatedBuyers();

    this.setUnreadOffers(db, id);
  }

  setUnreadOffers = async (db, sellerID) => {
    const totalOffers = await db
      .collection("offers")
      .where("seller", "==", sellerID)
      .get();
    const offers = await db
      .collection("offers")
      .where("seller", "==", sellerID)
      .where("verified", "==", true)
      .get();
    const offers2 = await db
      .collection("offers")
      .where("seller", "==", sellerID)
      .where("status", "==", "Executed")
      .get();

    const offersAll = await db
      .collection("offers")
      .where("seller", "==", sellerID)
      .get();

    const offersAllData = offersAll.docs.map((doc) => {
      const data = doc.data();
      return {
        id: doc.id,
        ...data,
      };
    });
    let haveAccepted = false;
    const offersDocs = ([...offers.docs, ...offers2.docs] || []).map((doc) => {
      const data = doc.data();
      if (data.status === "Accepted" || data.status === "Executed")
        haveAccepted = true;
      return {
        id: doc.id,
        ...data,
      };
    });

    this.setState({
      allOffers: offersAllData,
    })
    
    if (!offersDocs.length) return null;
    this.setState({
      haveAccepted,
      totalOffers: totalOffers.size,
      verifiedOffers: offers.size,
      unReadOffers: offersDocs.length,
      totalPurchasePrice: this.calculatePrice(offersDocs),
      financingType: this.findFinancingType(offersDocs),
      totalOptionFee: this.optionFee(offersDocs),
      totalOptionDays: this.optionDays(offersDocs),
      totalExpenseType: this.getAllExpenseType(offersDocs),
      totalPosseions: this.getAllPossession(offersDocs),
      totalSaleOfProperty: this.getAllSaleOfProperty(offersDocs),
      totalRightToTerminate: this.getAllRightToTerminate(offersDocs),
      totalEarnestMoney: this.calculateEarnestMoney(offersDocs),
      closeingDate: this.calculateNumberOfDays(offersDocs),
      totalDownPayment: this.calculateDownPayment(offersDocs),
    });
  };

  calculateNumberOfDays(offersDocs) {
    let totalNumberOfDays = 0;
    let newArr = offersDocs.map((doc) => Number(doc.numberOfDays));
    newArr.forEach((num) => {
      if (num) totalNumberOfDays += num;
    });
    return totalNumberOfDays / offersDocs.length;
  }

  calculateDownPayment = (offersDocs) => {
    let totalDownPayment = 0;
    let newArr = offersDocs.map((doc) => Number(doc.downPayment));
    newArr.forEach((num) => {
      if (num) totalDownPayment += num;
    });
    return totalDownPayment / offersDocs.length;
  };

  calculateEarnestMoney(offersDocs) {
    let totalEarnestMoney = 0;
    let newArr = offersDocs.map((doc) => Number(doc.earnestMoney));
    newArr.forEach((num) => {
      if (num) totalEarnestMoney += num;
    });
    return totalEarnestMoney / offersDocs.length;
  }

  getAllRightToTerminate(offersDocs) {
    let totalRightToTerminate = [];
    offersDocs.map(({ rightToTerminate }, i) => {
      if (
        rightToTerminate !==
        totalRightToTerminate.find((elm) => elm === rightToTerminate)
      )
        totalRightToTerminate.push(rightToTerminate);
    });
    return totalRightToTerminate.join(", ");
  }

  getAllPossession(offersDocs) {
    let totalTotalPossession = [];
    offersDocs.map(({ possession }, i) => {
      if (possession !== totalTotalPossession.find((elm) => elm === possession))
        totalTotalPossession.push(possession);
    });
    return totalTotalPossession.join(", ");
  }

  optionDays(offersDocs) {
    const numbers = [];
    offersDocs.forEach((num) => {
      if (num.optionDays) numbers.push(parseInt(num.optionDays));
    });
    return `${Math.min(...numbers)} - ${Math.max(...numbers)}`;
  }

  getAllSaleOfProperty(offersDocs) {
    let saleOfProperty = [];
    offersDocs.map(({ saleOfOtherProperty }, i) => {
      if (
        saleOfOtherProperty !==
        saleOfProperty.find((elm) => elm === saleOfOtherProperty)
      )
        saleOfProperty.push(saleOfOtherProperty);
    });
    return saleOfProperty.join(", ");
  }

  getAllExpenseType(offersDocs) {
    let totalExpenseType = [];
    offersDocs.map(({ titlePolicyExpense }, i) => {
      if (
        titlePolicyExpense !==
        totalExpenseType.find((elm) => elm === titlePolicyExpense)
      )
        totalExpenseType.push(titlePolicyExpense);
    });
    return totalExpenseType.join(", ");
  }

  findFinancingType(offersDocs) {
    let financeType = [];
    offersDocs.map(({ financingType }, i) => {
      if (financingType !== financeType.find((elm) => elm === financingType))
        financeType.push(financingType);
    });
    return financeType.join(", ");
  }

  optionFee(offersDocs) {
    let totalOptionFee = 0;
    let newArr = offersDocs.map((doc) => Number(doc.optionFee));
    newArr.forEach((num) => {
      if (num) totalOptionFee += num;
    });
    return totalOptionFee / offersDocs.length;
  }
  calculatePrice(offersDocs) {
    let totalPurchasePrice = 0;
    offersDocs.map((doc, i) => {
      totalPurchasePrice += parseInt(doc.purchasePrice);
    });
    return totalPurchasePrice / offersDocs.length;
  }

  onGrowithRateUpdate = (growth) => {
    const { seller } = this.state;
    let { current_value, expected_listing_year } = this.state.seller;
    current_value = parseInt(current_value);

    if (expected_listing_year) {
      expected_listing_year = parseInt(expected_listing_year);
      let currentYear = moment().format("YYYY");
      currentYear = parseInt(currentYear);
      for (let i = currentYear; i !== expected_listing_year; i++) {
        const growthAmount = (growth / 100) * current_value;
        current_value = current_value + growthAmount;
        current_value = parseInt(current_value);
      }
    }

    seller.projectedPrice = current_value;
    seller.growth = growth;

    this.setState({
      seller,
    });
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount() {
    const { history } = this.props;
    if (history.location.search.indexOf("trends=1") > 0)
      this.offerTrendsModalToggle();
    this.mounted = true;
  }

  setValue(inputId, value) {
    const { inputs } = this.state;
    inputs[inputId] = value;
    this.setState({ inputs });
  }

  getAssociatedBuyerById = (id) => {
    const { associatedBuyers } = this.state;
    let buyer = null;
    associatedBuyers.forEach((b) => {
      if (id === b.id) {
        buyer = b;
      }
    });
    return buyer;
  };

  async save(e) {
    e.preventDefault();

    const { inputs, seller } = this.state;
    const { user } = this.props;
    inputs.buyer = this.getAssociatedBuyerById(inputs.buyer) || {};
    this.setState({ busy: true });
    if (user.full_name) {
      inputs.requestorName = user.full_name;
    }
    if (user.email) {
      inputs.requestorEmail = user.email;
    }
    if (user.phone) {
      inputs.requestorContact = user.phone;
    }
    inputs.requestorId = user.uid;
    inputs.requestorRole = user.role;
    inputs.requestStatus = "new";
    inputs.requestorImage =
      user.profilePhoto && user.profilePhoto[128]
        ? user.profilePhoto[128]
        : user.profilePhoto && user.profilePhoto[64]
        ? user.profilePhoto[64]
        : null;
    let newSellerObject = { ...this.state.seller };
    if (newSellerObject.hasOwnProperty("tourRequests")) {
      newSellerObject.tourRequests.push(inputs);
    } else {
      newSellerObject.tourRequests = [inputs];
    }
    const db = firebase.firestore();
    const sendTourEmail = firebase.functions().httpsCallable("sendTourEmail");

    logEvent("tour_email", {
      seller_email: seller.seller_email,
    });

    const body = ReactDOMServer.renderToStaticMarkup(
      tourEmail(inputs, seller, this.id())
    );

    let ccEmails = [];
    let owner = await db.collection("users").doc(seller.owner).get();
    if (owner.exists) {
      owner = owner.data();
      if (owner.agent_available === "No" && owner.agent_needed === "No") {
        ccEmails = [owner.user_email];
      } else {
        ccEmails = [...(owner.agent_emails || [])];
      }
    }

    sendTourEmail({ email: seller.seller_email, body, cc: ccEmails })
      .then((result) => {
        db.collection("sellers")
          .doc(this.id())
          .update(newSellerObject)
          .then((sellerRef) => {
            window.$("#visitModal").modal("toggle");
            this.setState({
              busy: false,
              inputs: {},
              alreadyTourRequested: true,
            });
          })
          .catch((error) => {
            this.setState({ busy: false });
            console.error(error);
          });
      })
      .catch((error) => {
        this.setState({ busy: false });
        console.error(error);
      });
  }

  matches() {
    const { buyers } = this.state;
    const perPage = 3;

    if (!buyers) {
      return null;
    }

    const pageCount = buyers.length / perPage;
    const pagers = [];
    const pages = [];
    const matchTiles = buyers
      .filter((doc) => !this.isOwnBuyingProfile(doc))
      .map((doc) => <BuyerTile key={doc.id} doc={doc} />);

    for (let i = 0; i < pageCount; i++) {
      let active = "";
      if (i === 0) {
        active = "active";
      }

      pagers.push(
        <li
          key={i}
          data-target="#matches"
          data-slide-to={i}
          className={active}
        ></li>
      );

      const slice = matchTiles.slice(
        i * 3,
        Math.min(i * 3 + 4, matchTiles.length)
      );
      pages.push(
        <div key={i} className={`carousel-item ${active}`}>
          <div className="row">{slice}</div>
        </div>
      );
    }

    return (
      <>
        <h4>Potential Buyers</h4>
        <div id="matches" className="carousel slide" data-ride="carousel">
          <ol className="carousel-indicators">{pagers}</ol>

          <div className="carousel-inner">{pages}</div>

          <a
            className="carousel-control-prev"
            href="#matches"
            role="button"
            data-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href="#matches"
            role="button"
            data-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </>
    );
  }

  address(seller) {
    const { street_number, street_name, city, state, zip_code, location, unit_number } = seller;
    const getLocationWithUnitAndZip = () => {
      if (!location?.name) return null;
      let modifiedLocationName = location.name;
      if (unit_number && !location.name.includes(unit_number)) {
        const streetPattern = new RegExp(`(${street_number} ${street_name})`);
        modifiedLocationName = location.name.replace(streetPattern, `$1 ${unit_number}`);
      }
      if (!modifiedLocationName.includes(zip_code)) {
        modifiedLocationName += `, ${zip_code}`;
      }
      return modifiedLocationName;
    };

    if (!seller.ListingKey) {
      return getLocationWithUnitAndZip() || `${street_number} ${street_name} ${unit_number}, ${city}, ${state}, ${zip_code}`;
    }
    if (seller.hideAddress) {
      return `${city}, ${state}, ${zip_code || ""}`;
    }
    return `${street_number} ${street_name}, ${city}, ${state}, ${zip_code || ""}`;
  }
  

  // address(seller) {
  //   const { street_number, street_name, city, state, zip_code, location, unit_number } = seller;
  //   const getLocationWithZip = () => {
  //     if (!location?.name) return null;
  //     const zipExistsInName = location.name.includes(zip_code);
  //     console.log(zipExistsInName, "zipExistsInName")
  //     return zipExistsInName ? location.name : `${location.name}, ${zip_code}`;
  //   };

  //   if (!seller.ListingKey) {
  //     console.log("no listing key-- 11111111111111111111111111111111", unit_number);
  //     return getLocationWithZip() || `${street_number} ${street_name}, ${unit_number}, ${city}, ${state}, ${zip_code}`;
  //   }
  //   if (seller.hideAddress) {
  //     console.log("hide address-222222222222222222222222222222222222222");
  //     return `${city}, ${state}, ${zip_code || ""}`;
  //   }
  //   console.log("no hide address 222233333333333333333333333333333333333333333");
  //   return `${street_number} ${street_name}, ${city}, ${state}, ${
  //     zip_code || ""
  //   }`;
  // }

  renderTooltip = (id, text) => {
    return (
      <Tooltip
        hideArrow={true}
        style={{ backgroundColor: "#ffffff", color: "black" }}
        placement="auto"
        isOpen={this.state[id]}
        target={id}
        toggle={() => this.toggle(id)}
      >
        {text}
      </Tooltip>
    );
  };

  carousel(seller) {
    if (!seller) return null;

    let galleryKeys = [
      "homeGallery",
      "bedroomsGallery",
      "kitchenGallery",
      "bathroomGallery",
      "familyRoomGallery",
      "backyardGallery",
      "diningRoomGallery",
      "laundryRoomGallery",
      "mediaRoomGallery",
      "gameRoomGallery",
      "garageGallery",
      "gallery",
    ];

    if (seller.galleryCheckbox) {
      galleryKeys = ["gallery"];
    }

    const images = [];

    galleryKeys.forEach((key) => {
      if (seller[key]) {
        seller[key].forEach((img) => {
          images.push({
            original: img.url,
            thumbnail: img.url,
          });
        });
      }
    });

    return images.length ? (
      <ImageGallery
        autoPlay={true}
        showNav={false}
        showBullets={false}
        items={images}
      />
    ) : null;
  }

  doubleTakeProperty = async () => {
    const { seller } = this.state;
    const { user } = this.props;
    const db = firebase.firestore();
    if (!this.props.user) {
      // alert("either signup or login first!!")
      return this.props.history.push("/login");
    }

    const userDoc = db.collection("users").doc(user.uid);

    await userDoc.get().then((doc) => {
      const data = doc.data();
      this.setState({ fetchedUser: data });
    });

    let likedProps = [];
    if (
      this.state.fetchedUser.staredProps &&
      this.state.fetchedUser.staredProps.length
    ) {
      likedProps = [...this.state.fetchedUser.staredProps];
    }

    if (seller.likedByBuyers) {
      if (seller.likedByBuyers.includes(this.props.user.uid)) {
        seller.likedByBuyers.splice(
          seller.likedByBuyers.indexOf(this.props.user.uid),
          1
        );
        likedProps.splice(
          likedProps
            .map(function (e) {
              return e.HMX_Id;
            })
            .indexOf(seller.HMX_Id),
          1
        );
      } else {
        seller.likedByBuyers.push(this.props.user.uid);
        likedProps.push({
          HMX_Id: seller.HMX_Id || seller.ListingId || "",
          city: seller.city,
          imageURL:
            seller.featured_image && seller.featured_image.url
              ? seller.featured_image.url
              : "",
          seller_name: seller.seller_name,
          realtor_name: seller.realtor_name,
          state: seller.state,
          street_name: seller.street_name,
          street_number: seller.street_number,
          zipCode: seller.zip_code,
          property_id: this.id(),
        });
      }
    } else {
      seller.likedByBuyers = [this.props.user.uid];
      likedProps.push({
        HMX_Id: seller.HMX_Id || seller.ListingId || "",
        city: seller.city,
        imageURL:
          seller.featured_image && seller.featured_image.url
            ? seller.featured_image.url
            : "",
        seller_name: seller.seller_name,
        realtor_name: seller.realtor_name,
        state: seller.state,
        street_name: seller.street_name,
        street_number: seller.street_number,
        zipCode: seller.zip_code,
      });
    }

    // const db = firebase.firestore()
    const sellerRef = db.collection("sellers").doc(this.id());
    sellerRef
      .update(seller)
      .then((sellerRef) => {
        this.setState({ seller });
      })
      .catch((error) => console.error(error));
    const { realtor_name, lender_name, full_name } = this.props.user;

    let updatedUserObject = {
      ...this.state.fetchedUser,
      full_name: realtor_name || lender_name || full_name || "User",
      staredProps: likedProps || [],
    };

    userDoc
      .update(updatedUserObject)
      .then((userRef) => {
        console.log("user updated");
      })
      .catch((error) => console.error(error));
  };

  requestTour = (sellerInfo) => {
    const { user } = this.props;
    if (user) {
      window.$("#visitModal").modal();
    } else {
      alert("please login or signup to request tour");
    }
  };

  toggleModal = () => {
    this.setState({
      blvModal: !this.state.blvModal,
    });
  };

  checkUser = (data) => {
    return data.requestorId === this.props.user.uid;
  };

  renderRequestTourButton = () => {
    const { seller, alreadyTourRequested, followers, groups } = this.state;
    const {
      private_tours_allowed,
      offerDeadline,
      offerDeadlineDate,
      offerDeadlineTime,
    } = seller;
    const { user } = this.props;
    const { role } = user || {};
    const id = this.id();

    const jsx = (
      <>
        <ShareSellerModal
          {...this.props}
          user={user}
          seller={seller}
          followers={followers}
          groups={groups}
        />
        {offerDeadline === "Yes" && (
          <div
            style={{
              marginBottom: 10,
              border: "1px solid #eee",
              // marginTop: "7px",
            }}
          >
            <Link
              className="btn button-theme btn-block offer-end-time"
              to={`/makeOffer/${id}`}
            >
              Offer Deadline <br /> <> {offerDeadlineDate} </>
              {moment(offerDeadlineTime, "h:mm a").format("h:mm a")}
            </Link>
          </div>
        )}
      </>
    );
    if (role && role === "lender") return jsx;

    if (private_tours_allowed === "Yes" && !alreadyTourRequested) {
      return (
        <>
          {/* <Button color="danger" className="btn-theme" block onClick={() => this.requestTour(seller)}> Request tour </Button> */}
          {jsx}
        </>
      );
    }

    if (private_tours_allowed === "Yes" && alreadyTourRequested) {
      return (
        <>
          {/* <button className="btn btn-md button-theme" disabled>Tour requested</button> */}
          {jsx}
        </>
      );
    }

    return jsx;
  };

  onReady = (mapProps, map) => {
    const google = mapProps.google;
    this.addMarker(mapProps, map);
    google.maps.event.addListener(map, "center_changed", () =>
      this.centerChanged(mapProps, map)
    );
  };

  addMarker = (mapProps, map) => {
    const { seller } = this.state;
    const location = seller.location || {};
    const maps = mapProps.google.maps;
    this.marker = new maps.Marker({
      position: location,
      map,
    });
  };

  renderLocationMap = () => {
    const { seller } = this.state;
    if (seller.location) {
      return (
        <>
          <Map
            className="profile-google-map"
            style={{ width: "100%", height: "300px" }}
            google={this.props.google}
            zoom={16}
            initialCenter={seller.location}
            onReady={this.onReady}
          />
        </>
      );
    }
  };

  isOwnBuyingProfile = (buyerProfile) => {
    const { seller } = this.state;
    const { HMX_Id, ListingId } = seller;
    if (buyerProfile && seller) {
      const { home_hmxid, home_mlsid, selling_current_home } =
        buyerProfile.data();
      if (
        (home_hmxid === HMX_Id || home_mlsid === ListingId) &&
        selling_current_home === "Yes"
      ) {
        return true;
      }
    }
    return false;
  };

  renderSellingProfiles = () => {
    const { buyerProfile } = this.state;
    if (this.isOwnBuyingProfile(buyerProfile)) {
      return (
        <>
          <h4>My Buying Profile</h4>
          <BuyerTile key={buyerProfile.id} doc={buyerProfile} />
          <hr></hr>
        </>
      );
    }
  };

  renderBuildYear = (seller) => {
    const { year_built, construction_status } = seller;
    if (year_built && construction_status) return `${year_built} / ${construction_status}`;

    if (construction_status) return construction_status;

    return year_built;
  };

  renderNewStatus = () => {
    const { seller } = this.state;
    const { construction_status } = seller;
    const newConstructions = ["Incomplete", "Complete", "Area Development"];
    if (newConstructions.includes(construction_status)) {
      return (
        <p
          style={{
            zIndex: 9,
            position: "absolute",
            fontSize: "14px",
            margin: "5px",
            borderRadius: "20px",
            float: "left",
            color: "#fff",
            fontWeight: "700",
            backgroundColor: "#28a745",
            padding: "3px 7px",
          }}
        >
          New
        </p>
      );
    }
  };

  renderMLSIcon = () => {
    const { seller } = this.state;

    let { type } = seller;
    if (type !== "MLS") {
      type = "HMX";
    }

    // return <p style={{zIndex:9,position:"absolute",fontSize:"14px",margin: "8px", left:"30px",borderRadius: "20px",float:"left",color: "#fff","fontWeight": "700",backgroundColor: "#28a745",padding: "3px 7px"}}>{type}</p>;
  };

  toggle = () => {
    const { showDeadlinePopup } = this.state;
    this.setState({
      showDeadlinePopup: !showDeadlinePopup,
    });
  };

  showDeadlinePopup = () => {
    const { seller, showDeadlinePopup } = this.state;
    const { offerDeadline, offerDeadlineDate } = seller || {};

    if (offerDeadline === "Yes" && offerDeadlineDate !== "") {
      return (
        <Modal
          isOpen={!showDeadlinePopup}
          toggle={this.toggle}
          className="modal-danger modal-lg"
        >
          <ModalHeader toggle={this.toggle}>Offer Deadline</ModalHeader>
          <ModalBody>
            This Property has offer Deadline, please submit your offer before
            deadline.
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>
              Ok
            </Button>
          </ModalFooter>
        </Modal>
      );
    }

    return null;
  };

  render() {
    let {
      totalPurchasePrice,
      financingType,
      totalOptionDays,
      totalExpenseType,
      totalPosseions,
      totalSaleOfProperty,
      totalRightToTerminate,
      totalEarnestMoney,
      closeingDate,
      totalOffers,
      verifiedOffers,
      haveAccepted,
      allOffers,
      bestMatches,
      sellerUser,
      groups,
    } = this.state;

    let showThankMeFunctionalities;

    const numToFixed = (num) => Number(num).toFixed(2);

    if (
      sellerUser.id === "xIqPYqRjVrZ67J9M9xGuQFsaGDC2" ||
      sellerUser.id === this.props.user?.uid
    ) {
      showThankMeFunctionalities = true;
    } else {
      if (
        (sellerUser.subscriptionId &&
          new Date(sellerUser?.subscriptionEndDate * 1000) >
            new Date(Date.now())) ||
        sellerUser.id === this.props.user?.uid
      ) {
        showThankMeFunctionalities = true;
      }
    }

    const {
      seller,
      representedBy,
      realtorEmail,
      inputs,
      busy,
      associatedBuyers,
      statusColors,
      posts,
      unReadOffers,
    } = this.state;
    const virtualUrls = [];
    const listingPrice = parseInt(
      seller?.expected_sales_price ||
        0 ||
        seller?.projectedPrice ||
        seller?.current_value ||
        seller?.listing_price
    );
    const fields = {
      ...tourFormFields,
      buyer: {
        ...tourFormFields.buyer,
        options: associatedBuyers.map((b) => {
          return { key: b.id, value: b.buyer_name };
        }),
      },
    };

    const currencyFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    });

    let realtorInfo = null;
    const currentURL = window.location.href;

    const allClientOffers =
      allOffers &&
      allOffers?.map((e) => {
        return (
          <tr className="table-row" key={e.createdAt}>
            <td
              colSpan={3}
              className={
                bestMatches && bestMatches[0] && bestMatches[0].id === e.id
                  ? "address-td first-td"
                  : "address-td"
              }
            >
              {bestMatches && bestMatches[0] && bestMatches[0].id === e.id && (
                <p className="my-listings-best-match-tag">
                  <i class="fa fa-heart"></i>BEST MATCH
                </p>
              )}
              {e.address}
            </td>
            <td
              className={`p-0 ${
                bestMatches &&
                bestMatches[0] &&
                bestMatches[0].id === e.id &&
                `td-in-btwn`
              }`}
            >
              <div className="row">
                {e.verified && (
                  <div className="col-1 m-0 p-0">
                    <img
                      className="mr-1"
                      src={VerifiedListing}
                      style={{ width: "auto" }}
                    />
                  </div>
                )}
                <div className={`${e.verified ? "col-11" : "col-12"}`}>
                  {e.status === "In Review" ? (
                    <span className="all-client-offers-badge-width badge review-badge">
                      <i className="fa fa-repeat"></i>In Review
                    </span>
                  ) : e.status === "Not Accepted" ? (
                    <span className="all-client-offers-badge-width badge not-accepted-badge">
                      <i className="fa fa-times"></i>Not Accepted
                    </span>
                  ) : e.status === "Withdraw" ? (
                    <span className="all-client-offers-badge-width badge badge-black status-badge">
                      <i className="fa fa-check"></i>Withdrawn
                    </span>
                  ) : e.status === "Terminated" ? (
                    <span className="all-client-offers-badge-width badge badge-black status-badge">
                      <i className="fa fa-check"></i>Terminated
                    </span>
                  ) : e.status === "Not Accepted" ? (
                    <span className="all-client-offers-badge-width badge not-accepted-badge status-badge">
                      <i className="fa fa-times"></i>Not Accepted
                    </span>
                  ) : e.status === "Executed" ? (
                    <span className="all-client-offers-badge-width badge executed-badge status-badge">
                      <i className="fa fa-check"></i>Executed
                    </span>
                  ) : (
                    <span className="all-client-offers-badge-width badge badge-success status-badge">
                      <i className="fa fa-check"></i>Accepted
                    </span>
                  )}
                </div>
              </div>
            </td>

            <td
              className={
                bestMatches &&
                bestMatches[0] &&
                bestMatches[0].id === e.id &&
                "td-in-btwn"
              }
            >
              {moment(e.createdAt * 1000).format("DD/MM/YYYY")}
            </td>
            <td
              className={
                bestMatches &&
                bestMatches[0] &&
                bestMatches[0].id === e.id &&
                "td-in-btwn"
              }
            >
              {e.purchasePrice
                ? "$" +
                  e.purchasePrice
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : "N/A"}
            </td>
            <AgentInformationModal
              getGlobalAgents={this.getGlobalAgents}
              globalAgents={this.props.globalAgents}
              comingFrom="globalOffers"
              key={e.id}
              offer={e}
              user={this.props.user}
            />
            <td
              className={
                bestMatches &&
                bestMatches[0] &&
                bestMatches[0].id === e.id &&
                "last-td"
              }
            >
              <Link
                to={{
                  pathname: `${
                    e.sellerEmail === this.props.user?.email
                      ? `/offers/${e.id}`
                      : `/otherAgent/offer/${e.id}`
                  }`,
                  search: `?prevUrl=${encodeURIComponent(currentURL)}
                    `,
                  state: {
                    // globalAgents: globalAgents,
                    // getGlobalAgents: getGlobalAgents,
                    allOffers: allOffers,
                    totalItems: allOffers.length,
                    // curItem:
                    //   allSellersList
                    //     ?.filter((e) =>
                    //       selectedSellers.map((e) => e.id).includes(e.id)
                    //     )
                    //     .findIndex((el) => el.id === e.id) + 1,
                    curItem: allOffers.findIndex((el) => el.id === e.id) + 1,
                  },
                }}
              >
                <Button className="go-to-offer-btn mb-0">Go to Offer</Button>
              </Link>
            </td>
          </tr>
        );
      });

    if (representedBy) {
      realtorInfo = (
        <RealtorInfo
          id={seller.seller_id}
          type={seller.type}
          seller={seller}
          onBuilderUpdate={this.onBuilderUpdate}
          realtorEmail={realtorEmail}
          user={this.props.user}
        />
      );
    }

    if (seller) {
      let soldDate = null;
      const { removed, reason, purchasedDate } = seller;

      if (purchasedDate) {
        soldDate = moment(purchasedDate, "DD/MM/YYYY");
      }

      if (removed && reason && reason.reason) {
        soldDate = moment(new Date(reason.soldDate));
      }

      if (seller.VirtualTourURLUnbranded) {
        virtualUrls.push(seller.VirtualTourURLUnbranded);
      }

      if (seller.VirtualTourURLBranded) {
        virtualUrls.push(seller.VirtualTourURLBranded);
      }
      const toggle = () => this.setState({ isTooltip: !this.state.isTooltip });

      return (
        <>
          <div>
            <div className="container-fuild">
              <div className="property-container">
                <div className="property-main">
                  <div className="property-row">
                    <div className="main-col sellerPropImg">
                      {this.showDeadlinePopup()}
                      {this.renderNewStatus()}
                      {this.renderMLSIcon()}
                      {this.carousel(seller)}
                    </div>
                    <div className="side-col">
                      <div className="property-info-box">
                        <div>
                          <div className="mb-4">
                            <h4 className="text-white">
                              {this.address(seller)}
                            </h4>
                            {seller.status ? (
                              <span
                                className={`badge badge-${
                                  statusColors[seller.status] || "dark"
                                } px-3`}
                              >
                                {seller.status || "-"}
                              </span>
                            ) : (
                              seller.MlsStatus && (
                                <span
                                  className={`badge badge-${
                                    statusColors[seller.MlsStatus] || "dark"
                                  } px-3`}
                                >
                                {seller.MlsStatus === "Sold" ? "Closed" : seller.MlsStatus}
                                </span>
                              )
                            )}
                            {/* {unReadOffers >= 3  */}
                            {allOffers?.length > 1 && (
                              <span className="badge badge-danger px-3 ml-3">
                                Multiple Offers
                              </span>
                            )}
                          </div>

                          <div className="d-flex flex-column mb-2 lissting-price-container">
                            <span className="mb-1 text-white">
                              {seller.type === "Active"
                                ? "Projected Price"
                                : "Listing Price"}
                              {seller.is_price_flexible &&
                                seller.is_price_flexible === "Yes" && (
                                  <sup
                                    id="priceTooltip"
                                    className="flexible-date btn-pink-color"
                                  >
                                    {" "}
                                    Flexible{" "}
                                    {this.renderTooltip(
                                      "priceTooltip",
                                      "Price is flexible."
                                    )}
                                  </sup>
                                )}
                            </span>
                            <h3 className="font-weight-bold text-white">
                              {currencyFormatter.format(
                                parseInt(seller.expected_sales_price || "0") ||
                                  seller.projectedPrice ||
                                  seller.current_value ||
                                  seller.listing_price
                              )}
                            </h3>
                          </div>

                          <dl className="row mb-4">
                            {seller.is_flip_property === "Yes" &&
                              parseInt(seller.arv || "0") > 0 && (
                                <>
                                  <dt className="col-md-5">ARV</dt>
                                  <dd className="col-md-7">
                                    {currencyFormatter.format(
                                      parseInt(seller.arv)
                                    )}
                                  </dd>
                                </>
                              )}

                            <dt className="col-md-5">Bedrooms</dt>
                            <dd className="col-md-7">
                              {seller.bedrooms || "-"}
                            </dd>
                            <dt className="col-md-5">Bathrooms</dt>
                            <dd className="col-md-7">
                              {seller.bathrooms || "-"}
                            </dd>
                            <dt className="col-md-5">Area</dt>
                            <dd className="col-md-7">
                              {seller?.ListingKey ? seller.living_areas || seller.LivingArea || "0" : seller?.sqft}{" "}
                              {seller?.ListingKey ? seller?.LivingAreaUnits || seller?.BuildingAreaUnits : "Square Feet"}
                            </dd>
                            {this.renderBuildYear(seller) && (
                              <>
                                <dt className="col-md-5">Year Built</dt>
                                <dd className="col-md-7">
                                  {this.renderBuildYear(seller)}
                                </dd>
                              </>
                            )}
                            <dt className="col-md-5">Listing ID</dt>
                            <dd className="col-md-5">{seller?.ListingKey ? seller.ListingId : seller?.HMX_Id}</dd>
                            {/* <dt className="col-md-5">Days On Market</dt>
                                                    {(seller.type === "MLS") &&
                                                        <dd className="col-md-7">{seller.CumulativeDaysOnMarket} days</dd>} */}
                          </dl>
                        </div>

                        {/* <div className="mb-3">Accepting Backup Offers</div> */}
                        <div className="d-flex flex-column">
                          {/* <Link className="btn btn-outline-light mb-3" to={`/makeOffer/${this.id()}`}>Make an Offer</Link> */}
                          {
                            // this.props.user?.uid !== seller?.seller_id &&
                            <Link
                              className="btn btn-outline-light mb-3"
                              to={{
                                pathname: `/makeOffer/${this.id()}`,
                                state: {
                                  propertyAddress: this.address(seller),
                                },
                              }}
                            >
                              Make an Offer
                            </Link>
                          }
                          <div className="row mt-3">
                            <div className="col-5 p-0">
                              {this.renderRequestTourButton()}
                            </div>
                            <div className="col-7 p-0 my-auto">
                              {(haveAccepted &&
                                seller.MlsStatus === "Closed" &&
                                allOffers[0].sellerEmail ==
                                  this.props.user?.email) ||
                              this.state.usersAllAllowedToViewProperties.includes(
                                allOffers[0]?.seller
                              ) ||
                              this.props.user?.email ==
                                "inkrealtygroup@gmail.com" ? (
                                <button
                                  className="view-trend-offers-btn float-right trend-text"
                                  onClick={() => {
                                    this.offerTrendsModalToggle();
                                    const db = firebase.firestore();
                                    db.collection("sellers")
                                      .doc(allOffers[0].seller)
                                      .update({
                                        offerTrendsClickCount:
                                          firebase.firestore.FieldValue.increment(
                                            1
                                          ),
                                      });
                                  }}
                                >
                                  <img src={trendIcon} />
                                  View OfferTrends
                                </button>
                              ) : null}
                            </div>
                          </div>

                          {/* {showThankMeFunctionalities &&
                           */}
                            {this.state.seller.MlsStatus === "Closed" || this.state.seller.MlsStatus === "Sold" && (
                              <div className="row mt-3">
                                {/* <div className="col-md-6 p-0 pr-1">
                                  {!this.state.usersAllAllowedToViewProperties.includes(
                                    this.state.allOffers[0]?.seller
                                  ) && (
                                    <div className="info-icon-tooltip-container w-100">
                                      <button
                                        style={{
                                          border: "1px solid #EBEBEB",
                                          borderRadius: "5px",
                                          padding: "9px 17px",
                                          fontSize: "12px",
                                          fontWeight: "600",
                                          background: "none",
                                          cursor: "pointer",
                                        }}
                                        className="mr-2 info-icon w-100"
                                        onClick={() => {
                                          const db = firebase.firestore();
                                          const sellerRef = db
                                            .collection("sellers")
                                            .doc(allOffers[0].seller);
                                          sellerRef.update({
                                            thankMeClickCount:
                                              firebase.firestore.FieldValue.increment(
                                                1
                                              ),
                                          });
                                          if (
                                            this.state
                                              .thankedPropertiesThisMonth < 5
                                          ) {
                                            this.handleThankMe(
                                              this.state.allOffers[0],
                                              this.state.allOffers[0].seller_id
                                            );
                                          }
                                        }}
                                        onMouseEnter={() => {
                                          this.setState({
                                            showTooltip2: true,
                                          });
                                        }}
                                        onMouseLeave={() => {
                                          this.setState({
                                            showTooltip2: false,
                                          });
                                        }}
                                      >
                                        <span
                                          style={{
                                            color: "#fff",
                                          }}
                                        >
                                          {" "}
                                          $Thank Me
                                        </span>
                                      </button>
                                      {this.state.showTooltip2 && (
                                        <div className="tooltip-content-property-page">
                                          You have thanked{" "}
                                          {
                                            this.state
                                              .thankedPropertiesThisMonth
                                          }
                                          /5 properties this month. Subscribe to
                                          our premium plan to thank more than 5.
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div> */}
                                <div className="col-md-12 p-0 pl-1">
                                  <div className="info-icon-tooltip-container w-100">
                                    <button
                                      className="global-chart-button-margin-bottom btn-success w-100"
                                      style={{
                                        // color: "#28A745",
                                        border: "1px solid #EBEBEB",
                                        padding: "9px 17px",
                                        fontSize: "12px",
                                        // background: "none",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                        // width: "100%",
                                      }}
                                      onMouseLeave={() => {
                                        this.setState({
                                          showTooltip: false,
                                        });
                                      }}
                                      onClick={() => {
                                        this.setState({
                                          showOffersModal: true,
                                        });

                                        // if (
                                        //   allOffers[0]?.sellerEmail ==
                                        //     this.props?.user?.email ||
                                        //   this.state?.usersAllAllowedToViewProperties?.includes(
                                        //     allOffers[0]?.seller
                                        //   ) ||
                                        //   this.props.user?.email ==
                                        //     "inkrealtygroup@gmail.com"
                                        // ) {
                                        //   this.setState({
                                        //     showOffersModal: true,
                                        //   });
                                        // } else {
                                        //   this.setState({
                                        //     showTooltip: true,
                                        //   });
                                        // }
                                      }}
                                    >
                                      See{" "}
                                      {allOffers.length > 1
                                        ? allOffers.length + " Offers"
                                        : allOffers.length + " Offer"}
                                    </button>
                                  </div>
                                  {this.state.showTooltip && (
                                    <div className="tooltip-content-property-page">
                                      $Thank Me to view offers details
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}

                          {/* <Button className="btn btn-success"  to={`/`}>Buy Now</Button> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="property-row">
                    <div className="main-col">
                      <div
                        style={
                          window.innerWidth > 768
                            ? {
                                textAlign: "center",
                                margin: "25px auto",
                              }
                            : { display: "none" }
                        }
                      >
                        {seller.interactive_floor_plans ? (
                          <Button
                            onClick={() => {
                              this.refs.interactive_floor_plans.scrollIntoView({
                                behavior: "smooth",
                                block: "start",
                              });
                            }}
                            outline
                            color="danger"
                            style={{ margin: "5px", width: "190px" }}
                          >
                            Interactive Floor Plans
                          </Button>
                        ) : (
                          ""
                        )}
                        {seller.home_videos ? (
                          <Button
                            onClick={() => {
                              this.refs.home_videos.scrollIntoView({
                                behavior: "smooth",
                                block: "start",
                              });
                            }}
                            outline
                            color="danger"
                            style={{ margin: "5px", width: "190px" }}
                          >
                            Home Videos
                          </Button>
                        ) : (
                          ""
                        )}
                        {seller.community_view ? (
                          <Button
                            onClick={() => {
                              this.refs.community_view.scrollIntoView({
                                behavior: "smooth",
                                block: "start",
                              });
                            }}
                            outline
                            color="danger"
                            style={{ margin: "5px", width: "190px" }}
                          >
                            Community View
                          </Button>
                        ) : (
                          ""
                        )}
                        {seller.virtual_design_center ? (
                          <Button
                            onClick={() => {
                              this.refs.virtual_design_center.scrollIntoView({
                                behavior: "smooth",
                                block: "start",
                              });
                            }}
                            outline
                            color="danger"
                            style={{ margin: "5px", width: "190px" }}
                          >
                            Design Center Tour
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>

                      <div>
                        <div className="card mb-5 property-card-padding">
                          <h6 className="card-header">Property Description</h6>
                          <p className="card-body">
                            {seller.property_description
                              ? seller.property_description
                              : ""}
                          </p>
                        </div>
                        <div className="card mb-5 property-card-padding">
                          <h6 className="card-header">Special Instructions</h6>
                          <p className="card-body">
                            {seller.special_instructions
                              ? seller.special_instructions
                              : ""}
                          </p>
                        </div>

                        <div className="card mb-5 property-card-padding">
                          <h6 className="card-header">Overview</h6>
                          <div className="card-body overview-card">
                            <div className="overview-item">
                              <img
                                src={story_icon}
                                alt="story"
                                className="overview-icon"
                              ></img>
                              <div className="overview-item-info">
                                <span>Story</span>
                                <strong>
                                  {seller.Levels || seller.stories || "0"}
                                </strong>
                              </div>
                            </div>

                            <div className="overview-item">
                              <img
                                src={bedrooms_icon}
                                alt="bedrooms"
                                className="overview-icon"
                              ></img>
                              <div className="overview-item-info">
                                <span>Bedrooms</span>
                                <strong>{seller.bedrooms || "0"}</strong>
                              </div>
                            </div>

                            <div className="overview-item">
                              <img
                                src={bathrooms_icon}
                                alt="bathrooms"
                                className="overview-icon"
                              ></img>
                              <div className="overview-item-info">
                                <span>Bathrooms</span>
                                <strong>{seller.bathrooms || "0"}</strong>
                              </div>
                            </div>

                            <div className="overview-item">
                              <img
                                src={half_baths_icon}
                                alt="half_baths"
                                className="overview-icon"
                              ></img>
                              <div className="overview-item-info">
                                <span>Half Baths</span>
                                <strong>
                                  {seller.half_baths ||
                                    seller.BathroomsHalf ||
                                    "0"}
                                </strong>
                              </div>
                            </div>

                            <div className="overview-item">
                              <img
                                src={living_areas_icon}
                                alt="living_areas"
                                className="overview-icon livin-area"
                              ></img>
                              <div className="overview-item-info">
                                <span>Living Areas</span>
                                <strong>
                                  {seller.NTREIS2_NumberOfLivingAreas ||
                                    seller.living_areas ||
                                    seller.LivingArea ||
                                    "0"}{" "}
                                  {seller.LivingAreaUnits || ""}
                                </strong>
                              </div>
                            </div>

                            <div className="overview-item">
                              <img
                                src={dining_areas_icon}
                                alt="dining_areas"
                                className="overview-icon dining-areas"
                              ></img>
                              <div className="overview-item-info">
                                <span>Dining Areas</span>
                                <strong>
                                  {seller.NTREIS2_NumberOfDiningAreas ||
                                    seller.dining_areas ||
                                    seller.NTREIS2_NumberOfDiningAreas ||
                                    "0"}
                                </strong>
                              </div>
                            </div>

                            <div className="overview-item">
                              <img
                                src={garage_icon}
                                alt="garage"
                                className="overview-icon"
                              ></img>
                              <div className="overview-item-info">
                                <span>Garage</span>
                                <strong>{seller.garage || "0"}</strong>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="card mb-5 property-card-padding">
                          <h6 className="card-header">Property Details</h6>

                          <div className="card-body">
                            <div className="details">
                              <div className="details-item">
                                <strong>Property Type</strong>
                                <span>{seller?.property_type || "-"}</span>
                              </div>

                              <div className="details-item">
                                <strong>Sqft Source</strong>
                                <span>{seller?.sqft_source || "-"}</span>
                              </div>

                              <div className="details-item">
                                <strong>Acres</strong>
                                <span>{seller?.acres || "-"}</span>
                              </div>

                              <div className="details-item">
                                <strong>Heating/Cooling</strong>
                                <span>{seller?.heating_cooling || "-"}</span>
                              </div>

                              <div className="details-item">
                                <strong>Kitchen Equipment</strong>
                                <span>
                                  {seller.kitchen_equipment
                                    ? seller?.kitchen_equipment.join(", ") || "-"
                                    : "-"}
                                </span>
                              </div>

                              <div className="details-item">
                                <strong>Exterior Features</strong>
                                <span>
                                  {seller.exterior_features
                                    ? seller?.exterior_features.join(", ") || "-"
                                    : "-"}
                                </span>
                              </div>

                              <div className="details-item">
                                <strong>Financing Type</strong>
                                <span>
                                  {seller.financing_type
                                    ? seller?.financing_type.join(", ") || "-"
                                    : "-"}
                                </span>
                              </div>

                              <div className="details-item">
                                <strong>Style of House</strong>
                                <span>
                                  {(seller?.style_of_house || []).join(", ") ||
                                    "-"}
                                </span>
                              </div>

                              <div className="details-item">
                                <strong>Patio</strong>
                                <span>{seller?.patio || "-"}</span>
                              </div>

                              <div className="details-item">
                                <strong>Parking Features</strong>
                                <span>
                                  {seller.parking_features
                                    ? seller?.parking_features.join(", ") || "-"
                                    : "-"}
                                </span>
                              </div>

                              {seller.type !== "MLS" && (
                                <div className="details-item">
                                  <strong>Garage Location</strong>
                                  <span>{seller?.garage_location || "-"}</span>
                                </div>
                              )}

                              {seller.type === "MLS" && (
                                <div className="details-item">
                                  <strong>Garage Location</strong>
                                  <span>
                                    {seller?.AttachedGarageYN === "Yes"
                                      ? "Attached"
                                      : "Not Attached"}
                                  </span>
                                </div>
                              )}

                              <div className="details-item">
                                <strong>Selling As-is</strong>
                                <span>{seller?.selling_as_is || "-"}</span>
                              </div>

                              <div className="details-item">
                                <strong>Front Door Faces</strong>
                                <span>{seller?.front_door_faces || "-"}</span>
                              </div>

                              <div className="details-item">
                                <strong>Occupancy Type</strong>
                                <span>{seller?.occupancy_type || "-"}</span>
                              </div>

                              <div className="details-item">
                                <strong>Subdivision</strong>
                                <span>{seller?.subdivision || "-"}</span>
                              </div>

                              <div className="details-item">
                                <strong>Foundation</strong>
                                <span>{seller?.foundation || "-"}</span>
                              </div>

                              <div className="details-item">
                                <strong>Flooring</strong>
                                <span>
                                  {seller?.flooring
                                    ? seller?.flooring.join(", ") || "-"
                                    : "-"}
                                </span>
                              </div>

                              <div className="details-item">
                                <strong>Type Of Fence</strong>
                                <span>
                                  {seller?.type_of_fence
                                    ? seller?.type_of_fence.join(", ") || "-"
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card mb-5 property-card-padding">
                          <h6 className="card-header">Amenities</h6>
                          <div className="card-body">
                            <div className="amenities-card">
                              {this.state.amentities.map((amenity) => (
                                <div className="amenity">
                                  <i
                                    className={
                                      "fa fa-lg amenities-icon " +
                                      (seller?.common_features &&
                                      seller?.common_features?.find(
                                        (name) =>
                                          name
                                            .replace(" ", "")
                                            .toLowerCase() ===
                                          amenity.replace(" ", "").toLowerCase()
                                      )
                                        ? "fa-check-circle-o text-success"
                                        : "fa-times-circle-o text-danger")
                                    }
                                  ></i>
                                  {amenity}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>

                        <div className="card mb-5 property-card-padding">
                          <h6 className="card-header">School</h6>
                          <div className="card-body row">
                            <div className="col-md-6">
                              <dl className="row">
                                <dt className="col-md-7">District</dt>
                                <dd className="col-md-5">
                                  {seller?.school_district || "-"}
                                </dd>
                              </dl>
                              <dl className="row">
                                <dt className="col-md-7">Elementary School</dt>
                                <dd className="col-md-5">
                                  {seller?.elementary_school || "-"}
                                </dd>
                              </dl>
                            </div>
                            <div className="col-md-6">
                              <dl className="row">
                                <dt className="col-md-7">Middle School</dt>
                                <dd className="col-md-5">
                                  {seller?.middle_school || "-"}
                                </dd>
                              </dl>
                              <dl className="row">
                                <dt className="col-md-7">High School</dt>
                                <dd className="col-md-5">
                                  {seller?.high_school || "-"}
                                </dd>
                              </dl>
                            </div>
                          </div>
                        </div>

                        <div className="card mb-5 property-card-padding">
                          <h6 className="card-header">HOA</h6>
                          <div className="card-body row">
                            <dl className="col row">
                              <dt className="col-md-7">HOA</dt>
                              <dd className="col-md-5">{seller.hoa || "-"}</dd>
                              <dt className="col-md-7">Dues</dt>
                              <dd className="col-md-5">
                                {seller.hoa_dues
                                  ? currencyFormatter.format(seller?.hoa_dues) +
                                    " " +
                                    seller?.hoa_billing_frequency
                                  : "-"}
                              </dd>
                            </dl>

                            <dl className="col row">
                              <dt className="col-md-7">Includes</dt>
                              <dd className="col-md-5">
                                {seller?.hoa_includes
                                  ? seller?.hoa_includes.join(", ")
                                  : "-"}
                              </dd>
                              <dt className="col-md-7">Common Areas</dt>
                              <dd className="col-md-5">
                                {seller?.common_features
                                  ? seller?.common_features.join(", ")
                                  : "-"}
                              </dd>
                            </dl>
                          </div>
                        </div>

                        {this.renderBuilderQAs()}

                        {seller?.tags?.length ? (
                          <div className="mb-5">
                            <h5>Tags</h5>
                            {seller?.tags.map((tag) => (
                              <div
                                className="badge badge-light m-1"
                                key={tag.value}
                              >
                                {tag.label}
                              </div>
                            ))}
                          </div>
                        ) : null}

                        {posts.length > 0 ? (
                          <div className="card mb-5 property-card-padding">
                            <h6 className="card-header">Message Boards</h6>
                            <div className="card-body">
                              {posts.map((post, index) => (
                                <PostDetailModal key={index} post={post} />
                              ))}
                            </div>
                          </div>
                        ) : null}

                        <div className="card mb-5 property-card-padding">
                          <div className="card-header">Location</div>
                          <div className="card-body">
                            {this.renderLocationMap()}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="side-col">
                      {seller.instantOffer === "Yes" && (
                        <div
                          style={{
                            marginBottom: 10,
                            border: "1px solid #eee",
                            padding: 10,
                          }}
                        >
                          <InstantOfferModal />
                        </div>
                      )}

                      {realtorInfo}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="visitModal" className="modal" tabIndex="-1" role="dialog">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-md-12 TourRequestedModel">
                        <form
                          onSubmit={this.save.bind(this)}
                          ref={this.mainForm}
                        >
                          <PartitionedForm
                            fields={fields}
                            sections={sections}
                            setValue={this.setValue.bind(this)}
                            values={inputs}
                          />
                          <div className="row pad-20">
                            <div className="col-lg-4 col-md-12 col-sm-12">
                              <button
                                onClick={() =>
                                  (this.mainForm.current.className +=
                                    "submitted")
                                }
                                type="submit"
                                className="btn btn-md button-theme"
                                disabled={this.state.busy}
                              >
                                {busy ? "Sending..." : "Send Request"}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal
              isOpen={this.state.offerTrendsModal}
              size="lg"
              fade={false}
              toggle={this.offerTrendsModalToggle}
              className="offers-modal"
            >
              <ModalHeader
                className="modal-header"
                toggle={this.offerTrendsModalToggle}
              ></ModalHeader>
              <ModalBody className="modal-body">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <h5 className="trend-modal-heading">
                        OfferTrends
                        <img src={trendIcon} />
                        <img
                          onClick={this.shareOfferTrends}
                          className="share-link-icon"
                          src={shareIcon}
                        />
                      </h5>
                      <p className="trend-modal-subheading">
                        OfferTrends provides insight into offer terms made on
                        this home.
                      </p>
                      <div className="row-spacebtwn">
                        <p className="verified-offers-text">
                          <img src={verified_icon} />
                          verified offers{" "}
                          <span>
                            <img
                              style={{ width: "1.4rem" }}
                              src={infoIcon}
                              id="Tooltip"
                              alt="info"
                            />
                          </span>{" "}
                          <small>
                            {verifiedOffers || 0} out of {totalOffers} offers
                            are verified.
                          </small>
                        </p>
                        <Tooltip
                          placement="top"
                          isOpen={this.state.isTooltip}
                          target="Tooltip"
                          toggle={toggle}
                          className="info-tooltip"
                        >
                          OfferTrends shows the percentage of offers received
                          which terms below have been verified
                        </Tooltip>
                        <p className="progress-value">
                          {totalOffers
                            ? ((verifiedOffers / totalOffers) * 100).toFixed(2)
                            : 0}
                          %
                        </p>
                      </div>
                      <Progress
                        value={
                          totalOffers ? (verifiedOffers / totalOffers) * 100 : 0
                        }
                      />
                      <p className="offer-trend-status">
                        <span>{verifiedOffers && verifiedOffers}</span> out of{" "}
                        <span>{totalOffers && totalOffers}</span> offers are
                        verified
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 overflow m-vertical-32">
                      <Table striped>
                        <tbody>
                          <tr>
                            <td className="normal-td light-weight">
                              Purchase Price
                            </td>
                            <td className="light-bold-td">
                              <img src={up_arrow} />{" "}
                              {totalPurchasePrice
                                ? numToFixed(
                                    (1 - listingPrice / totalPurchasePrice) *
                                      100
                                  )
                                : 0}
                              % Average
                            </td>
                          </tr>
                          <tr>
                            <td className="normal-td light-weight">
                              Down payment
                            </td>
                            <td className="light-bold-td">
                              <NumberFormat
                                value={`${Number(
                                  this.state.totalDownPayment
                                ).toFixed(0)}`}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                                renderText={(value, props) => <>{value}</>}
                              />{" "}
                              Average
                            </td>
                          </tr>
                          <tr>
                            <td className="normal-td light-weight">
                              finance type
                            </td>
                            <td className="light-bold-td">{financingType}</td>
                          </tr>
                          <tr>
                            <td className="normal-td light-weight">
                              option fee
                            </td>
                            <td className="light-bold-td">
                              {" "}
                              <NumberFormat
                                value={`${Number(
                                  this.state.totalOptionFee
                                ).toFixed(0)}`}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                                renderText={(value, props) => <>{value}</>}
                              />{" "}
                              Average
                            </td>
                          </tr>
                          <tr>
                            <td className="normal-td light-weight">
                              option days
                            </td>
                            <td className="light-bold-td">
                              {totalOptionDays} days
                            </td>
                          </tr>
                          <tr>
                            <td className="normal-td light-weight">
                              title policy expense
                            </td>
                            <td className="light-bold-td">
                              {totalExpenseType}
                            </td>
                          </tr>
                          <tr>
                            <td className="normal-td light-weight">
                              possession
                            </td>
                            <td className="light-bold-td">{totalPosseions}</td>
                          </tr>
                          <tr>
                            <td className="normal-td light-weight">
                              sale of other property
                            </td>
                            <td className="light-bold-td">
                              {totalSaleOfProperty}
                            </td>
                          </tr>
                          <tr>
                            <td className="normal-td light-weight">
                              Right to terminate due to lender’s apprasial
                            </td>
                            <td className="light-bold-td">
                              {totalRightToTerminate}
                            </td>
                          </tr>
                          <tr>
                            <td className="normal-td light-weight">
                              Earnest money
                            </td>
                            <td className="light-bold-td">
                              <NumberFormat
                                value={`${numToFixed(totalEarnestMoney)}`}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                                renderText={(value, props) => <>{value}</>}
                              />{" "}
                              Average
                            </td>
                          </tr>

                          <tr>
                            <td className="normal-td light-weight">
                              closing date
                            </td>
                            <td className="light-bold-td">
                              {Math.ceil(closeingDate)} Days Average
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </ModalBody>
            </Modal>
          </div>

          <ShareModal
            hideModal={() =>
              this.setState({
                shareModal: !this.state.shareModal,
              })
            }
            showModel={this.state.shareModal}
            sellerId={this.props.match.params.id}
          />

          {/* View Offers Modal */}
          <Modal
            isOpen={this.state.showOffersModal}
            size="lg"
            fade={false}
            toggle={() => {
              this.setState({
                showOffersModal: !this.state.showOffersModal,
              });
            }}
            className="offers-modal"
          >
            <ModalHeader
              className="modal-header"
              toggle={() => {
                this.setState({
                  showOffersModal: !this.state.showOffersModal,
                });
              }}
            ></ModalHeader>
            <ModalBody className="modal-body">
              <Container>
                <Row>
                  <Col>
                    <div className="row-space-between">
                      <h5 className="modal-title">All client offers</h5>
                      {/* <button className="modal-chart-btn" onClick={chartToggle}>
                    <img src={Graph} className="pr-10" />Chart filters
                  </button> */}
                    </div>
                  </Col>
                </Row>
                <Row className="mb-30">
                  <Col>
                    <div className="table-responsive">
                      <table className="offers-table table-customized">
                        <tr className="modal-tr-header">
                          <th>Address</th>
                          <th></th>
                          <th></th>
                          <th>Status</th>
                          <th>Date</th>
                          <th>Purchase Price</th>
                          <th>Buyer Agent</th>
                          <th></th>
                        </tr>
                        <tbody>
                          {allClientOffers.length === 0 ? (
                            <tr>
                              <td colSpan="8" style={{ textAlign: "center", padding: "20px" }}>
                                There are no offers currently listed right now
                              </td>
                            </tr>
                          ) : (
                            allClientOffers
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>

          {/* Thank me Modal */}
          <Modal
            isOpen={this.state.thankMeModal}
            // isOpen={true}
            size="sm"
            style={{}}
            fade={false}
            toggle={() => {
              this.setState({
                thankMeModal: !this.state.thankMeModal,
                selectedAmount: 0,
                paymentModal: false,
              });
            }}
            className="thankme-modal"
          >
            <ModalHeader
              className="thankme-modal-header"
              toggle={() => {
                this.setState({
                  thankMeModal: !this.state.thankMeModal,
                  selectedAmount: 0,
                  paymentModal: false,
                });
              }}
            >
              <p className="">
                {this.state.paymentModal && (
                  <img
                    src={arrowLeft}
                    height={"24px"}
                    className="d-inline"
                    onClick={() => {
                      this.setState({
                        paymentModal: false,
                      });
                    }}
                    style={{
                      height: "24px !important",
                      marginRight: "5px",
                      marginBottom: "3px",
                      cursor: "pointer",
                    }}
                  />
                )}
                $Thank Me
              </p>
            </ModalHeader>

            <ModalBody className="modal-body pt-2 px-3 px-lg-0 pb-0 pb-md-4">
              {!this.state.paymentModal ? (
                <>
                  <div className="row mx-auto mt-3">
                    <div className="thankme-price-btn col-6 m-0 p-1">
                      <button
                        className={`btn ${
                          this.state.selectedAmount == this.state.thankMeAmount
                            ? "thank-me-buttons-active"
                            : "thank-me-buttons"
                        } w-100`}
                        onClick={() => {
                          this.setState({
                            otherAmount: false,
                            selectedAmount: this.state.thankMeAmount,
                            errorMessage: {},
                          });
                        }}
                      >
                        $ {this.state.thankMeAmount}
                      </button>
                    </div>

                    {/* <div className="thankme-price-btn p-1 col-3">
                  <button
                    className={`btn ${
                      selectedAmount == 40
                        ? "thank-me-buttons-active"
                        : "thank-me-buttons"
                    } w-100`}
                    onClick={() => {
                      setOtherAmount(false);
                      setSelectedAmount(40);
                    }}
                  >
                    $40
                  </button>
                </div>
                <div className="thankme-price-btn p-1 col-3">
                  <button
                    className={`btn ${
                      selectedAmount == 69
                        ? "thank-me-buttons-active"
                        : "thank-me-buttons"
                    } w-100`}
                    onClick={() => {
                      setOtherAmount(false);
                      setSelectedAmount(69);
                    }}
                  >
                    $69
                  </button>
                  </div>*/}

                    <div className="thankme-price-btn col-6 m-0 p-1">
                      <button
                        className={`btn ${
                          this.state.otherAmount
                            ? "thank-me-buttons-active"
                            : "thank-me-buttons"
                        } w-100`}
                        onClick={() => {
                          this.setState({
                            otherAmount: true,
                          });
                        }}
                      >
                        Other
                      </button>
                    </div>
                  </div>
                  <div className="row mx-auto mt-3">
                    <p
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "#000000",
                      }}
                      className="m-0 p-0 mb-3"
                    >
                      What You Will Get:
                    </p>
                    <ul
                      style={{
                        listStyle: "disc",
                        fontSize: "12px",
                        fontWeight: "600",
                        marginLeft: "12px",
                      }}
                    >
                      <li>OfferTrends View of Combined Offers</li>
                      <li>All Offer Details and Terms on Listing</li>
                      <li>Buyer's Agent Contact Information</li>
                      <li>Follow My Listing SMS Notification</li>
                    </ul>
                  </div>
                  {this.state.otherAmount && (
                    <div className="row mt-3 mx-auto">
                      <p
                        style={{
                          fontWeight: "700",
                          fontSize: "16px",
                          color: "#000000",
                        }}
                      >
                        Custom amount
                      </p>
                      <input
                        type="text"
                        className="w-100 thankme-textarea p-2"
                        value={this.state.selectedAmount}
                        onChange={(e) => {
                          this.setState({
                            errorMessage: {},
                            selectedAmount: e.target.value,
                          });
                        }}
                      />
                      {this.state.errorMessage.type === "minAmount" && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "12px",
                            fontWeight: "600",
                          }}
                          className="mb-0"
                        >
                          {this.state.errorMessage.message}
                        </p>
                      )}
                    </div>
                  )}
                  <div className="row mt-3 mx-auto">
                    <p
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "#000000",
                      }}
                    >
                      Leave a note
                    </p>
                    <textarea rows={3} className="w-100 thankme-textarea p-2" />
                  </div>
                </>
              ) : (
                <>
                  <div className="row mt-3 mx-auto">
                    <div className="col-12 p-0 m-0">
                      <p
                        className="mb-1"
                        style={{
                          fontWeight: "700",
                          fontSize: "12px",
                          color: "#000000",
                        }}
                      >
                        Email
                      </p>
                      <input
                        type="text"
                        className="w-100 thankme-textarea p-1"
                        value={this.state.cardEmail}
                        onChange={(e) =>
                          this.setState({ cardEmail: e.target.value })
                        }
                      />
                    </div>
                    <div className="col-12 p-0 m-0 mt-3">
                      <p
                        className="mb-1"
                        style={{
                          fontWeight: "700",
                          fontSize: "12px",
                          color: "#000000",
                        }}
                      >
                        Card Information
                      </p>
                      <input
                        type="text"
                        className="w-100 thankme-textarea p-1 pl-5"
                        value={this.formatCardNumber(this.state.cardNumber)}
                        style={{
                          backgroundImage: `url(${mastercard})`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "left 0.5rem center",
                          backgroundSize: "contain",
                          paddingLeft: "55px !important",
                        }}
                        onChange={(e) => {
                          if (e.target.value.length <= 19)
                            this.setState({
                              cardNumber: e.target.value,
                            });
                        }}
                      />
                      {this.state.errorMessage.type == "cardNumber" && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "12px",
                            fontWeight: "600",
                          }}
                          className="mb-0"
                        >
                          {this.state.errorMessage.message}
                        </p>
                      )}
                      {/* <img src={mastercard} className="w-50 float-right" /> */}
                    </div>

                    <div className="col-6 p-0 m-0 mt-3 pr-1">
                      <p
                        className="mb-1"
                        style={{
                          fontWeight: "700",
                          fontSize: "12px",
                          color: "#000000",
                        }}
                      >
                        Expiry (MM/YY)
                      </p>
                      <input
                        type="text"
                        className="w-100 thankme-textarea p-1"
                        value={this.formatExpDate(this.state.expDate)}
                        onChange={(e) => {
                          if (e.target.value.length <= 5)
                            this.setState({
                              expDate: e.target.value,
                            });
                        }}
                        placeholder="MM/YY"
                      />
                      {this.state.errorMessage.type == "expDate" && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "12px",
                            fontWeight: "600",
                          }}
                          className="mb-0"
                        >
                          {this.state.errorMessage.message}
                        </p>
                      )}
                    </div>
                    <div className="col-6 p-0 m-0 mt-3 pl-1">
                      <p
                        className="mb-1"
                        style={{
                          fontWeight: "700",
                          fontSize: "12px",
                          color: "#000000",
                        }}
                      >
                        CVC
                      </p>
                      <input
                        type="text"
                        className="w-100 thankme-textarea p-1"
                        value={this.state.cvv}
                        onChange={(e) => {
                          if (e.target.value.length <= 3) {
                            this.setState({
                              cvv: e.target.value,
                            });
                          }
                        }}
                      />
                      {this.state.errorMessage.type == "cvv" && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "12px",
                            fontWeight: "600",
                          }}
                          className="mb-0"
                        >
                          {this.state.errorMessage.message}
                        </p>
                      )}
                    </div>
                    <div className="col-12 p-0 m-0 mt-3">
                      <p
                        className="mb-1"
                        style={{
                          fontWeight: "700",
                          fontSize: "12px",
                          color: "#000000",
                        }}
                      >
                        Name on card
                      </p>
                      <input
                        type="text"
                        className="w-100 thankme-textarea p-1"
                        value={this.state.cardName}
                        onChange={(e) =>
                          this.setState({ cardName: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="row mt-3">
                <div className="col-md-12 p-0">
                  <Button
                    className="btn thank-me-continue w-100"
                    onClick={() => {
                      {
                        this.state.paymentModal
                          ? this.handlePayment()
                          : this.state.selectedAmount <
                            this.state.minThankMeAmount
                          ? this.setState({
                              errorMessage: {
                                type: "minAmount",
                                message: `Amount must be at least $${this.state.minThankMeAmount}`,
                              },
                            })
                          : this.props.user?.uid === this.state.tippedUser?.id
                          ? toast.error("You can't tip yourself")
                          : this.state.isLoggedin
                          ? this.setState({
                              paymentModal: true,
                            })
                          : this.props.history.push("/login");
                      }
                    }}
                  >
                    {this.state.paymentModal
                      ? this.state.thankMeLoading
                        ? "Sending..."
                        : `Support ${
                            this.state.selectedAmount != 0
                              ? "$" + this.state.selectedAmount
                              : ""
                          }`
                      : "Continue"}{" "}
                    {/* {selectedAmount != 0 ? "$" + selectedAmount : ""} */}
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>

          {/* after thank me modal */}
          <Modal
            isOpen={this.state.afterThankMeModal}
            // centered={true}
            size="sm"
            fade={false}
            toggle={() => {
              this.setState({
                afterThankMeModal: !this.state.afterThankMeModal,
              });
            }}
            className="thankme-modal"
          >
            <ModalBody className="modal-body thank-you-modal">
              <div className="row mx-auto">
                <div className="col-md-12 text-center">
                  <img src={thankme} />
                  <p
                    className="p-0 m-0 mt-4"
                    style={{
                      fontWeight: "600",
                      fontSize: "18px",
                      color: "#000000",
                    }}
                  >
                    Thank you for your support!
                  </p>
                  <div
                    className="p-1 mt-4"
                    style={{
                      width: "100% !important",
                    }}
                  >
                    <button
                      className={`btn thank-you-btn w-100 mb-0`}
                      style={{ height: "40px !important" }}
                      onClick={() => {
                        this.setState({
                          afterThankMeModal: !this.state.afterThankMeModal,
                        });
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </>
      );
    }

    if (this.state.loading) {
      return (
        <Loader
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          type="Rings"
          color="#ff214f"
          height={200}
          width={200}
        />
      );
    }

    return <PageNotFound />;
  }
}

export default GoogleApiWrapper({
  apiKey,
})(PropertyPage);
