import React, { Component } from "react";
import { connect } from "react-redux";
import UserVerificationAlert from "../components/Alerts/UserVerificationAlert";
import { Link } from "react-router-dom";
import AdminNav from "components/AdminNav";
import { Badge } from "reactstrap";
import TimeAgo from "react-timeago";
import firebase from "firebase/app";
import "firebase/auth";
import SubscriptionExpiredPage from "./../components/Payment/SubscriptionExpiredPage";
import Subscriptions from "../pages/Subscriptions";
import Profile from "../pages/Profile";
import Dashboard from "../pages/Dashboard";
import moment from "moment";
import "../assets/css/offers.css";
import { forEach } from "jszip";

class Account extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: "",
      inputs: {
        NXIHZ: "",
      },
      formsSubmitted: {},
      notifications: [],
      unreadNotifications: 0,
    };
  }

  logout() {
    const redirect = (error) => {
      if (error) {
        console.error(error);
      }
      this.props.history.push("/");
      this.props.functions.logout();
    };
    firebase
      .auth()
      .signOut()
      .then(redirect.bind(this))
      .catch(redirect.bind(this));
  }

  renderUndreamMessagesCount = () => {
    const { unreadMessages } = this.props;
    if (unreadMessages > 0) {
      return (
        <Badge
          pill
          color="danger"
          style={{
            position: "inherit",
            backgroundColor: "#dc3545",
            height: "auto",
            width: "auto",
          }}
        >
          {unreadMessages}
        </Badge>
      );
    }
  };

  hasAccess = () => {
    const { packages, expiry } = this.props;
    if (packages === undefined || expiry === undefined) {
      return true;
    }

    const filteredPackages = (packages || []).filter((p) => p.isActive);

    if (filteredPackages.length === 0) {
      return true;
    }

    const now = moment.now();
    var dateString = moment.unix(expiry);

    let days = dateString.diff(now, "days");

    if (days <= 0) {
      days = 0;
    }

    return days !== 0;
  };

  renderComponent = () => {
    const { children } = this.props;
    if (
      this.hasAccess() ||
      [Subscriptions, Profile, Dashboard].includes(children.type)
    ) {
      return children;
    }
    return <SubscriptionExpiredPage />;
  };

  componentDidMount = async () => {
    const { user } = this.props;
    const db = firebase.firestore();
    const { docs } = await db
      .collection("notifications")
      .where("user_id", "==", user.uid)
      .orderBy("createdAt", "desc")
      .get();
    this.setState({
      notifications: docs,
    });

    docs.forEach((noti) => {
      noti = noti.data();
      if (!noti.isRead)
        this.setState({
          unreadNotifications: this.state.unreadNotifications + 1,
        });
    });

    const storage = firebase.storage();
    const storageRef = storage.ref();
    const realtorRef = db?.collection("realtors").doc(user.uid);
    const realtorLogoRef = storageRef.child(
      `images/logos/${user.realtor?.id}.jpg`
    );

    realtorRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          realtorLogoRef.getDownloadURL().then((url) => {
            this.setState({
              companyLogo: url,
            });
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  markNotificationsRead = async (id, link, e) => {
    e.preventDefault();

    const db = firebase.firestore();
    await db.collection("notifications").doc(id).update({
      isRead: 1,
    });
    this.props.history.push(link);
  };

  markAllNotificationsRead = () => {
    const { notifications } = this.state;

    notifications.forEach(async (notification) => {
      /* … */
      const noti = notification.data();
      if (noti.isRead !== 1) {
        const db = firebase.firestore();
        await db.collection("notifications").doc(notification.id).update({
          isRead: 1,
        });
      }

      const { user } = this.props;
      const db = firebase.firestore();
      const { docs } = await db
        .collection("notifications")
        .where("user_id", "==", user.uid)
        .orderBy("createdAt", "desc")
        .get();
      this.setState({
        notifications: docs,
      });
      docs.forEach((noti) => {
        noti = noti.data();
        if (!noti.isRead)
          this.setState({
            unreadNotifications: 0,
          });
      });
    });
  };

  render() {
    const { notifications, unreadNotifications } = this.state;
    const { user, hideSideBar } = this.props;
    const { role, profilePhoto, emailVerified, profileComplete } = user || {};

    let roleDisplayName = null;

    if (role === "realtor") {
      roleDisplayName = "Realtor";
    } else if (role === "user") {
      roleDisplayName = "User";
    } else if (role === "lender") {
      roleDisplayName = "Lender";
    } else if (role === "builder") {
      roleDisplayName = "Builder";
    }

    let NotificationBell = "/images/icons/bell.svg";
    let profilePhotoUrl = "/images/face-placeholder.png";

    if (profilePhoto) {
      if (profilePhoto[64]) {
        profilePhotoUrl = profilePhoto[64];
      } else {
        profilePhotoUrl = profilePhoto.placeholder;
      }
    }

    if (!user) {
      return null;
    }

    return (
      <React.Fragment>
        {!emailVerified && role !== "titleCompany" && <UserVerificationAlert />}
        <header className="main-header header-2">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-lg navbar-light">
              <Link className="navbar-brand logo pad-0" to="/">
                <img
                  src={
                    this.state.companyLogo
                      ? this.state.companyLogo
                      : "/img/logos/black-logo.png"
                  }
                  alt="logo"
                />
              </Link>
              <button
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                className="navbar-toggler margin-0"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div
                id="navbarSupportedContent"
                className="collapse navbar-collapse"
              >
                {role === "titleCompany" ? (
                  <ul className="navbar-nav ml-auto d-lg-none d-xl-none">
                    <li className="nav-item dropdown">
                      <Link className="nav-link" to="/account">
                        My Profile
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <Link className="nav-link" to="/offers">
                        Offers
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <a
                        href="#/"
                        className="nav-link"
                        onClick={this.logout.bind(this)}
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                ) : (
                  <ul className="navbar-nav ml-auto d-lg-none d-xl-none">
                    <li className="nav-item dropdown">
                      <Link className="nav-link" to="/dashboard">
                        Dashboard
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <Link className="nav-link" to="/analytics">
                        Analytics
                      </Link>
                    </li>
                    <Link className="nav-link" to="/sellers">
                      {role === "builder" ? "Builder Homes" : "My Listings"}
                    </Link>
                    <li className="nav-item dropdown">
                      <Link className="nav-link" to="/homes">
                        My Homes for Sale
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <Link className="nav-link" to="/offers">
                        My Offers
                      </Link>
                    </li>
                    {/* <li className="nav-item dropdown">
                      <Link className="nav-link" to="/transactions">
                        My Transactions
                      </Link>
                    </li> */}

                    <li className="nav-item dropdown">
                      <Link className="nav-link" to="/users">
                        Users
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <Link className="nav-link" to="/account">
                        My account
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <Link className="nav-link" to="/my-subscriptions">
                        My Subscription
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <Link className="nav-link" to="/my-analytics">
                        Sales Analytics
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <a
                        href="#/"
                        className="nav-link"
                        onClick={this.logout.bind(this)}
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                )}
                <div className="navbar-buttons ml-auto d-none d-xl-block d-lg-block">
                  <ul>
                    {role !== "titleCompany" && (
                      <li>
                        <div className="dropdown btns">
                          <a
                            href="#/"
                            data-toggle="dropdown"
                            className="dropdown-toggle"
                          >
                            <img
                              className="notification-bell"
                              src={NotificationBell}
                              alt="avatar"
                            />
                            {unreadNotifications ? (
                              <p className="notification-badge">
                                {unreadNotifications}
                              </p>
                            ) : null}
                          </a>
                          <div className="dropdown-menu notification-width">
                            <button
                              type="button"
                              style={{ width: "100%", color: "red" }}
                              class="btn btn-light"
                              onClick={this.markAllNotificationsRead}
                            >
                              Mark All as Read
                            </button>
                            {notifications.map((n, i) => {
                              const noti = n.data();

                              let link = "/";
                              if (noti.type === "offer") {
                                link = `/offers/${noti.property_id}`;
                                return (
                                  <div key={i} className="notification-list">
                                    <ul>
                                      <li>
                                        <a
                                          href="#"
                                          key={i}
                                          onClick={(e) =>
                                            this.markNotificationsRead(
                                              n.id,
                                              link,
                                              e
                                            )
                                          }
                                          className="dropdown-item"
                                        >
                                          {!noti.isRead ? (
                                            <div className="dashboard-notification-text-container">
                                              {noti.isRead ? (
                                                noti.text
                                              ) : (
                                                <strong>{noti.text}</strong>
                                              )}
                                              <div className="unread-dot"></div>
                                            </div>
                                          ) : (
                                            noti.text
                                          )}
                                        </a>
                                        <p
                                          style={{
                                            color: noti.isRead
                                              ? "#9a9a9a"
                                              : "#eb3e54",
                                          }}
                                        >
                                          {moment(
                                            noti.createdAt * 1000
                                          ).fromNow()}
                                        </p>
                                      </li>
                                    </ul>
                                  </div>
                                );
                              }
                            })}
                            {!notifications.length && (
                              <div className="notification-list empty-notification">
                                <ul>
                                  <li>
                                    <p>You have no new notifications</p>
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>
                        </div>
                      </li>
                    )}

                    <li>
                      <div className="dropdown btns">
                        <a
                          href="#/"
                          data-toggle="dropdown"
                          className="dropdown-toggle"
                        >
                          <img src={profilePhotoUrl} alt="avatar" />
                          <span style={{ position: "relative" }}>
                            {this.props.user.email}
                            <span
                              style={{
                                position: "absolute",
                                left: 0,
                                top: 17,
                                color: "#999",
                                fontSize: 11,
                              }}
                            >
                              {roleDisplayName}
                            </span>
                          </span>
                        </a>
                        <div className="dropdown-menu">
                          {profileComplete &&
                            (role === "titleCompany" ? (
                              <>
                                <Link className="dropdown-item" to="/offers">
                                  Offers
                                </Link>
                                <Link className="dropdown-item" to="/account">
                                  My account
                                </Link>
                              </>
                            ) : (
                              <>
                                <Link className="dropdown-item" to="/dashboard">
                                  Dashboard
                                </Link>
                                <Link className="dropdown-item" to="/sellers">
                                  {role === "builder"
                                    ? "Builder Homes"
                                    : "My Listings"}
                                </Link>
                                <Link className="dropdown-item" to="/offers">
                                  Offers
                                </Link>
                                <Link className="dropdown-item" to="/account">
                                  My account
                                </Link>
                              </>
                            ))}
                          <a
                            href="#/"
                            className="dropdown-item"
                            onClick={this.logout.bind(this)}
                          >
                            Logout
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </header>
        <div className="dashboard">
          <div
            className="container-fluid"
            style={{
              padding: 0,
            }}
          >
            {hideSideBar ? (
              <div className="row">
                <div>{this.renderComponent()}</div>
              </div>
            ) : (
              <div className="row">
                <div className="col-lg-2 col-md-12 col-sm-12 col-pad">
                  <AdminNav {...this.props} logout={this.logout.bind(this)} />
                </div>
                <div className="col-lg-10 col-md-12 col-sm-12 col-pad">
                  {this.renderComponent()}
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ MessengerReducer, PaymentReducer }) => {
  return {
    ...MessengerReducer,
    ...PaymentReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
