import React, { Component } from "react";
import { Card, CardBody, Tooltip, Button } from "reactstrap";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import moment from "moment";
import Replay from "../../assets/icons/replay_arrow.svg";
import ReplayWhite from "../../assets/icons/replay_arrow_white.svg";
import Tick from "../../assets/icons/tick.svg";
import Cross from "../../assets/icons/cross.svg";
import Person from "../../assets/icons/person.svg";
import Calendar from "../../assets/icons/calendar.svg";
import Flag from "../../assets/icons/flag-21.svg";
import VerifiedListing from "../../assets/icons/verified-icon.svg";
import AgentInformationModal from "../../components/Analytics/AgentInformationModal";

class OfferListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: {},
      scoringDetailsVisible: {},
    };
  }

  toggleTooltip = (offerId) => {
    this.setState((prevState) => ({
      tooltipOpen: {
        ...prevState.tooltipOpen,
        [offerId]: !prevState.tooltipOpen[offerId],
      },
    }));
  };
  
  renderStatus = (status, isBuyer, viewed) => {
    let statusClass = "";
    let prependIcon = "";
    if (status === "In Review") {
      statusClass = "in-review";
      prependIcon = Replay;
    } else if (status === "Accepted") {
      statusClass = "accepted";
      prependIcon = Tick;
    } else if (status === "Not Accepted") {
      statusClass = "declined";
      prependIcon = Cross;
    } else if (status === "Deleted") {
      statusClass = "declined";
      prependIcon = Cross;
    } else if (status === "Executed") {
      statusClass = "executed";
      prependIcon = Tick;
    } else if (status === "Withdraw" || status === "Terminated") {
      statusClass = "withdrawn";
      prependIcon = Tick;
    } else if (status === "Seller Review") {
      statusClass = "seller-review";
      prependIcon = ReplayWhite;
    } else if (status === "Countered") {
      statusClass = "accepted";
      prependIcon = ReplayWhite;
    }

    if (status == "Executed") {
      return (
        <>
          <p className={`accepted align-items-center`}>
            <button
              style={{
                paddingTop: "4px",
                paddingBottom: "22px",
              }}
            >
              <img src={prependIcon} alt="prepend icon" />
              Accepted
            </button>

            <button
              className="ml-2"
              style={{
                paddingTop: "4px",
                paddingBottom: "22px",
              }}
            >
              <img src={prependIcon} alt="prepend icon" />
              Executed
            </button>
            {this.props.offer?.ammendOffers?.length >= 1 && (
              <button
                className="ml-2"
                style={{
                  paddingTop: "4px",
                  paddingBottom: "22px",
                  backgroundColor: "#ff214f",
                  color: "white",
                }}
              >
                Amended
              </button>
            )}

            <button
              className="ml-2"
              style={{
                backgroundColor: "#9C9D9E",
                color: "white",
                paddingTop: "4px",
                paddingBottom: "22px",
              }}
            >
              {isBuyer ? "Received" : "Sent"}
            </button>
            {/* {viewed && <span className="is-viewed">Viewed</span>} */}
          </p>
        </>
      );
    }

    return (
      <p className={`${statusClass} align-items-center`}>
        <button
          style={{
            paddingTop: "4px",
            paddingBottom: "22px",
          }}
        >
          <img src={prependIcon} alt="prepend icon" />
          {status === "Withdraw" ? "Withdrawn" : status}
        </button>
        <button
          className="ml-2"
          style={{
            backgroundColor: "#9C9D9E",
            color: "white",
            paddingTop: "4px",
            paddingBottom: "22px",
          }}
        >
          {isBuyer ? "Recieved" : "Sent"}
        </button>
        {viewed && <span className="is-viewed">Viewed</span>}
      </p>
    );
  };

  toggleScoringDetails = (offerId) => {
    this.setState((prevState) => ({
      scoringDetailsVisible: {
        ...prevState.scoringDetailsVisible,
        [offerId]: !prevState.scoringDetailsVisible[offerId],
      },
    }));
  };

  calculateScore = (offer, listingPrice) => {
    const purchasePrice = parseFloat(offer.purchasePrice || 0);
    const sellerConcessions = parseFloat(offer.sellerConcessions || 0); // New field
    const serviceContract = parseFloat(offer.serviceContract || 0); // New field
    const netPurchasePrice = purchasePrice - sellerConcessions - serviceContract; // Net Purchase Price

    const earnestMoney = parseFloat(offer.earnestMoney || 0);
    const closingDate = new Date(offer.closingDate);
    const today = new Date();
    const optionDays = parseInt(offer.optionDays || 0);
    const titlePolicyExpense = offer.titlePolicyExpense;

    // 1. Purchase Price Score (50 Base + Bonus)
    // const basePriceScore = Math.min((purchasePrice / listingPrice) * 50, 50);
    // const bonusPriceScore = Math.max((purchasePrice - listingPrice) / 10000, 0) * 2;
    const basePriceScore = Math.min((netPurchasePrice / listingPrice) * 54.5, 54.5); // Updated to 54 base
    const bonusPriceScore = Math.max((netPurchasePrice - listingPrice) / 5000, 0) * 2;
    const purchasePriceScore = basePriceScore + bonusPriceScore;

    // 2. Closing Date Score
    const closingDays = Math.ceil((closingDate - today) / (1000 * 60 * 60 * 24));
    let closingDateScore = 0;
    if (closingDays <= 15) closingDateScore = 20;
    else if (closingDays <= 30) closingDateScore = 15;
    else if (closingDays <= 45) closingDateScore = 10;
    else closingDateScore = 5;

    // 3. Earnest Money Score
    const earnestMoneyPercentage = (earnestMoney / listingPrice) * 100;
    let earnestMoneyScore = 0;
    if (earnestMoneyPercentage >= 5) earnestMoneyScore = 15;
    else if (earnestMoneyPercentage >= 3.5) earnestMoneyScore = 12;
    else if (earnestMoneyPercentage >= 1.5) earnestMoneyScore = 10;
    else earnestMoneyScore = 5;

    // 4. Option Period Score
    let optionPeriodScore = 0;
    if (optionDays === 0) optionPeriodScore = 10;
    else if (optionDays <= 3) optionPeriodScore = 8;
    else if (optionDays <= 5) optionPeriodScore = 5;

    // 5. Title Policy Responsibility Score
    // const titlePolicyScore = titlePolicyExpense === "Buyer" ? 5 : 0;
    const titlePolicyScore = titlePolicyExpense === "Buyer" ? 0.5 : 0;

    // Total Score
    return (
      purchasePriceScore +
      closingDateScore +
      earnestMoneyScore +
      optionPeriodScore +
      titlePolicyScore
    );
  };
  
  renderDynamicTable = (offer, listingPrice) => {
    const purchasePrice = parseFloat(offer.purchasePrice || 0);
    const sellerConcessions = parseFloat(offer.sellerConcessions || 0); // New field
    const serviceContract = parseFloat(offer.serviceContract || 0); // New field
    const netPurchasePrice = purchasePrice - sellerConcessions - serviceContract; // Net Purchase Price

    const earnestMoney = parseFloat(offer.earnestMoney || 0);
    const closingDate = new Date(offer.closingDate);
    const today = new Date();
    const optionDays = parseInt(offer.optionDays || 0);
    const titlePolicyExpense = offer.titlePolicyExpense;

    // Scoring Breakdown
    // const basePriceScore = Math.min((purchasePrice / listingPrice) * 50, 50);
    // const bonusPriceScore = Math.max((purchasePrice - listingPrice) / 10000, 0) * 2;
    const basePriceScore = Math.min((netPurchasePrice / listingPrice) * 54.5, 54.5); // Updated to 54 base
    const bonusPriceScore = Math.max((netPurchasePrice - listingPrice) / 5000, 0) * 2;
    const purchasePriceScore = basePriceScore + bonusPriceScore;
    const closingDays = Math.ceil((closingDate - today) / (1000 * 60 * 60 * 24));
    let closingDateScore = 0;
    if (closingDays <= 15) closingDateScore = 20;
    else if (closingDays <= 30) closingDateScore = 15;
    else if (closingDays <= 45) closingDateScore = 10;
    else closingDateScore = 5;

    const earnestMoneyPercentage = (earnestMoney / listingPrice) * 100;
    let earnestMoneyScore = 0;
    if (earnestMoneyPercentage >= 5) earnestMoneyScore = 15;
    else if (earnestMoneyPercentage >= 3.5) earnestMoneyScore = 12;
    else if (earnestMoneyPercentage >= 1.5) earnestMoneyScore = 10;
    else earnestMoneyScore = 5;

    let optionPeriodScore = 0;
    if (optionDays === 0) optionPeriodScore = 10;
    else if (optionDays <= 3) optionPeriodScore = 8;
    else if (optionDays <= 5) optionPeriodScore = 5;

    // const titlePolicyScore = titlePolicyExpense === "Buyer" ? 5 : 0;
    const titlePolicyScore = titlePolicyExpense === "Buyer" ? 0.5 : 0;

    const totalScore =
      purchasePriceScore +
      closingDateScore +
      earnestMoneyScore +
      optionPeriodScore +
      titlePolicyScore;

    const scoreDetails = [
      { category: "Purchase Price", score: purchasePriceScore.toFixed(2) },
      { category: "Closing Date", score: closingDateScore },
      { category: "Earnest Money", score: earnestMoneyScore },
      { category: "Option Period", score: optionPeriodScore },
      { category: "Title Policy Responsibility", score: titlePolicyScore },
    ];

    return (
      <>
        <table className="scoring-table-container">
          <thead>
            <tr className="scoring-table-header">
              <th className="scoring-table-category">Category</th>
              <th className="scoring-table-score">Score</th>
            </tr>
          </thead>
          <tbody>
            {scoreDetails.map((detail, index) => (
              <tr
                key={index}
                className={`scoring-table-row ${
                  index % 2 === 0 ? "scoring-table-row-even" : "scoring-table-row-odd"
                }`}
              >
                <td className="scoring-table-category">{detail.category}</td>
                <td className="scoring-table-score">{detail.score}</td>
              </tr>
            ))}
            <tr className="scoring-table-total-row">
              <td className="scoring-table-category">Total Score</td>
              <td className="scoring-table-score">{totalScore.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
        <div className="scoring-percentage-container">
          <p className="ai-score-percentage">
            Offer Weighted Percentage is <strong>{`${offer.aiScorePercentage.toFixed(2)}%`}</strong>
          </p>
        </div>
      </>
    );
  };
  

  render() {
    const { scoringDetailsVisible } = this.state;
    const {
      offer,
      user,
      showAiScore,
      showWeightedScorePercentage,
      curItem,
      totalItems,
      bestMatch,
      sellers,
      allOffers,
      isGlobal
    } = this.props;

    const {
      status,
      id,
      viewed,
      address,
      brokerageName,
      createdAt,
      purchasePrice,
      viewedAt,
      viewedBy,
      verified,
      offer_audio,
      offer_video
    } = offer;
    const isBuyer = offer?.sellerEmail === user?.email;
    const listingPrice = parseFloat(offer.actual_price || 0);
    const OfferValueInPercentage = (purchasePrice * 100) / listingPrice - 100;

    return (
      <Link
        to={
          isGlobal
            ? `/otherAgent/offer/${id}`
            : { 
                pathname: `/offers/${id}`,
                search: `?curItem=${curItem}&totalItems=${totalItems}`,
                state: {
                  offer,
                  curItem,
                  totalItems,
                  allOffers,
                },
              }
        }
        onClick={(e) => {
          if (e.target.closest(".ai-score-card-container")) {
            e.preventDefault();
          }
        }}
      >
        <Card
          className={
            bestMatch && bestMatch[0] && bestMatch[0].id === id
              ? "best-match-offers-card"
              : "custom-card"
          }
        >
          <CardBody>
            {bestMatch && bestMatch[0] && bestMatch[0].id === id && (
              <p className="best-match-tag ml--1">
                <i className="fa fa-heart"></i>BEST MATCH
              </p>
            )}
            <div
              className={
                bestMatch && bestMatch[0] && bestMatch[0].id === id
                  ? "row offers-thumbnail-view-padding"
                  : "row"
              }
            >
              <div className="col-xl-4 col-lg-4 col-md-12 house">
                <img
                  src={
                    offer.featured_image?.url || "/images/house-placeholder.jpg"
                  }
                  alt="house"
                />
              </div>
              <div className="col-xl-8 col-lg-8 col-md-12 homeoffer-details padd-lft">
                {!!offer?.aiScore && offer.aiScore !== "NaN" && showAiScore && 
                <div 
                  className="ai-score-card-container"
                  id={`Tooltip-AIScore-${id}`} 
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault(); 
                  }}
                  style={{
                    fontSize: showWeightedScorePercentage && offer.aiScorePercentage !== undefined ? '16px' : undefined,
                  }}
                  >
                  {showWeightedScorePercentage ? `${offer.aiScorePercentage}%` : offer.aiScore}
                  <Tooltip
                    placement="right"
                    isOpen={this.state.tooltipOpen[id] || false}
                    target={`Tooltip-AIScore-${id}`}
                    toggle={() => this.toggleTooltip(id)}
                    autohide={false}
                    modifiers={{
                      preventOverflow: { boundariesElement: "viewport" }, // Prevent overflow
                      offset: { offset: "0, 10" }, // Add spacing
                    }}
                  >
                    <div className="ai-score-tooltip">
                      <p className="tooltip-heading">Optimize Your Offers with Homematchx AI</p>
                      <p style={{ marginBottom: "5px", fontSize: '14px' }}>
                        Our unique Weighted Scoring System evaluates offers based on key criteria like purchase price, closing date, earnest money, and more, giving you an edge.
                      </p>

                      <Button
                        className="view-scoring-details-btn"
                        onClick={(e) => {
                          e.stopPropagation();
                          this.toggleScoringDetails(id);
                        }}
                      >
                        {scoringDetailsVisible[id] ? "Hide Scoring Details" : "View Scoring Details"}
                        <img
                          className={
                            scoringDetailsVisible[id]
                              ? "arrow-down-flipped"
                              : "arrow-down-unflipped"
                          }
                          src={require("../../assets/icons/list-arrow-down-green.png")}
                        />
                      </Button>
                      {scoringDetailsVisible[id] && this.renderDynamicTable(offer, listingPrice)}
            
                      <p className="scoring-tooltip-disclamer">
                        Weighted scores account for bonus points and align with seller preferences for price, speed, and terms.
                      </p>

                      <Button
                        className="learn-scoring-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          this.props.toggleLearnScoringModal();
                        }}
                      >
                        Learn How We Score
                      </Button>
                    </div>
                  </Tooltip>
                </div>}
                <ul>
                  <li className="offfer-status-rendering-container">
                    <p className="offer">
                      Offer {curItem} of {totalItems}{" "}
                      {verified ? (
                        <img src={VerifiedListing} style={{ marginLeft: 10 }} />
                      ) : null}{" "}
                      <i
                        className={`${
                          status === "Executed" ? "ml-2" : "ml-20"
                        } new-offers-status-view`}
                      >
                        {this.renderStatus(status, isBuyer, viewed)}
                      </i>
                    </p>
                  </li>
                  <p className="new-offers-mobile-status-view">
                    {this.renderStatus(status, isBuyer, viewed)}
                  </p>
                  <li className="flex-start-align-center">
                    <h5>{address}</h5>
                    {!!offer_audio && <img
                      className={"offer-audio-img"}
                      src={require("../../assets/icons/audio.png")}
                    />}
                    {!!offer_video && <img
                      className={"offer-video-img"}
                      src={require("../../assets/icons/video-2.png")}
                    />}
                  </li>
                  <li>
                    <img src={Person} />
                    <p>
                      buyers agent{" "}
                      <span>
                        <AgentInformationModal
                          comingFrom="offersPage"
                          key={id}
                          showBuyerAgentStats={true}
                          offer={this.props.offer}
                        />
                        , {brokerageName}
                      </span>
                    </p>
                  </li>
                  <li>
                    <img src={Calendar} />
                    <p>
                      <span>
                        {moment(createdAt * 1000).format(
                          "MMMM Do YYYY, [at] h:mm:ss a"
                        )}{" "}
                        CST ({moment(createdAt * 1000).fromNow()})
                      </span>
                    </p>
                  </li>
                  {viewedAt && (
                    <li>
                      <img src={Flag} />
                      <p>
                        The offer has been reviewed{" "}
                        {moment(viewedAt * 1000).format(
                          "MM.DD.YYYY [at] hh:mm A"
                        )}{" "}
                        CST by <span className="underline">{viewedBy}</span>
                      </p>
                    </li>
                  )}
                </ul>
                <div className="centerly offer-price-container">
                  <NumberFormat
                    className="price-tag"
                    value={purchasePrice}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                    renderText={(value, props) => (
                      <p {...props}>
                        {value}{" "}
                        <span
                          style={{
                            color:
                              OfferValueInPercentage > 0
                                ? "#28a745"
                                : "#eb3e54",
                            fontSize: 18,
                            display:
                              OfferValueInPercentage === 0 ||
                              !OfferValueInPercentage
                                ? "none"
                                : "inline",
                          }}
                        >
                          ({OfferValueInPercentage.toFixed(2)}%)
                        </span>
                      </p>
                    )}
                  />
                  <button className="green-btn new-offers-green-btn">
                    Review Offer
                  </button>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Link>
    );
  }
}

export default OfferListItem;
