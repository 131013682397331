import React, { Component } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import queryString from "query-string";
import OfferListItem from "components/Offers/OfferListItem";
import shareOffersTemplate from "../templates/email/share-offers";
import styles from "../components/Analytics/Analytic.module.css";
import ScoringModal from '../components/ScoringModal';
import _ from "underscore";
import Switch from "react-switch"; 
import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Row,
  Col,
  Label,
  Input,
  FormGroup,
  ModalFooter,
  Button,
} from "reactstrap";
import XLSX from "xlsx";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import {
  getOffers,
  getUsersSellers,
  bestMatchOffers,
} from "../Services/SellerService";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "../assets/css/offers-new.css";
import DateRangePicker from "react-daterange-picker";
import Loader from "react-loader-spinner";
import "react-daterange-picker/dist/css/react-calendar.css";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { add } from "lodash";
import Select from "react-select";

// fake data generator
const getItems = (count, offset = 0) =>
  Array.from({ length: count }, (v, k) => k).map((k) => ({
    id: `item-${k + offset}`,
    content: `item ${k + offset}`,
  }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: "8px 16px",
  margin: `0 0 ${grid}px 0`,
  background: isDragging ? "#ffa5b8" : "white",
  ...draggableStyle,
});

const getList1Style = () => ({
  background: "#EBECF0",
  // background: isDraggingOver ? '#EBECF0' : '#ffeaee',
  padding: grid * 2,
  width: "50%",
});

const getList2Style = (isDraggingOver) => ({
  background: isDraggingOver ? "#ffeaee" : "#EBECF0",
  padding: grid * 2,
  width: "50%",
  border: isDraggingOver ? "1px dashed #ff214f" : 0,
});

class Offers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAiScore: false,
      showWeightedScorePercentage: false,
      aiSortingEnabled: false,
      filteredOffers: [],
      showLearnScoringModal: false,
      aiPoweredModal: false,
      aiPoweredModalLoading: false,
      isGlobal: false,
      globalAllOffers: [],
      globalSellers: [],
      paginationOffset: 0,
      totalPages: 0,
      listingsPerPageFilter: 10,
      smsBlast: false,
      sellers: [],
      smsBlastListings: [],
      error: "",
      isBuyer: false,
      receivedOffers: [],
      sendOffers: [],
      loading: true,
      smsTxt:
        "You made an offer on one or more of %name% with Ink Realty Group listings. They have a new listing you may be interested in at %sellerAddress%: https://homematchx.com/sellers/%listingKey%",
      activeTab: "1",
      offerStatusFilter: "",
      offerTypeFilter: "",
      offerBuyerFilter: "",
      offerAddressFilter: "",
      items: getItems(10),
      selected: getItems(5, 10),
      spreadsheetSelected: [],
      brokerageNames: [],
      shareCSVModal: false,
      spreadsheetOptions: [
        {
          key: "address",
          name: "Address",
        },
        {
          key: "agentName",
          name: "Agent Name",
        },
        {
          key: "bathrooms",
          name: "Bathrooms",
        },
        {
          key: "bedrooms",
          name: "Bedrooms",
        },
        {
          key: "brokerageName",
          name: "Brokerage Name",
        },
        {
          key: "buyerEmail",
          name: "Agent Email",
        },
        {
          key: "buyerName",
          name: "Buyer Name",
        },
        {
          key: "closingDate",
          name: "Closing Date",
        },
        {
          key: "comments",
          name: "Comments",
        },
        {
          key: "createdAt",
          name: "Created At",
        },
        {
          key: "earnestMoney",
          name: "Earnest Money",
        },
        {
          key: "email",
          name: "Email",
        },
        {
          key: "featured_image",
          name: "Featured Image",
        },
        {
          key: "financeAmount",
          name: "Finance Amount",
        },
        {
          key: "financingType",
          name: "Financing Type",
        },
        {
          key: "leasebackDays",
          name: "Leaseback Number of Days",
        },
        {
          key: "licenseNo",
          name: "License No",
        },
        {
          key: "nonRealtyItem",
          name: "Non Realty Item",
        },
        {
          key: "purchasePrice",
          name: "Purchase Price",
        },
        {
          key: "phoneNumber",
          name: "Phone Number",
        },
        {
          key: "possession",
          name: "Possession",
        },
        {
          key: "actual_price",
          name: "Listing Price",
        },
        {
          key: "saleOfOtherProperty",
          name: "Sale Of Other Property",
        },
        {
          key: "search",
          name: "Search",
        },
        {
          key: "sellerEmail",
          name: "Seller Email",
        },
        {
          key: "seller_id",
          name: "Seller Id",
        },
        {
          key: "sqft",
          name: "Sqft",
        },
        {
          key: "status",
          name: "Status",
        },
        {
          key: "statusChangedAt",
          name: "Status Changed At",
        },
        {
          key: "titlePolicyExpense",
          name: "Title Policy Expense",
        },
        {
          key: "updateHistory",
          name: "Update History",
        },
        {
          key: "viewed",
          name: "Viewed",
        },
        {
          key: "viewedAt",
          name: "Viewed At",
        },
        {
          key: "viewedBy",
          name: "Viewed By",
        },
        {
          key: "optionFee",
          name: "Option Fee",
        },
        {
          key: "optionDays",
          name: "Option Days",
        },
        {
          key: "rightToTerminate",
          name: "Right to Terminate",
        },
        {
          key: "residentialServiceContract",
          name: "Residential Service Contract",
        },
        {
          key: "downPayment",
          name: "Down Payment",
        },
      ],
      statusFilterOptions: [
        "In Review",
        "Accepted",
        "Not Accepted",
        "Withdraw",
        "Terminated",
        "Executed",
        "Seller Review",
        "Countered",
      ],
      offerFinanceTypeFilter: [
        "Cash",
        "Lease",
        "Conventional",
        "FHA",
        "VA",
        "Seller Finance",
      ],
      offerTypeFilterOptions: ["Sent", "Received"],
      listingFilterOptions: ["Verified", "Non Verified", "Archived"],
      filterBrokerageName: null,
      buyerFilterOptions: [],
      addressFilterOptions: [],
      chartModal: false,
      spreadSheetModal: false,
      selectedFinanceType: null,
      showDatePicker: false,
      offersStatusFilter: [],
      buyersAgentFilter: [],
      propertyAddressFilter: [],
      brokerageNameFilter: [],
      offerTypeFilter: [],
      financeTypeFilter: [],
      listingTypeFilter: [],
      showMoreStatusFilterOptions: false,
      showStatusFilter: false,
      showBuyersAgentFilter: false,
      showMoreBuyersAgentFilterOptions: false,
      showPropertyAddressFilter: false,
      showMorePropertyAddressFilterOptions: false,
      showOfferTypeFilter: false,
      showBrokerageNameFilter: false,
      showFinanceTypeFilter: false,
      showMoreBrokerageNameFilterOptions: false,
      showMoreFinanceTypeFilterOptions: false,
      offerFiltersModal: false,
      filteredAddressOptions: [],
      filteredStatusOptions: [],
      filteredBuyersAgentOptions: [],
      filteredBrokerageNameOptions: [],
      filteredOfferTypeOptions: [],
      filteredFinanceTypeOptions: [],
      filteredListingTypeOptions: [],
      filterDateRange: { start: null, end: null },
      showListingTypeFilter: false,
      options: {
        chart: {
          type: "line",
          renderTo: "container",
        },
        xAxis: {
          title: {
            text: "Offer",
          },
          type: "datetime",
          min: 0,
          max: 15,
          categories: [],
          labels: {
            overflow: "justify",
            step: 1,
            rotation: -90,
          },
        },
        yAxis: {
          min: 0,
          max: 0,
          title: {
            text: "Cost ($)",
          },
        },
        tooltip: {
          headerFormat:
            '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>${point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          style: {
            pointerEvents: "auto",
            whiteSpace: "normal",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
        },
        series: [
          {
            name: "Purchase",
            data: [],
            color: "#90A69B",
          },
          {
            name: "Down payment",
            data: [],
            color: "#E5B536",
          },
          {
            name: "Earnest Money",
            data: [],
            color: "#3745C8",
          },
          {
            name: "Option Fee",
            data: [],
            color: "#038A4B",
          },
        ],
        scrollbar: {
          enabled: true,
          barBackgroundColor: "gray",
          barBorderRadius: 7,
          barBorderWidth: 0,
          buttonBackgroundColor: "gray",
          buttonBorderWidth: 0,
          buttonArrowColor: "yellow",
          buttonBorderRadius: 7,
          rifleColor: "yellow",
          trackBackgroundColor: "white",
          trackBorderWidth: 1,
          trackBorderColor: "silver",
          trackBorderRadius: 7,
        },
      },
      offersToShare: [],
      shareOffersModel: false,
      shareOfferEmail: null,
      shareOfferName: null,
      sendingOffers: false,
      filteredListingsShare: [],
      showMoreFilteredListingsShare: false,
      groups: [],
      selectedGroups: [],
      groupAudience: 0,
      selectedGroupsOption: [],
      deadline: null,
      smsTemplates: [
        {
          label:
            "You made an offer on one or more of %name% with Ink Realty Group listings. They have a new listing you may be interested in at %sellerAddress%: https://homematchx.com/sellers/%listingKey%",
          value: "default",
        },
        {
          label:
            "Hi %agentName% we have multiple offers on %sellerAddress%. Deadline is %deadline%.",
          value: "hi_agent_we_have_multiple_offers",
        },
        {
          label:
            "Thank you for submitting an offer. The seller is reviewing all offers and will make a decision soon.",
          value: "thank_you_for_submitting_an_offer",
        },
        {
          label: "Seller is reviewing all offers and will make a decision soon",
          value: "seller_is_reviewing_all_offers_and_will_make_a_decision_soon",
        },
        {
          label:
            "You represented a cash offer buyer on one of my listings and we are looking for a cash offer on this new listing %sellerAddress%",
          value:
            "you_represented_a_cash_offer_buyer_on_one_of_my_listings_and_we_are_looking_for_a_cash_offer_on_this_new_listing",
        },
        {
          label:
            "If you are receiving this message your offer has been received and viewed by the Listing Agent. Thank you!",
          value:
            "if_you_are_receiving_this_message_your_offer_has_been_received_and_viewed_by_the_listing_agent_thank_you",
        },
        {
          label: "The counteroffer has been submitted to the buyer.",
          value: "the_counteroffer_has_been_submitted_to_the_buyer",
        },
        {
          label: "The counteroffer has been submitted to the seller.",
          value: "the_counteroffer_has_been_submitted_to_the_seller",
        },
        {
          label:
            "The seller has agreed to the buyers offer terms and will accept the offer.",
          value:
            "the_seller_has_agreed_to_the_buyers_offer_terms_and_will_accept_the_offer",
        },
        {
          label:
            "The buyer has agreed to the sellers offer terms and will accept the offer.",
          value:
            "the_buyer_has_agreed_to_the_sellers_offer_terms_and_will_accept_the_offer",
        },
        {
          label:
            "The seller has agreed to the buyers offer terms and will not accept the offer.",
          value:
            "the_seller_has_agreed_to_the_buyers_offer_terms_and_will_not_accept_the_offer",
        },
        {
          label:
            "The buyer has agreed to the sellers offer terms and will not accept the offer.",
          value:
            "the_buyer_has_agreed_to_the_sellers_offer_terms_and_will_not_accept_the_offer",
        },
      ],
    };
    this.DatePickerRef = React.createRef();
  }

  id2List = {
    droppable: "spreadsheetOptions",
    droppable2: "spreadsheetSelected",
  };

  handleGroupChange = (selectedOptions) => {
    const { groups } = this.state;
    const selectedGroups = groups.filter((group) =>
      selectedOptions.some((option) => option.value === group.id)
    );
    const selectedGroupsOption = selectedOptions || [];

    let groupAudience = selectedGroups.reduce((totalAudience, group) => {
      const audience = group?.agents?.reduce(
        (count, agent) =>
          count + (agent?.phoneNumber?.match(/\d/g)?.join("") ? 1 : 0),
        0
      );
      return totalAudience + audience;
    }, 0);

    this.setState({
      selectedGroupsOption: selectedGroupsOption,
      selectedGroups: selectedGroups,
      groupAudience: groupAudience,
    });
  };

  handlePageClick = (data) => {
    this.setState({ paginationOffset: data.selected });
  };

  getList = (id) => this.state[this.id2List[id]];

  onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) return;
    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        this.getList(source.droppableId),
        source.index,
        destination.index
      );

      let state = { items };

      if (source.droppableId === "droppable2") {
        state = { spreadsheetSelected: items };
      }

      this.setState(state);
    } else {
      const result = move(
        this.getList(source.droppableId),
        this.getList(destination.droppableId),
        source,
        destination
      );

      this.setState({
        spreadsheetOptions: result.droppable.filter(
          (el) => JSON.stringify(result.droppable2).indexOf(el.key) < 0
        ),
        spreadsheetSelected: result.droppable2,
      });
    }
  };

  toggle = (activeTab) => {
    this.setState({ activeTab });
  };

  chartToggle = () => {
    this.setState({
      chartModal: !this.state.chartModal,
    });
  };

  spreadSheetToggle = () => {
    this.setState({
      spreadSheetModal: !this.state.spreadSheetModal,
    });
  };

  offerFiltersToggle = () => {
    this.setState({
      offerFiltersModal: !this.state.offerFiltersModal,
      offersStatusFilter: [],
      buyersAgentFilter: [],
      propertyAddressFilter: [],
      brokerageNameFilter: [],
      offerTypeFilter: [],
      financeTypeFilter: [],
      listingTypeFilter: [],
      filterDateRange: { start: null, end: null },
    });
  };

  longPressEvents(callback, ms = 500) {
    let timeout = null;

    const start = () => (timeout = setTimeout(callback, ms));
    const stop = () => timeout && window.clearTimeout(timeout);

    return callback
      ? {
          onTouchStart: start,
          onTouchMove: stop,
          onTouchEnd: stop,
        }
      : {};
  }

  async componentDidMount() {
    const { user, location } = this.props;
    const { uid, email } = user;
    const { options, globalAllOffers, globalSellers } = this.state;
    const db = firebase.firestore();
    let query = db.collection("offers");
    let groupsData = [];

    let globalSellersData = [];
    let globalAllOffersData = [];

    if (user.allowedToViewProperties?.length) {
      for (const property of user.allowedToViewProperties) {
        const doc = await db
          .collection("offers")
          .where("seller", "==", property.property)
          .get();
        doc.forEach((doc) => {
          globalAllOffersData.push({
            id: doc.id,
            ...doc.data(),
          });
        });
      }

      for (const property of user.allowedToViewProperties) {
        const doc = await db.collection("sellers").doc(property.property).get();
        globalSellersData.push({
          id: doc.id,
          ...doc.data(),
        });
      }
    }

    this.setState({
      globalAllOffers: globalAllOffersData,
      globalSellers: globalSellersData,
    });

    const params = queryString.parse(window.location?.search);
    this.setState(
      {
        ...params,
      },
      () => this.filterData()
    );

    this.getUserSpreadSheetAttributes();
    if (location.search.indexOf("status") > 0)
      query = db
        .collection("offers")
        .where("status", "==", location.search.split("=")[1]);
    const isBuyer = user.uid === this.props.location.state?.offer?.sender;
    this.setState({ isBuyer });
    const promises = [
      query
        .where("search", "array-contains", email)
        .orderBy("createdAt", "desc")
        .get(),
      query
        .where("search", "array-contains", uid)
        .orderBy("createdAt", "desc")
        .get(),
      query.where("sender", "==", uid).orderBy("createdAt", "desc").get(),
    ];

    try {
      let sellers = await getUsersSellers(user);
      sellers = sellers.map((seller) => {
        return {
          id: seller?.id,
          ...seller.data(),
        };
      });

      const snaps = await Promise.all(promises);
      const docs = {};
      [snaps[0], snaps[1]].forEach((s) => {
        s.docs.forEach((d) => {
          docs[d?.id] = {
            ...d.data(),
            id: d?.id,
          };
        });
      });

      const snap = snaps[2];
      const sentOffers = snap.docs.map((d) => ({ id: d?.id, ...d.data() }));
      const receivedOffers = Object.keys(docs).map((key) => docs[key]);
      const buyerFilterOptions = [
        ...new Set(Object.values(docs).map((m) => m.agentName)),
      ];
      let brokerageNames = [
        ...new Set(Object.values(docs).map((m) => m.brokerageName)),
      ];
      let addressFilterOptions = [
        ...new Set(Object.values(docs).map((m) => m.address)),
      ];
      brokerageNames = _.sortBy(brokerageNames);
      const offers = [...new Set([...sentOffers, ...receivedOffers])];

      db.collection("network")
        .doc(this.props.user?.uid)
        .collection("buyerAgentGroups")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = {
              id: doc.id,
              ...doc.data(),
            };
            groupsData.push(data);
          });
        });
      this.setState({
        groups: groupsData,
        totalPages: Math.ceil(
          Object.keys(docs).length / this.state.listingsPerPageFilter
        ),
        filteredOffers: docs,
        sellers,
        buyerFilterOptions,
        aiSortingEnabled: this.props.user.aiSortingEnabled,
        showWeightedScorePercentage: this.props.user.showWeightedScorePercentage,
        showAiScore: this.props.user.showAiScore,
        addressFilterOptions,
        filteredStatusOptions: this.state.statusFilterOptions,
        filteredBuyersAgentOptions: buyerFilterOptions,
        filteredAddressOptions: addressFilterOptions,
        filteredBrokerageNameOptions: brokerageNames,
        filteredOfferTypeOptions: this.state.offerTypeFilterOptions,
        filteredFinanceTypeOptions: this.state.offerFinanceTypeFilter,
        filteredListingTypeOptions: this.state.listingFilterOptions,
        sendOffers: sentOffers,
        receivedOffers: docs,
        brokerageNames,
        loading: false,
        offers,
        ...params,
      });

      let graphSeries = options.series;
      this.setState({
        options: {
          ...options,
          series: options.series.map((s) => {
            s.data = [];
            return s;
          }),
        },
      });

      const addresses = [];
      for (const i in offers) {
        let o = offers[i];
        graphSeries = options.series.map((s) => {
          if (i === 0) s.data = [];

          addresses.push(o.address);
          if (s.name === "Purchase")
            s.data.push({
              y: parseInt(o.purchasePrice),
              link: o?.id,
            });
          if (s.name === "Down payment")
            s.data.push({
              y: parseInt(o.downPayment),
              link: o?.id,
            });
          if (s.name === "Earnest Money")
            s.data.push({
              y: parseInt(o.earnestMoney),
              link: o?.id,
            });
          if (s.name === "Option Fee")
            s.data.push({
              y: parseInt(o.optionFee),
              link: o?.id,
            });

          if (options.yAxis.max < parseInt(o.purchasePrice))
            options.yAxis.max = parseInt(o.purchasePrice) + 1000;
          return s;
        });
      }
      this.setState({
        options: {
          ...options,
          series: graphSeries,
          xAxis: {
            ...options.xAxis,
            categories: addresses,
          },
        },
      });
    } catch (error) {
      this.setState({
        loading: false,
        error: error.message || "Something went wrong, please try again!",
      });
    }
  }

  getUserSpreadSheetAttributes = async () => {
    const db = firebase.firestore();
    db.collection("users")
      .doc(this.props.user?.uid)
      .get()
      .then((snapshot) => {
        const selectedOptions = snapshot.data()?.spreadsheet_attributes
          ? snapshot.data()?.spreadsheet_attributes
          : [];

        this.setState({
          spreadsheetSelected: selectedOptions,
          spreadsheetOptions: this.state.spreadsheetOptions.filter(
            (el) => JSON.stringify(selectedOptions).indexOf(el.key) < 0
          ),
        });
      });
  };

  filterOffers = ({
    buyersAgent,
    propertyAddress,
    brokerageName,
    offerType,
    financeType,
    listingType,
    status,
    dateRange,
  }) => {
    const {
      propertyAddressFilter,
      offersStatusFilter,
      buyersAgentFilter,
      brokerageNameFilter,
      offerTypeFilter,
      financeTypeFilter,
      filterDateRange,
      listingTypeFilter,
      // offers
    } = this.state;

    const offers = this.state["receivedOffers"];

    const { user } = this.props;
    const userId = user?.uid;
    const endDate = !!dateRange?.end && moment(dateRange.end).format();
    const startDate = !!dateRange?.start && moment(dateRange.start).format();

    let filteredOffers = Object.keys(offers)
      .filter((key) => offers[key].status !== "Deleted")
      .filter((k) => {
        if (propertyAddress?.length)
          return propertyAddress.includes(offers[k]?.address);
        return k;
      })
      .filter((k) => {
        if (status?.length) {
          if (status.includes("Accepted")) {
            return ["Accepted", "Executed"].includes(offers[k]?.status);
          }
          return status.includes(offers[k]?.status);
        }
        return k;
      })      
      .filter((k) => {
        if (buyersAgent?.length) {
          return buyersAgent.includes(offers[k]?.agentName);
        }
        return k;
      })
      .filter((k) => {
        if (brokerageName?.length)
          return brokerageName.includes(offers[k]?.brokerageName);
        return k;
      })
      .filter((k) => {
        if (offerType?.length) {
          if (offerType.includes("Received") && offerType.includes("Sent")) {
            return k;
          }
          if (offerType.includes("Received")) {
            return offers[k]?.sellerEmail === user.email;
          }
          if (offerType.includes("Sent")) {
            return offers[k]?.sellerEmail !== user.email;
          }
        }
        return k;
      })
      .filter((k) => {
        if (financeType?.length)
          return financeType.includes(offers[k]?.financingType);
        return k;
      })
      .filter((k) => {
        if (endDate && startDate)
          return (
            startDate <= moment(offers[k]?.createdAt * 1000).format() &&
            moment(offers[k]?.createdAt * 1000).format() <= endDate
          );
        return k;
      })
      .filter((k) => {
        if (
          listingType?.includes("Verified") &&
          listingType.includes("Non Verified")
        )
          return k;
        if (listingType?.includes("Verified")) return offers[k]?.verified;
        if (listingType?.includes("Non Verified")) return !offers[k]?.verified;
        if (listingType?.includes("Archived")) {
          return offers[k]?.archived?.includes(userId);
        }
        return k;
      });

    const updatedOffers = {};
    for (const key of filteredOffers) {
      updatedOffers[key] = offers[key];
    }

    this.setState({
      filteredOffers: updatedOffers,
      totalPages: Math.ceil(filteredOffers.length / 10),
    });
  };

  sortOffers = (offers, showWeightedScorePercentage) => {
    // Group offers by seller
    const groupedOffers = {};
    Object.values(offers).forEach((offer) => {
      const sellerId = offer.seller;
      if (!groupedOffers[sellerId]) {
        groupedOffers[sellerId] = [];
      }
      groupedOffers[sellerId].push(offer);
    });
  
    // Sort each group by the chosen field
    Object.keys(groupedOffers).forEach((sellerId) => {
      groupedOffers[sellerId].sort((a, b) => {
        const key = showWeightedScorePercentage
          ? "aiScorePercentage"
          : "aiScore";
        return b[key] - a[key]; // Descending order
      });
    });
  
    // Flatten grouped and sorted offers into a single array
    return Object.values(groupedOffers).flat();
  };  

  renderOffers = (key) => {
    const db = firebase.firestore();
    const {
      sellers,
      loading,
      error,
      isGlobal,
      globalSellers,
      globalAllOffers,
      filteredOffers,
      showAiScore,
      aiSortingEnabled,
      showWeightedScorePercentage
    } = this.state;
    const offers = this.state[key];
    const { user } = this.props;
    const userId = user?.uid;
    const listingTypeFilter = this.state.listingTypeFilter; 
    let errorCase = "Loading...";
    errorCase = error || errorCase;

    if ((loading || error) && errorCase) {
      return (
        <table className="manage-table">
          <tbody>
            <tr className="responsive-table">
              <td>
                <p>{errorCase}</p>
              </td>
            </tr>
          </tbody>
        </table>
      );
    }
    const bestMatches = bestMatchOffers(offers, sellers);

    const filteredOffersByArchived = Object.keys(filteredOffers)
    .filter((key) => {
      const offer = offers[key];
      if (listingTypeFilter.includes("Archived")) {
        return offer.archived?.includes(userId);
      }
      return !offer.archived?.includes(userId);
    })
    .reduce((acc, key) => {
      acc[key] = offers[key];
      return acc;
    }, {});

  // Apply sorting only if showAiScore is true
  const sortedOffers =
    showAiScore && aiSortingEnabled
      ? this.sortOffers(filteredOffersByArchived, showWeightedScorePercentage)
      : Object.values(filteredOffersByArchived);
      
    if (isGlobal) {
      if (globalAllOffers.length) {
        // return;
        return globalAllOffers.map((offer, index) => (
          <OfferListItem
            isGlobal={true}
            showWeightedScorePercentage={this.state.showWeightedScorePercentage}
            toggleLearnScoringModal={this.toggleLearnScoringModal}
            showAiScore={this.state.showAiScore}
            sellers={globalSellers}
            bestMatch={bestMatches}
            key={index}
            user={user}
            curItem={
              this.state.paginationOffset * this.state.listingsPerPageFilter +
              index +
              1
            }
            totalItems={globalAllOffers.length}
            offer={offer}
            allOffers={globalAllOffers}
          />
        ));
      } else {
        return <p className="noOffers">No offers found</p>;
      }
    } else if (user.role == "titleCompany") {
      return user.recievedOffers.length ? (
        user.recievedOffers
          .slice(
            this.state.paginationOffset * this.state.listingsPerPageFilter,
            this.state.listingsPerPageFilter * (this.state.paginationOffset + 1)
          )
          .map((k, index) => (
            <OfferListItem
              showAiScore={this.state.showAiScore}
              showWeightedScorePercentage={this.state.showWeightedScorePercentage}
              toggleLearnScoringModal={this.toggleLearnScoringModal}
              sellers={sellers}
              bestMatch={bestMatches}
              key={index}
              user={user}
              curItem={
                this.state.paginationOffset * this.state.listingsPerPageFilter +
                index +
                1
              }
              totalItems={user.recievedOffers.length}
              offer={k}
              allOffers={user.recievedOffers}
            />
          ))
      ) : (
        <p className="noOffers">No offers found</p>
      );
    } else {
      if (offers) {
        return Object.keys(filteredOffersByArchived).length ? (
          sortedOffers
            .slice(
              this.state.paginationOffset * this.state.listingsPerPageFilter,
              this.state.listingsPerPageFilter * (this.state.paginationOffset + 1)
            )
            .map((offer, index) => (
              <OfferListItem
              showAiScore={this.state.showAiScore}
              showWeightedScorePercentage={this.state.showWeightedScorePercentage}
              toggleLearnScoringModal={this.toggleLearnScoringModal}
              sellers={sellers}
              bestMatch={bestMatches}
              key={index}
              user={user}
              curItem={
                this.state.paginationOffset * this.state.listingsPerPageFilter +
                index +
                1
              }
              totalItems={Object.keys(filteredOffersByArchived).length}
              offer={offer}
              allOffers={sortedOffers}
            />
            ))
        ) : (
          <p className="noOffers">No offers found</p>
        );
      }
    }
  };

  updateGraph = (type) => {
    getOffers(this.props.user, null, {
      financeType: type,
    })
      .then((offers) => {
        let graphSeries = this.state.options.series;
        this.setState({
          ...this.state.options,
          series: this.state.options.series.map((s) => {
            s.data = [];
            return s;
          }),
        });

        const addresses = [];
        for (const i in offers) {
          let o = offers[i];
          graphSeries = this.state.options.series.map((s) => {
            if (i === 0) s.data = [];
            addresses.push(o.address);

            if (s.name === "Purchase") s.data.push(parseInt(o.purchasePrice));
            if (s.name === "Down payment") s.data.push(parseInt(o.downPayment));
            if (s.name === "Earnest Money")
              s.data.push(parseInt(o.earnestMoney));
            if (s.name === "Option Fee") s.data.push(parseInt(o.optionFee));

            if (this.state.options.yAxis.max < parseInt(o.purchasePrice));
            this.state.options.yAxis.max = parseInt(o.purchasePrice) + 1000;
            return s;
          });
        }

        this.setState({
          options: {
            ...this.state.options,
            series: graphSeries,
            xAxis: {
              ...this.state.options.xAxis,
              categories: addresses,
            },
          },
        });
      })
      .catch((err) => {
        console.log("Get All Offers Error: ", err);
      });
  };

  exportDataToXLSX = async () => {
    const { spreadsheetSelected } = this.state;
    const filteredXlsxAttributes = spreadsheetSelected.map((e) => e);
    const data = Object.keys(this.state.filteredOffers).map((key) => {
      const offer = this.state.receivedOffers[key];

      delete offer.seller;
      delete offer.sender;
      delete offer?.id;
      delete offer.buyer;
      delete offer.documents;
      delete offer.notifiedBy;
      delete offer.notifiedBy;

      const updatedOffer = {};
      filteredXlsxAttributes.forEach((e) => {
        updatedOffer[e.name] = offer[e.key];
      });

      return updatedOffer;
    });
    const db = firebase.firestore();
    await db.collection("users").doc(this.props.user.uid).update({
      spreadsheet_attributes: spreadsheetSelected,
    });
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, "users_sheet");

    const filename = "offers.xlsx";
    const wb_opts = { bookType: "xlsx", type: "binary" };
    XLSX.writeFile(wb, filename, wb_opts);
    this.spreadSheetToggle();
  };

  shareXLSXViaEMail = async () => {
    if (!this.state.emailToShare)
      return alert("Please enter correct email address.");

    this.setState({
      sharingCSV: true,
    });

    const { spreadsheetSelected } = this.state;
    const filteredXlsxAttributes = spreadsheetSelected.map((e) => e);
    const data = Object.keys(this.state.receivedOffers).map((key) => {
      const offer = this.state.receivedOffers[key];

      delete offer.seller;
      delete offer.sender;
      delete offer?.id;
      delete offer.buyer;
      delete offer.documents;
      delete offer.notifiedBy;
      delete offer.notifiedBy;

      const updatedOffer = {};
      filteredXlsxAttributes.forEach((e) => {
        updatedOffer[e.name] = offer[e.key];
      });

      return updatedOffer;
    });
    const db = firebase.firestore();
    await db.collection("users").doc(this.props.user.uid).update({
      spreadsheet_attributes: spreadsheetSelected,
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, "users_sheet");
    const csvData = XLSX.utils.sheet_to_csv(ws);

    const sendEmail = firebase.functions().httpsCallable("sendMatchEmail");

    try {
      await sendEmail({
        subject: "Offers CSV shared - Homematchx",
        email: this.state.emailToShare,
        body: `Hello Dear,
        <br /><br />
        Please download attached CSV.<br /><br /><br />
        Regards,<br />Homematchx Team`,
        attachments: [
          {
            filename: "offers.csv",
            content: csvData,
          },
        ],
      });
    } catch (err) {
      console.log(err);
    }

    this.setState({
      shareCSVModal: false,
      sharingCSV: false,
    });
  };

  sendSMSToAll = async () => {
    const {
      smsBlastListings,
      filteredOffers,
      sellers,
      smsTxt,
      selectedGroups,
      groupAudience,
      deadline,
    } = this.state;
    const { user } = this.props;

    if (!smsBlastListings.length)
      return alert("Please select atleast 1 listing to send sms blast.");

    smsBlastListings.forEach((listing) => {
      const seller = sellers.find((seller) => seller.id === listing);
      let numbers;
      let names;

      if (!selectedGroups.length && groupAudience === 0) {
        numbers = Object.keys(filteredOffers).map((key) =>
          filteredOffers[key].phoneNumber.match(/\d/g)?.join("")
        );
        names = Object.keys(filteredOffers).map(
          (key) =>
            filteredOffers[key].agentName || filteredOffers[key].buyerName
        );
      }

      numbers = [...new Set(numbers)];
      names = [...new Set(names)];

      if (selectedGroups.length && groupAudience > 0) {
        selectedGroups.forEach((group) => {
          group.agents.forEach((agent) => {
            const phoneNumber = agent.phoneNumber?.match(/\d/g)?.join("");
            if (phoneNumber) {
              numbers.push(phoneNumber);
              names.push(agent.name);
            }
          });
        });
      }

      const sendSMS = firebase.functions().httpsCallable("sendSMS");

      numbers.forEach(async (num, i) => {
        try {
          await sendSMS({
            to: `+1${num.replace("+1", "")}`,
            body: smsTxt
              .replace("%name%", user.realtor_name)
              .replace("%sellerAddress%", seller.UnparsedAddress)
              .replace("%listingKey%", seller.ListingKey)
              .replace("%agentName%", names[i] || "")
              .replace(
                "%deadline%",
                moment(deadline).format("dddd, MMMM D, YYYY h:mm A")
              ),
          });
        } catch (err) {
          console.log(err);
        }
      });
    });

    this.setState({
      smsBlast: false,
    });
  };

  filterData = async () => {
    const {
      offerStatusFilter,
      offerBuyerFilter,
      offerAddressFilter,
      offerTypeFilter,
      offerBrokerageNamesFilter,
    } = this.state;
    const { user } = this.props;
    const { uid, email } = user;
    const db = firebase.firestore();
    let query = db.collection("offers");

    if (!!offerStatusFilter)
      query = query.where("status", "==", offerStatusFilter);
    if (!!offerBuyerFilter)
      query = query.where("agentName", "==", offerBuyerFilter);
    if (!!offerAddressFilter)
      query = query.where("address", "==", offerAddressFilter);
    if (!!offerBrokerageNamesFilter)
      query = query.where("brokerageName", "==", offerBrokerageNamesFilter);

    const promises = [
      query
        .where("search", "array-contains", email)
        .orderBy("createdAt", "desc")
        .get(),
      query
        .where("search", "array-contains", uid)
        .orderBy("createdAt", "desc")
        .get(),
      query.where("sender", "==", uid).orderBy("createdAt", "desc").get(),
    ];

    try {
      const snaps = await Promise.all(promises);
      const docs = {};
      const agentNames = [];
      [snaps[0], snaps[1]].forEach((s) => {
        s.docs.forEach((d) => {
          const id = d?.id;
          const offerData = d.data();
          agentNames.push(offerData.agentName);
          if (
            offerTypeFilter === "Received" &&
            offerData.sellerEmail === user.email
          ) {
            docs[d?.id] = {
              id,
              ...offerData,
            };
          } else if (
            offerTypeFilter === "Sent" &&
            offerData.sellerEmail !== user.email
          ) {
            docs[d?.id] = {
              id,
              ...offerData,
            };
          } else if (
            offerTypeFilter !== "Received" &&
            offerTypeFilter !== "Sent"
          ) {
            docs[d?.id] = {
              id,
              ...offerData,
            };
          }
        });
      });

      const snap = snaps[2];
      const sendOffers = snap.docs.map((d) => ({ id: d?.id, ...d.data() }));
      this.setState({
        sendOffers,
        loading: false,
        receivedOffers: docs,
        buyerFilterOptions: agentNames,
      });
    } catch (error) {
      this.setState({
        loading: false,
        error: error.message || "Something went wrong, please try again !",
      });
    }
  };

  resetFilters = () => {
    this.setState(
      {
        offerStatusFilter: "",
        offerBuyerFilter: "",
        offerAddressFilter: "",
        offerBrokerageNamesFilter: "",
      },
      () => this.filterData()
    );
  };

  onChange = (e) => {
    const { search } = window.location;
    const params = queryString.parse(search);

    params[e.target.name] = e.target.value;
    window.history.pushState(
      null,
      "",
      window.location.pathname + "?" + queryString.stringify(params)
    );
    this.setState({ [e.target.name]: e.target.value }, () => this.filterData());
  };

  clearFilter() {
    window.history.pushState(
      null,
      "",
      window.location.pathname + "?" + queryString.stringify("")
    );
    this.resetFilters();
  }

  handlePropertyAddressFilter = (e) => {
    const { addressFilterOptions } = this.state;
    const filteredAddressOptions = addressFilterOptions.filter((a) =>
      a?.toLowerCase()?.includes(e.target.value.toLowerCase())
    );
    this.setState({
      filteredAddressOptions,
    });
  };

  handleStatusFilter = (e) => {
    const { statusFilterOptions } = this.state;
    const filteredStatusOptions = statusFilterOptions.filter((status) =>
      status?.toLowerCase()?.includes(e.target.value.toLowerCase())
    );
    this.setState({
      filteredStatusOptions,
    });
  };

  handleBuyerAgentFilter = (e) => {
    const { buyerFilterOptions } = this.state;
    const filteredBuyersAgentOptions = buyerFilterOptions.filter((status) =>
      status?.toLowerCase()?.includes(e.target.value.toLowerCase())
    );
    this.setState({
      filteredBuyersAgentOptions,
    });
  };

  handleBrokerageNameFilter = (e) => {
    const { brokerageNames } = this.state;
    const filteredBrokerageNameOptions = brokerageNames.filter((status) =>
      status?.toLowerCase()?.includes(e.target.value.toLowerCase())
    );
    this.setState({
      filteredBrokerageNameOptions,
    });
  };

  handleOfferTypeFilter = (e) => {
    const { offerTypeFilterOptions } = this.state;
    const filteredOfferTypeOptions = offerTypeFilterOptions.filter((status) =>
      status?.toLowerCase()?.includes(e.target.value.toLowerCase())
    );
    this.setState({
      filteredOfferTypeOptions,
    });
  };

  handleListingTypeFilter = (e) => {
    const { listingFilterOptions } = this.state;
    const filteredListingTypeOptions = listingFilterOptions.filter((status) =>
      status?.toLowerCase()?.includes(e.target.value.toLowerCase())
    );
    this.setState({
      filteredListingTypeOptions,
    });
  };

  handleFinanceTypeFilter = (e) => {
    const { offerFinanceTypeFilter } = this.state;
    const filteredFinanceTypeOptions = offerFinanceTypeFilter.filter((status) =>
      status?.toLowerCase()?.includes(e.target.value.toLowerCase())
    );
    this.setState({
      filteredFinanceTypeOptions,
    });
  };

  handleShareOffers = async () => {
    const {
      shareOfferEmail,
      shareOffersModel,
      shareOfferName,
      sendingOffers,
      offersToShare,
    } = this.state;
    const {
      profilePhoto,
      realtor_phone,
      broker_phone,
      brokerage_name,
      realtor_name,
      email,
      realtor_email,
      displayName,
    } = this.props.user;

    const filter =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (filter.test(shareOfferEmail)) {
      this.setState({
        sendingOffers: true,
      });
      const sendEmail = firebase.functions().httpsCallable("sendMatchEmail");
      await sendEmail({
        subject: `Offers Shared With You - Homematchx`,
        email: shareOfferEmail,
        body: shareOffersTemplate(offersToShare, shareOfferName,   {
          profilePhoto: profilePhoto["64"],
          realtor_phone,
          broker_phone,
          brokerage_name,
          realtor_name: displayName || realtor_name,
          email: realtor_email || email,
        }),
      });
      this.setState({
        sendingOffers: false,
      });
      toast.success("Selected offers has been shared successfully.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        offersToShare: [],
      });
      this.setState({ shareOffersModel: false });
    } else {
      alert("Please enter correct email address.");
    }
  };

  handleTemplateChange = (e) => {
    const { smsTemplates } = this.state;
    const selectedTemplate = smsTemplates.find(
      (template) => template.value === e.value
    );
    this.setState({
      smsTxt: selectedTemplate?.label,
    });
  };

  toggleLearnScoringModal = () => {
    this.setState((prevState) => ({
      showLearnScoringModal: !prevState.showLearnScoringModal,
    }));
  };

  toggleAiPoweredModal = () => {
    this.setState({ aiPoweredModal: !this.state.aiPoweredModal });
  };

  render() {
    const {
      offerTypeFilter,
      options,
      sellers,
      smsBlastListings,
      offerFinanceTypeFilter,
      selectedFinanceType,
      receivedOffers,
      offersStatusFilter,
      buyersAgentFilter,
      propertyAddressFilter,
      brokerageNameFilter,
      filteredAddressOptions,
      filteredStatusOptions,
      filteredBuyersAgentOptions,
      filteredBrokerageNameOptions,
      filteredOfferTypeOptions,
      filteredFinanceTypeOptions,
      financeTypeFilter,
      loading,
      filterDateRange,
      listingTypeFilter,
      filteredListingTypeOptions,
      offersToShare,
      shareOfferEmail,
      shareOfferName,
      shareOffersModel,
      sendingOffers,
      filteredListingsShare,
      showMoreFilteredListingsShare,
      groups,
      selectedGroups,
      groupAudience,
      smsTemplates,
      filteredOffers,
      isGlobal,
    } = this.state;
  
    const notTitleCompany = this.props.user?.role !== "titleCompany";
    const groupOptions = groups.map((group) => ({
      value: group.id,
      label: `Group: ${group.name}`,
    }));

    const offers = this.state["receivedOffers"];
    let numbers =
      Object.keys(filteredOffers) &&
      Object.keys(filteredOffers).map((key) =>
        filteredOffers[key]?.phoneNumber?.match(/\d/g)?.join("")
      );
    const smsBlastAudience = [...new Set(numbers)].length;

    const totalOffers = Object.keys(offers).filter(
      (key) => offers[key].status !== "Deleted"
    );

    return (
      <>
        {loading ? (
          <Loader
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            type="Rings"
            color="#ff214f"
            height={200}
            width={200}
          />
        ) : (
          <div className="dashboard-main">
            {/* <div className="dashboard-content"> */}
            {/* <div className="container"> */}
            <div className="dashboard-header">
              <div className="row">
                <div className="col-sm-12 col-md-3">
                  <h4 className="seller-main-heading">
                    {isGlobal ? "Global" : "My"} Offers
                  </h4>
                </div>
                {notTitleCompany && (
                  <div
                    className="col-sm-12 col-md-9 centerly mobile-btn-view-container"
                    style={{ textAlign: "right" }}
                  >
                    <div className="d-inline-block">
                      <button
                        className="homematchx-ai-button mr-2"
                        onClick={this.toggleAiPoweredModal}
                      >
                        <img 
                          className="mr-2 ai-icon"
                          src={require("../assets/icons/ai-new.png")}
                        />
                        OfferAIde
                      </button>
                      <button
                        className="follow-my-listing-btn mr-2"
                        onClick={() =>
                          this.setState({ shareOffersModel: true })
                        }
                      >
                        {/* <img
                        src={require("../assets/icons/follow-listing.svg")}
                      /> */}
                        <i className="fa fa-share-alt mr-2"></i>
                        Share Offers
                      </button>
                      <button
                        className="follow-my-listing-btn"
                        onClick={() =>
                          this.setState({
                            smsBlast: true,
                          })
                        }
                      >
                        <img
                          src={require("../assets/icons/follow-listing.svg")}
                        />
                        Follow My Listing
                      </button>
                      <button
                        className="create-spreadsheet-btn"
                        onClick={this.spreadSheetToggle}
                      >
                        <img src={require("../assets/icons/calculator.svg")} />
                        Create Spreadsheet
                      </button>
                      <button
                        className="create-spreadsheet-btn"
                        onClick={() => {
                          this.setState({ isGlobal: !this.state.isGlobal });
                        }}
                      >
                        {this.state.isGlobal ? "My" : "Global"} Offers
                      </button>
                    </div>
                  </div>
                )}
                <div className="col"></div>
              </div>
              {/* <div className={`row`}>
                <div className="col-md-12">
                  <button
                    className="create-spreadsheet-btn float-right"
                    onClick={() => {
                      this.setState({ isGlobal: !this.state.isGlobal });
                    }}
                  >
                    {this.state.isGlobal ? "My" : "Global"} Offers
                  </button>
                </div>
              </div> */}
            </div>
            {notTitleCompany && !isGlobal ? (
              <div className="offers-cards">
                <div className="row">
                  <div className="col-md-3 col-sm-6  col-6 offers-stats-card-container pl-0">
                    <div className="offers-stats-card">
                      <p>Total Offers</p>
                      <div className="offer-stats">
                        <h5>
                          {
                            totalOffers?.filter(
                              (k) => offers[k].status !== "Deleted"
                            ).length
                          }
                        </h5>
                        <p>
                          {
                            totalOffers?.filter((k) => offers[k]?.verified)
                              ?.length
                          }{" "}
                          verified (
                          {(
                            (totalOffers?.filter((k) => offers[k]?.verified)
                              ?.length *
                              100) /
                            totalOffers?.length
                          )?.toFixed(1) + "%"}
                          )
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-6 offers-stats-card-container">
                    <div className="offers-stats-card">
                      <p>Total Offers Accepted</p>
                      <div className="offer-stats">
                        <h5>
                          {
                            totalOffers?.filter((k) =>
                              ["Accepted", "Executed"].includes(
                                offers[k]?.status
                              )
                            )?.length
                          }
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-6 offers-stats-card-container">
                    <div className="offers-stats-card">
                      <p>Total Offers Not Accepted</p>
                      <div className="offer-stats">
                        <h5>
                          {
                            totalOffers?.filter((k) =>
                              ["Not Accepted"].includes(offers[k]?.status)
                            )?.length
                          }
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-6 offers-stats-card-container pr-0">
                    <div className="offers-stats-card">
                      <p>Total Offers Withdrawn</p>
                      <div className="offer-stats">
                        <h5>
                          {
                            totalOffers?.filter((k) =>
                              ["Withdraw"].includes(offers[k]?.status)
                            )?.length
                          }
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {/* <div className="container"> */}
            <div className="row">
              {notTitleCompany && !isGlobal && (
                <div className="col-md-4 pl-0 offers-desktop-filters">
                  <div className="offer-filters-container">
                    <button
                      className="offers-datepicker-btn"
                      onClick={() =>
                        this.setState({
                          showDatePicker: !this.state.showDatePicker,
                        })
                      }
                    >
                      <img src={require("../assets/icons/Calender.svg")} />
                      {!!filterDateRange.start && !!filterDateRange.end
                        ? moment(filterDateRange.start).format("l") +
                          " - " +
                          moment(filterDateRange.end).format("l")
                        : "Date Range"}
                    </button>
                    {this.state.showDatePicker && (
                      <DateRangePicker
                        onSelect={(e) => {
                          this.setState({
                            filterDateRange: e,
                            showDatePicker: !this.state.showDatePicker,
                          });
                          const dateRange = e;
                          this.filterOffers({ dateRange });
                        }}
                      />
                    )}

                    <div className="single-offer-filter">
                      <button
                        className="offer-filter-collapsable-btn"
                        onClick={() =>
                          this.setState({
                            showListingTypeFilter:
                              !this.state.showListingTypeFilter,
                          })
                        }
                      >
                        Offer
                        <img
                          style={{
                            transform: this.state.showListingTypeFilter
                              ? "rotate(0deg)"
                              : "rotate(180deg)",
                          }}
                          src={require("../assets/icons/ChevronUp.svg")}
                        />
                      </button>
                      {this.state.showListingTypeFilter && (
                        <div>
                          <div className="offer-filters-search-container">
                            <input
                              className="offer-filters-search"
                              placeholder="Search Offer Verification Status"
                              onChange={(e) => this.handleListingTypeFilter(e)}
                            />
                            <img src={require("../assets/icons/Search.svg")} />
                          </div>
                          {filteredListingTypeOptions.map((value, key) => {
                            return (
                              <div
                                className="custom-control custom-checkbox m-vertical-7"
                                key={key}
                              >
                                <input
                                  className="custom-control-input propertyFilterChkBox"
                                  type="checkbox"
                                  checked={listingTypeFilter.includes(value)}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    this.setState({
                                      listingTypeFilter: e.target.checked
                                        ? [...listingTypeFilter, e.target.value]
                                        : listingTypeFilter.filter(
                                            (val) => val !== e.target.value
                                          ),
                                    });
                                    const listingType = e.target.checked
                                      ? [...listingTypeFilter, e.target.value]
                                      : listingTypeFilter.filter(
                                          (val) => val !== e.target.value
                                        );
                                    this.filterOffers({
                                      listingType,
                                      offers: this.state.filteredOffers,
                                    });
                                  }}
                                  id={key + "listingtype"}
                                  name={value}
                                  value={value}
                                />
                                <label
                                  className="custom-control-label custom-offers-label"
                                  for={key + "listingtype"}
                                >
                                  {" "}
                                  {value}
                                </label>
                              </div>
                            );
                          })}
                          {!filteredListingTypeOptions.length && (
                            <p className="no-results">No Results Found</p>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="single-offer-filter">
                      <button
                        className="offer-filter-collapsable-btn"
                        onClick={() =>
                          this.setState({
                            showStatusFilter: !this.state.showStatusFilter,
                          })
                        }
                      >
                        Status
                        <img
                          style={{
                            transform: this.state.showStatusFilter
                              ? "rotate(0deg)"
                              : "rotate(180deg)",
                          }}
                          src={require("../assets/icons/ChevronUp.svg")}
                        />
                      </button>
                      {this.state.showStatusFilter && (
                        <div>
                          <div className="offer-filters-search-container">
                            <input
                              className="offer-filters-search"
                              placeholder="Search Status"
                              onChange={(e) => this.handleStatusFilter(e)}
                            />
                            <img src={require("../assets/icons/Search.svg")} />
                          </div>
                          {!!filteredStatusOptions &&
                          filteredStatusOptions.length > 5 &&
                          !this.state.showMoreStatusFilterOptions
                            ? filteredStatusOptions
                                .slice(0, 5)
                                .map((status, key) => {
                                  return (
                                    <div
                                      className="custom-control custom-checkbox m-vertical-7"
                                      key={key}
                                    >
                                      <input
                                        className="custom-control-input propertyFilterChkBox"
                                        type="checkbox"
                                        checked={offersStatusFilter.includes(
                                          status
                                        )}
                                        onClick={(e) => {
                                          this.setState({
                                            offersStatusFilter: e.target.checked
                                              ? [
                                                  ...offersStatusFilter,
                                                  e.target.value,
                                                ]
                                              : offersStatusFilter.filter(
                                                  (val) =>
                                                    val !== e.target.value
                                                ),
                                          });
                                          const status = e.target.checked
                                            ? [
                                                ...offersStatusFilter,
                                                e.target.value,
                                              ]
                                            : offersStatusFilter.filter(
                                                (val) => val !== e.target.value
                                              );
                                          this.filterOffers({
                                            status,
                                            offers: this.state.filteredOffers,
                                          });
                                        }}
                                        id={key + "_status"}
                                        name={status}
                                        value={status}
                                      />
                                      <label
                                        className="custom-control-label custom-offers-label"
                                        for={key + "_status"}
                                      >
                                        {" "}
                                        {status}
                                      </label>
                                    </div>
                                  );
                                })
                            : filteredStatusOptions.map((status, key) => {
                                return (
                                  <div
                                    className="custom-control custom-checkbox m-vertical-7"
                                    key={key}
                                  >
                                    <input
                                      className="custom-control-input propertyFilterChkBox"
                                      type="checkbox"
                                      checked={offersStatusFilter.includes(
                                        status
                                      )}
                                      onClick={(e) => {
                                        this.setState({
                                          offersStatusFilter: e.target.checked
                                            ? [
                                                ...offersStatusFilter,
                                                e.target.value,
                                              ]
                                            : offersStatusFilter.filter(
                                                (val) => val !== e.target.value
                                              ),
                                        });
                                        const status = e.target.checked
                                          ? [
                                              ...offersStatusFilter,
                                              e.target.value,
                                            ]
                                          : offersStatusFilter.filter(
                                              (val) => val !== e.target.value
                                            );
                                        this.filterOffers({
                                          status,
                                          offers: this.state.filteredOffers,
                                        });
                                      }}
                                      id={key + "_status"}
                                      name={status}
                                      value={status}
                                    />
                                    <label
                                      className="custom-control-label custom-offers-label"
                                      for={key + "_status"}
                                    >
                                      {" "}
                                      {status}
                                    </label>
                                  </div>
                                );
                              })}
                          {filteredStatusOptions.length > 5 && (
                            <button
                              className="offers-filter-show-more-btn"
                              onClick={() =>
                                this.setState({
                                  showMoreStatusFilterOptions:
                                    !this.state.showMoreStatusFilterOptions,
                                })
                              }
                            >
                              {this.state.showMoreStatusFilterOptions
                                ? "Show less"
                                : "Show more"}
                              <img
                                style={{
                                  transform: this.state
                                    .showMoreStatusFilterOptions
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)",
                                }}
                                src={require("../assets/icons/arrow-down-blue.svg")}
                              />
                            </button>
                          )}
                          {!filteredStatusOptions.length && (
                            <p className="no-results">No Results Found</p>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="single-offer-filter">
                      <button
                        className="offer-filter-collapsable-btn"
                        onClick={() =>
                          this.setState({
                            showBuyersAgentFilter:
                              !this.state.showBuyersAgentFilter,
                          })
                        }
                      >
                        Buyer’s Agent
                        <img
                          style={{
                            transform: this.state.showBuyersAgentFilter
                              ? "rotate(0deg)"
                              : "rotate(180deg)",
                          }}
                          src={require("../assets/icons/ChevronUp.svg")}
                        />
                      </button>
                      {this.state.showBuyersAgentFilter && (
                        <div>
                          <div className="offer-filters-search-container">
                            <input
                              className="offer-filters-search"
                              placeholder="Search Buyer’s Agent"
                              onChange={(e) => this.handleBuyerAgentFilter(e)}
                            />
                            <img src={require("../assets/icons/Search.svg")} />
                          </div>

                          {!!filteredBuyersAgentOptions &&
                          filteredBuyersAgentOptions.length > 5 &&
                          !this.state.showMoreBuyersAgentFilterOptions
                            ? filteredBuyersAgentOptions
                                .slice(0, 5)
                                .sort()
                                .map((value, key) => {
                                  return (
                                    <div
                                      className="custom-control custom-checkbox m-vertical-7"
                                      key={key}
                                    >
                                      <input
                                        className="custom-control-input propertyFilterChkBox"
                                        type="checkbox"
                                        checked={buyersAgentFilter.includes(
                                          value
                                        )}
                                        onClick={(e) => {
                                          this.setState({
                                            buyersAgentFilter: e.target.checked
                                              ? [
                                                  ...buyersAgentFilter,
                                                  e.target.value,
                                                ]
                                              : buyersAgentFilter.filter(
                                                  (val) =>
                                                    val !== e.target.value
                                                ),
                                          });
                                          const buyersAgent = e.target.checked
                                            ? [
                                                ...buyersAgentFilter,
                                                e.target.value,
                                              ]
                                            : buyersAgentFilter.filter(
                                                (val) => val !== e.target.value
                                              );
                                          this.filterOffers({
                                            buyersAgent,
                                            offers: this.state.filteredOffers,
                                          });
                                        }}
                                        id={key + "_buyer’sAgent"}
                                        name={value}
                                        value={value}
                                      />
                                      <label
                                        className="custom-control-label custom-offers-label"
                                        for={key + "_buyer’sAgent"}
                                      >
                                        {" "}
                                        {value}
                                      </label>
                                    </div>
                                  );
                                })
                            : filteredBuyersAgentOptions
                                .sort()
                                .map((value, key) => {
                                  return (
                                    <div
                                      className="custom-control custom-checkbox m-vertical-7"
                                      key={key}
                                    >
                                      <input
                                        className="custom-control-input propertyFilterChkBox"
                                        type="checkbox"
                                        checked={buyersAgentFilter.includes(
                                          value
                                        )}
                                        onClick={(e) => {
                                          this.setState({
                                            buyersAgentFilter: e.target.checked
                                              ? [
                                                  ...buyersAgentFilter,
                                                  e.target.value,
                                                ]
                                              : buyersAgentFilter.filter(
                                                  (val) =>
                                                    val !== e.target.value
                                                ),
                                          });
                                          const buyersAgent = e.target.checked
                                            ? [
                                                ...buyersAgentFilter,
                                                e.target.value,
                                              ]
                                            : buyersAgentFilter.filter(
                                                (val) => val !== e.target.value
                                              );
                                          this.filterOffers({
                                            buyersAgent,
                                            offers: this.state.filteredOffers,
                                          });
                                        }}
                                        id={key + "_buyer’sAgent"}
                                        name={value}
                                        value={value}
                                      />
                                      <label
                                        className="custom-control-label custom-offers-label"
                                        for={key + "_buyer’sAgent"}
                                      >
                                        {" "}
                                        {value}
                                      </label>
                                    </div>
                                  );
                                })}
                          {filteredBuyersAgentOptions.length > 5 && (
                            <button
                              className="offers-filter-show-more-btn"
                              onClick={() =>
                                this.setState({
                                  showMoreBuyersAgentFilterOptions:
                                    !this.state
                                      .showMoreBuyersAgentFilterOptions,
                                })
                              }
                            >
                              {this.state.showMoreBuyersAgentFilterOptions
                                ? "Show less"
                                : "Show more"}
                              <img
                                style={{
                                  transform: this.state
                                    .showMoreBuyersAgentFilterOptions
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)",
                                }}
                                src={require("../assets/icons/arrow-down-blue.svg")}
                              />
                            </button>
                          )}
                          {!filteredBuyersAgentOptions.length && (
                            <p className="no-results">No Results Found</p>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="single-offer-filter">
                      <button
                        className="offer-filter-collapsable-btn"
                        onClick={() =>
                          this.setState({
                            showPropertyAddressFilter:
                              !this.state.showPropertyAddressFilter,
                          })
                        }
                      >
                        Property Address
                        <img
                          style={{
                            transform: this.state.showPropertyAddressFilter
                              ? "rotate(0deg)"
                              : "rotate(180deg)",
                          }}
                          src={require("../assets/icons/ChevronUp.svg")}
                        />
                      </button>
                      {this.state.showPropertyAddressFilter && (
                        <div>
                          <div className="offer-filters-search-container">
                            <input
                              className="offer-filters-search"
                              placeholder="Search Property Address"
                              onChange={(e) =>
                                this.handlePropertyAddressFilter(e)
                              }
                            />
                            <img src={require("../assets/icons/Search.svg")} />
                          </div>
                          {!!filteredAddressOptions &&
                          filteredAddressOptions.length > 5 &&
                          !this.state.showMorePropertyAddressFilterOptions
                            ? filteredAddressOptions
                                .slice(0, 5)
                                .map((value, key) => {
                                  return (
                                    <div
                                      className="custom-control custom-checkbox m-vertical-7"
                                      key={key}
                                    >
                                      <input
                                        className="custom-control-input propertyFilterChkBox"
                                        type="checkbox"
                                        checked={propertyAddressFilter.includes(
                                          value
                                        )}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          this.setState({
                                            propertyAddressFilter: e.target
                                              .checked
                                              ? [
                                                  ...propertyAddressFilter,
                                                  e.target.value,
                                                ]
                                              : propertyAddressFilter.filter(
                                                  (val) =>
                                                    val !== e.target.value
                                                ),
                                          });
                                          const propertyAddress = e.target
                                            .checked
                                            ? [
                                                ...propertyAddressFilter,
                                                e.target.value,
                                              ]
                                            : propertyAddressFilter.filter(
                                                (val) => val !== e.target.value
                                              );
                                          this.filterOffers({
                                            propertyAddress,
                                            offers: this.state.filteredOffers,
                                          });
                                        }}
                                        id={key + "property_address"}
                                        name={value}
                                        value={value}
                                      />
                                      <label
                                        className="custom-control-label custom-offers-label"
                                        for={key + "property_address"}
                                      >
                                        {" "}
                                        {value}
                                      </label>
                                    </div>
                                  );
                                })
                            : filteredAddressOptions.map((value, key) => {
                                return (
                                  <div
                                    className="custom-control custom-checkbox m-vertical-7"
                                    key={key}
                                  >
                                    <input
                                      className="custom-control-input propertyFilterChkBox"
                                      type="checkbox"
                                      checked={propertyAddressFilter.includes(
                                        value
                                      )}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        this.setState({
                                          propertyAddressFilter: e.target
                                            .checked
                                            ? [
                                                ...propertyAddressFilter,
                                                e.target.value,
                                              ]
                                            : propertyAddressFilter.filter(
                                                (val) => val !== e.target.value
                                              ),
                                        });
                                        const propertyAddress = e.target.checked
                                          ? [
                                              ...propertyAddressFilter,
                                              e.target.value,
                                            ]
                                          : propertyAddressFilter.filter(
                                              (val) => val !== e.target.value
                                            );
                                        this.filterOffers({
                                          propertyAddress,
                                          offers: this.state.filteredOffers,
                                        });
                                      }}
                                      id={key + "property_address"}
                                      name={value}
                                      value={value}
                                    />
                                    <label
                                      className="custom-control-label custom-offers-label"
                                      for={key + "property_address"}
                                    >
                                      {" "}
                                      {value}
                                    </label>
                                  </div>
                                );
                              })}
                          {filteredAddressOptions.length > 5 && (
                            <button
                              className="offers-filter-show-more-btn"
                              onClick={() =>
                                this.setState({
                                  showMorePropertyAddressFilterOptions:
                                    !this.state
                                      .showMorePropertyAddressFilterOptions,
                                })
                              }
                            >
                              {this.state.showMorePropertyAddressFilterOptions
                                ? "Show less"
                                : "Show more"}
                              <img
                                style={{
                                  transform: this.state
                                    .showMorePropertyAddressFilterOptions
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)",
                                }}
                                src={require("../assets/icons/arrow-down-blue.svg")}
                              />
                            </button>
                          )}
                          {!filteredAddressOptions.length && (
                            <p className="no-results">No Results Found</p>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="single-offer-filter">
                      <button
                        className="offer-filter-collapsable-btn"
                        onClick={() =>
                          this.setState({
                            showBrokerageNameFilter:
                              !this.state.showBrokerageNameFilter,
                          })
                        }
                      >
                        Brokerage Name
                        <img
                          style={{
                            transform: this.state.showBrokerageNameFilter
                              ? "rotate(0deg)"
                              : "rotate(180deg)",
                          }}
                          src={require("../assets/icons/ChevronUp.svg")}
                        />
                      </button>
                      {this.state.showBrokerageNameFilter && (
                        <div>
                          <div className="offer-filters-search-container">
                            <input
                              className="offer-filters-search"
                              placeholder="Search Brokerage Name"
                              onChange={(e) =>
                                this.handleBrokerageNameFilter(e)
                              }
                            />
                            <img src={require("../assets/icons/Search.svg")} />
                          </div>
                          {!!filteredBrokerageNameOptions &&
                          filteredBrokerageNameOptions.length > 5 &&
                          !this.state.showMoreBrokerageNameFilterOptions
                            ? filteredBrokerageNameOptions
                                .slice(0, 5)
                                .sort()
                                .map((value, key) => {
                                  return (
                                    <div
                                      className="custom-control custom-checkbox m-vertical-7"
                                      key={key}
                                    >
                                      <input
                                        className="custom-control-input propertyFilterChkBox"
                                        type="checkbox"
                                        checked={brokerageNameFilter.includes(
                                          value
                                        )}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          const { search } = window.location;
                                          const params =
                                            queryString.parse(search);
                                          params["filterBrokerageName"] = value;
                                          window.history.pushState(
                                            null,
                                            "",
                                            window.location.pathname +
                                              "?" +
                                              queryString.stringify(params)
                                          );
                                          this.setState({
                                            brokerageNameFilter: e.target
                                              .checked
                                              ? [
                                                  ...brokerageNameFilter,
                                                  e.target.value,
                                                ]
                                              : brokerageNameFilter.filter(
                                                  (val) =>
                                                    val !== e.target.value
                                                ),
                                          });
                                          const brokerageName = e.target.checked
                                            ? [
                                                ...brokerageNameFilter,
                                                e.target.value,
                                              ]
                                            : brokerageNameFilter.filter(
                                                (val) => val !== e.target.value
                                              );
                                          this.filterOffers({
                                            brokerageName,
                                            offers: this.state.filteredOffers,
                                          });
                                        }}
                                        id={key + "_brokerageName"}
                                        name={value}
                                        value={value}
                                      />
                                      <label
                                        className="custom-control-label custom-offers-label"
                                        for={key + "_brokerageName"}
                                      >
                                        {" "}
                                        {value}
                                      </label>
                                    </div>
                                  );
                                })
                            : filteredBrokerageNameOptions
                                .sort()
                                .map((value, key) => {
                                  return (
                                    <div
                                      className="custom-control custom-checkbox m-vertical-7"
                                      key={key}
                                    >
                                      <input
                                        className="custom-control-input propertyFilterChkBox"
                                        type="checkbox"
                                        checked={brokerageNameFilter.includes(
                                          value
                                        )}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          const { search } = window.location;
                                          const params =
                                            queryString.parse(search);
                                          params["filterBrokerageName"] = value;
                                          window.history.pushState(
                                            null,
                                            "",
                                            window.location.pathname +
                                              "?" +
                                              queryString.stringify(params)
                                          );
                                          this.setState({
                                            brokerageNameFilter: e.target
                                              .checked
                                              ? [
                                                  ...brokerageNameFilter,
                                                  e.target.value,
                                                ]
                                              : brokerageNameFilter.filter(
                                                  (val) =>
                                                    val !== e.target.value
                                                ),
                                          });
                                          const brokerageName = e.target.checked
                                            ? [
                                                ...brokerageNameFilter,
                                                e.target.value,
                                              ]
                                            : brokerageNameFilter.filter(
                                                (val) => val !== e.target.value
                                              );
                                          this.filterOffers({
                                            brokerageName,
                                            offers: this.state.filteredOffers,
                                          });
                                        }}
                                        id={key + "_brokerageName"}
                                        name={value}
                                        value={value}
                                      />
                                      <label
                                        className="custom-control-label custom-offers-label"
                                        for={key + "_brokerageName"}
                                      >
                                        {" "}
                                        {value}
                                      </label>
                                    </div>
                                  );
                                })}
                          {filteredBrokerageNameOptions.length > 5 && (
                            <button
                              className="offers-filter-show-more-btn"
                              onClick={() =>
                                this.setState({
                                  showMoreBrokerageNameFilterOptions:
                                    !this.state
                                      .showMoreBrokerageNameFilterOptions,
                                })
                              }
                            >
                              {this.state.showMoreBrokerageNameFilterOptions
                                ? "Show less"
                                : "Show more"}
                              <img
                                style={{
                                  transform: this.state
                                    .showMoreBrokerageNameFilterOptions
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)",
                                }}
                                src={require("../assets/icons/arrow-down-blue.svg")}
                              />
                            </button>
                          )}
                          {!filteredBrokerageNameOptions.length && (
                            <p className="no-results">No Results Found</p>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="single-offer-filter">
                      <button
                        className="offer-filter-collapsable-btn"
                        onClick={() =>
                          this.setState({
                            showOfferTypeFilter:
                              !this.state.showOfferTypeFilter,
                          })
                        }
                      >
                        Offer Type
                        <img
                          style={{
                            transform: this.state.showOfferTypeFilter
                              ? "rotate(0deg)"
                              : "rotate(180deg)",
                          }}
                          src={require("../assets/icons/ChevronUp.svg")}
                        />
                      </button>
                      {this.state.showOfferTypeFilter && (
                        <div>
                          <div className="offer-filters-search-container">
                            <input
                              className="offer-filters-search"
                              placeholder="Search Offer Type"
                              onChange={(e) => this.handleOfferTypeFilter(e)}
                            />
                            <img src={require("../assets/icons/Search.svg")} />
                          </div>
                          {filteredOfferTypeOptions.map((value, key) => {
                            return (
                              <div
                                className="custom-control custom-checkbox m-vertical-7"
                                key={key}
                              >
                                <input
                                  className="custom-control-input propertyFilterChkBox"
                                  type="checkbox"
                                  checked={offerTypeFilter.includes(value)}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    this.setState({
                                      offerTypeFilter: e.target.checked
                                        ? [...offerTypeFilter, e.target.value]
                                        : offerTypeFilter.filter(
                                            (val) => val !== e.target.value
                                          ),
                                    });
                                    const offerType = e.target.checked
                                      ? [...offerTypeFilter, e.target.value]
                                      : offerTypeFilter.filter(
                                          (val) => val !== e.target.value
                                        );
                                    this.filterOffers({
                                      offerType,
                                      offers: this.state.filteredOffers,
                                    });
                                  }}
                                  id={key + "offertype"}
                                  name={value}
                                  value={value}
                                />
                                <label
                                  className="custom-control-label custom-offers-label"
                                  for={key + "offertype"}
                                >
                                  {" "}
                                  {value}
                                </label>
                              </div>
                            );
                          })}
                          {!filteredOfferTypeOptions.length && (
                            <p className="no-results">No Results Found</p>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="single-offer-filter">
                      <button
                        className="offer-filter-collapsable-btn"
                        onClick={() =>
                          this.setState({
                            showFinanceTypeFilter:
                              !this.state.showFinanceTypeFilter,
                          })
                        }
                      >
                        Finance Type
                        <img
                          style={{
                            transform: this.state.showFinanceTypeFilter
                              ? "rotate(0deg)"
                              : "rotate(180deg)",
                          }}
                          src={require("../assets/icons/ChevronUp.svg")}
                        />
                      </button>
                      {this.state.showFinanceTypeFilter && (
                        <div>
                          <div className="offer-filters-search-container">
                            <input
                              className="offer-filters-search"
                              placeholder="Search Finance Type"
                              onChange={(e) => this.handleFinanceTypeFilter(e)}
                            />
                            <img src={require("../assets/icons/Search.svg")} />
                          </div>
                          {!!filteredFinanceTypeOptions &&
                          filteredFinanceTypeOptions.length > 5 &&
                          !this.state.showMoreFinanceTypeFilterOptions
                            ? filteredFinanceTypeOptions
                                .slice(0, 5)
                                .sort()
                                .map((value, key) => {
                                  return (
                                    <div
                                      className="custom-control custom-checkbox m-vertical-7"
                                      key={key}
                                    >
                                      <input
                                        className="custom-control-input propertyFilterChkBox"
                                        type="checkbox"
                                        checked={financeTypeFilter.includes(
                                          value
                                        )}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          this.setState({
                                            financeTypeFilter: e.target.checked
                                              ? [
                                                  ...financeTypeFilter,
                                                  e.target.value,
                                                ]
                                              : financeTypeFilter.filter(
                                                  (val) =>
                                                    val !== e.target.value
                                                ),
                                          });

                                          const financeType = e.target.checked
                                            ? [
                                                ...financeTypeFilter,
                                                e.target.value,
                                              ]
                                            : financeTypeFilter.filter(
                                                (val) => val !== e.target.value
                                              );
                                          this.filterOffers({
                                            financeType,
                                            offers: this.state.filteredOffers,
                                          });
                                        }}
                                        id={key + "_financeType"}
                                        name={value}
                                        value={value}
                                      />
                                      <label
                                        className="custom-control-label custom-offers-label"
                                        for={key + "_financeType"}
                                      >
                                        {" "}
                                        {value}
                                      </label>
                                    </div>
                                  );
                                })
                            : filteredFinanceTypeOptions
                                .sort()
                                .map((value, key) => {
                                  return (
                                    <div
                                      className="custom-control custom-checkbox m-vertical-7"
                                      key={key}
                                    >
                                      <input
                                        className="custom-control-input propertyFilterChkBox"
                                        type="checkbox"
                                        checked={financeTypeFilter.includes(
                                          value
                                        )}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          this.setState({
                                            financeTypeFilter: e.target.checked
                                              ? [
                                                  ...financeTypeFilter,
                                                  e.target.value,
                                                ]
                                              : financeTypeFilter.filter(
                                                  (val) =>
                                                    val !== e.target.value
                                                ),
                                          });
                                          const financeType = e.target.checked
                                            ? [
                                                ...financeTypeFilter,
                                                e.target.value,
                                              ]
                                            : financeTypeFilter.filter(
                                                (val) => val !== e.target.value
                                              );
                                          this.filterOffers({
                                            financeType,
                                            offers: this.state.filteredOffers,
                                          });
                                        }}
                                        id={key + "_financeType"}
                                        name={value}
                                        value={value}
                                      />
                                      <label
                                        className="custom-control-label custom-offers-label"
                                        for={key + "_financeType"}
                                      >
                                        {" "}
                                        {value}
                                      </label>
                                    </div>
                                  );
                                })}
                          {filteredFinanceTypeOptions.length > 5 && (
                            <button
                              className="offers-filter-show-more-btn"
                              onClick={() =>
                                this.setState({
                                  showMoreFinanceTypeFilterOptions:
                                    !this.state
                                      .showMoreFinanceTypeFilterOptions,
                                })
                              }
                            >
                              {this.state.showMoreFinanceTypeFilterOptions
                                ? "Show less"
                                : "Show more"}
                              <img
                                style={{
                                  transform: this.state
                                    .showMoreFinanceTypeFilterOptions
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)",
                                }}
                                src={require("../assets/icons/arrow-down-blue.svg")}
                              />
                            </button>
                          )}
                          {!filteredFinanceTypeOptions.length && (
                            <p className="no-results">No Results Found</p>
                          )}
                        </div>
                      )}
                    </div>

                    <button
                      className="clear-all-offer-filters-btn"
                      onClick={() => {
                        this.setState({
                          offersStatusFilter: [],
                          buyersAgentFilter: [],
                          propertyAddressFilter: [],
                          brokerageNameFilter: [],
                          offerTypeFilter: [],
                          financeTypeFilter: [],
                          listingTypeFilter: [],
                          filterDateRange: { start: null, end: null },
                        });
                        this.filterOffers({
                          offersStatus: [],
                          buyersAgent: [],
                          propertyAddress: [],
                          brokerageName: [],
                          offerType: [],
                          financeType: [],
                          listingType: [],
                          dateRange: { start: null, end: null },
                          offers: this.state.filteredOffers,
                        });
                      }}
                    >
                      Clear All Filters
                    </button>
                  </div>
                </div>
              )}
              {notTitleCompany && !isGlobal && (
                <button
                  className="offers-filters-modal-btn"
                  onClick={this.offerFiltersToggle}
                >
                  <img src={require("../assets/icons/blue-funnel.svg")} />
                  Filters
                </button>
              )}
              <div
                className={`col-md-${
                  notTitleCompany && !isGlobal ? "8" : "12"
                } col-sm-12 pr-0 offers-new-mobile-list`}
              >
                <div className="applied-filters-area">
                  {!!filterDateRange.end && !!filterDateRange.start && (
                    <div className="single-applied-filter">
                      <p>Date Range</p>
                      <img
                        src={require("../assets/icons/blue-cross-icon.svg")}
                        onClick={() => {
                          this.setState({
                            filterDateRange: { start: null, end: null },
                          });
                          this.filterOffers({
                            dateRange: { start: null, end: null },
                            offers: this.state.filteredOffers,
                          });
                        }}
                      />
                    </div>
                  )}
                  {!!offersStatusFilter.length &&
                    offersStatusFilter.map((e, key) => (
                      <div key={key} className="single-applied-filter">
                        <p>Status: {e}</p>
                        <img
                          src={require("../assets/icons/blue-cross-icon.svg")}
                          onClick={() => {
                            this.setState({
                              offersStatusFilter: offersStatusFilter.filter(
                                (val) => val !== e
                              ),
                            });
                            this.filterOffers({
                              status: offersStatusFilter.filter(
                                (val) => val !== e
                              ),
                              offers: this.state.filteredOffers,
                            });
                          }}
                        />
                      </div>
                    ))}
                  {!!listingTypeFilter.length &&
                    listingTypeFilter.map((e, key) => (
                      <div key={key} className="single-applied-filter">
                        <p>{e} Offers</p>
                        <img
                          src={require("../assets/icons/blue-cross-icon.svg")}
                          onClick={() => {
                            this.setState({
                              listingTypeFilter: listingTypeFilter.filter(
                                (val) => val !== e
                              ),
                            });
                            this.filterOffers({
                              listingType: listingTypeFilter.filter(
                                (val) => val !== e
                              ),
                              offers: this.state.filteredOffers,
                            });
                          }}
                        />
                      </div>
                    ))}
                  {!!buyersAgentFilter.length &&
                    buyersAgentFilter.map((e, key) => (
                      <div key={key} className="single-applied-filter">
                        <p>{e}</p>
                        <img
                          src={require("../assets/icons/blue-cross-icon.svg")}
                          onClick={() => {
                            this.setState({
                              buyersAgentFilter: buyersAgentFilter.filter(
                                (val) => val !== e
                              ),
                            });
                            let buyersAgent = buyersAgentFilter.filter(
                              (val) => val !== e
                            );
                            this.filterOffers({
                              buyersAgent,
                              offers: this.state.filteredOffers,
                            });
                          }}
                        />
                      </div>
                    ))}
                  {!!propertyAddressFilter.length &&
                    propertyAddressFilter.map((e, key) => (
                      <div key={key} className="single-applied-filter">
                        <p>{e}</p>
                        <img
                          src={require("../assets/icons/blue-cross-icon.svg")}
                          onClick={() => {
                            this.setState({
                              propertyAddressFilter:
                                propertyAddressFilter.filter(
                                  (val) => val !== e
                                ),
                            });
                            this.filterOffers({
                              propertyAddress: propertyAddressFilter.filter(
                                (val) => val !== e
                              ),
                              offers: this.state.filteredOffers,
                            });
                          }}
                        />
                      </div>
                    ))}
                  {!!brokerageNameFilter.length &&
                    brokerageNameFilter.map((e, key) => (
                      <div key={key} className="single-applied-filter">
                        <p>{e}</p>
                        <img
                          src={require("../assets/icons/blue-cross-icon.svg")}
                          onClick={() => {
                            this.setState({
                              brokerageNameFilter: brokerageNameFilter.filter(
                                (val) => val !== e
                              ),
                            });
                            this.filterOffers({
                              brokerageName: brokerageNameFilter.filter(
                                (val) => val !== e
                              ),
                              offers: this.state.filteredOffers,
                            });
                          }}
                        />
                      </div>
                    ))}
                  {!!offerTypeFilter.length &&
                    offerTypeFilter.map((e, key) => (
                      <div key={key} className="single-applied-filter">
                        <p>{e}</p>
                        <img
                          src={require("../assets/icons/blue-cross-icon.svg")}
                          onClick={() => {
                            this.setState({
                              offerTypeFilter: offerTypeFilter.filter(
                                (val) => val !== e
                              ),
                            });
                            this.filterOffers({
                              offerType: offerTypeFilter.filter(
                                (val) => val !== e
                              ),
                              offers: this.state.filteredOffers,
                            });
                          }}
                        />
                      </div>
                    ))}
                  {!!financeTypeFilter.length &&
                    financeTypeFilter.map((e, key) => (
                      <div key={key} className="single-applied-filter">
                        <p>{e}</p>
                        <img
                          src={require("../assets/icons/blue-cross-icon.svg")}
                          onClick={() => {
                            this.setState({
                              financeTypeFilter: financeTypeFilter.filter(
                                (val) => val !== e
                              ),
                            });
                            this.filterOffers({
                              financeType: financeTypeFilter.filter(
                                (val) => val !== e
                              ),
                              offers: this.state.filteredOffers,
                            });
                          }}
                        />
                      </div>
                    ))}
                </div>
                {this.renderOffers("receivedOffers")}
                {Object.keys(this.state.filteredOffers).length ||
                (!notTitleCompany &&
                  isGlobal &&
                  this.props.user.recievedOffers.length) ? (
                  <div className="text-center">
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.totalPages}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={1}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            {/* </div> */}
            {/* <div className="container"> */}
            <div className="row">
              <div className="col-lg-8 col-12 centerly">
                <Modal
                  isOpen={this.state.chartModal}
                  size="lg"
                  fade={false}
                  toggle={this.chartToggle}
                  className="offers-modal"
                >
                  <ModalHeader
                    className="modal-header"
                    toggle={this.chartToggle}
                  ></ModalHeader>
                  <ModalBody className="modal-body">
                    <Container>
                      <Row>
                        <Col>
                          <div className="row-space-between">
                            {/* <h5 className="modal-title">Chart Filters</h5> */}
                            {/* <button className="modal-chart-btn">
                              <img src={Bars} className="pr-10" />List view
                            </button> */}
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-30">
                        <Col>
                          <br />
                          <div className="d-inline-block mb-2">
                            <span className="period-filter-label">
                              Finance Type:
                            </span>
                            <div className="d-inline-block">
                              <select
                                name="offerStatusFilter"
                                value={selectedFinanceType}
                                onChange={(e) => {
                                  this.updateGraph(e.target.value);
                                  this.setState({
                                    selectedFinanceType: e.target.value,
                                  });
                                }}
                                className="search-fields offers-search-fields mr-0 mr-md-4"
                              >
                                {offerFinanceTypeFilter.map((m, i) => (
                                  <option key={i} value={m}>
                                    {m}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="highchart-container">
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={options}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </ModalBody>
                </Modal>

                <Modal
                  isOpen={this.state.spreadSheetModal}
                  size="lg"
                  fade={false}
                  toggle={this.spreadSheetToggle}
                  className="offers-modal"
                >
                  <ModalHeader
                    className="modal-header"
                    toggle={this.spreadSheetToggle}
                  ></ModalHeader>
                  <ModalBody className="modal-body">
                    <Container className="mb-40">
                      <Row>
                        <Col>
                          <h5 className="modal-title text-center">
                            Drag Spreadsheet Attributes To Right
                          </h5>
                        </Col>
                      </Row>
                      <Row className="mt-20">
                        <Col>
                          <h6 className="text-center">Fields</h6>
                        </Col>
                        <Col>
                          <h6 className="text-center">Export</h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="drag-and-drop-container">
                            <DragDropContext onDragEnd={this.onDragEnd}>
                              <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    style={getList1Style(
                                      snapshot.isDraggingOver
                                    )}
                                  >
                                    {this.state.spreadsheetOptions.map(
                                      (item, index) => (
                                        <Draggable
                                          key={item.key}
                                          draggableId={item.key}
                                          index={index}
                                        >
                                          {(provided, snapshot) => (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                              )}
                                            >
                                              {item.name}
                                            </div>
                                          )}
                                        </Draggable>
                                      )
                                    )}
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                              <Droppable droppableId="droppable2">
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    style={getList2Style(
                                      snapshot.isDraggingOver
                                    )}
                                  >
                                    {this.state.spreadsheetSelected.map(
                                      (item, index) => (
                                        <Draggable
                                          key={item.key}
                                          draggableId={item.key}
                                          index={index}
                                        >
                                          {(provided, snapshot) => (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                              )}
                                            >
                                              {item.name}
                                            </div>
                                          )}
                                        </Draggable>
                                      )
                                    )}
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </DragDropContext>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="flex-center flex-wrap">
                            <button
                              className="cancel-btn"
                              onClick={this.spreadSheetToggle}
                            >
                              Cancel
                            </button>
                            <button
                              className="gnerate-file"
                              onClick={this.exportDataToXLSX}
                            >
                              Generate XLSX
                            </button>
                            <button
                              className="gnerate-file"
                              onClick={() =>
                                this.setState({
                                  shareCSVModal: true,
                                })
                              }
                            >
                              Share XLSX
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </ModalBody>
                </Modal>
              </div>
            </div>
            {/* </div> */}
            {/* </div> */}
            <Modal
              isOpen={this.state.offerFiltersModal}
              size="sm"
              fade={false}
              toggle={this.offerFiltersToggle}
              className="mobile-filters-modal"
            >
              <ModalBody className="modal-body">
                <div className="mobile-filters-modal-header">
                  <h6>Filters</h6>
                  <img
                    src={require("../assets/icons/Close.svg")}
                    onClick={() =>
                      this.setState({
                        offerFiltersModal: !this.state.offerFiltersModal,
                      })
                    }
                  />
                </div>
                <div className="offer-filters-container modal-offer-filters">
                  <button
                    className="offers-datepicker-btn"
                    onClick={() =>
                      this.setState({
                        showDatePicker: !this.state.showDatePicker,
                      })
                    }
                  >
                    <img src={require("../assets/icons/Calender.svg")} />
                    {!!filterDateRange.start && !!filterDateRange.end
                      ? moment(filterDateRange.start).format("l") +
                        " - " +
                        moment(filterDateRange.end).format("l")
                      : "Date Range"}
                  </button>
                  {this.state.showDatePicker && (
                    <DateRangePicker
                      onSelect={(e) => {
                        this.setState({
                          filterDateRange: e,
                          showDatePicker: !this.state.showDatePicker,
                        });
                        this.filterOffers({
                          dateRange: e,
                          offers: this.state.offers,
                        });
                      }}
                    />
                  )}

                  <div className="single-offer-filter modal-body-filters">
                    <button
                      className="offer-filter-collapsable-btn"
                      onClick={() =>
                        this.setState({
                          showListingTypeFilter:
                            !this.state.showListingTypeFilter,
                        })
                      }
                    >
                      Offer
                      <img
                        style={{
                          transform: this.state.showListingTypeFilter
                            ? "rotate(0deg)"
                            : "rotate(180deg)",
                        }}
                        src={require("../assets/icons/ChevronUp.svg")}
                      />
                    </button>
                    {this.state.showListingTypeFilter && (
                      <div>
                        <div className="offer-filters-search-container">
                          <input
                            className="offer-filters-search"
                            placeholder="Search Offer Verification Status"
                            onChange={(e) => this.handleListingTypeFilter(e)}
                          />
                          <img src={require("../assets/icons/Search.svg")} />
                        </div>
                        {filteredListingTypeOptions.map((value, key) => {
                          return (
                            <div
                              className="custom-control custom-checkbox m-vertical-7"
                              key={key}
                            >
                              <input
                                className="custom-control-input propertyFilterChkBox"
                                type="checkbox"
                                checked={listingTypeFilter.includes(value)}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.setState({
                                    listingTypeFilter: e.target.checked
                                      ? [...listingTypeFilter, e.target.value]
                                      : listingTypeFilter.filter(
                                          (val) => val !== e.target.value
                                        ),
                                  });
                                  this.filterOffers({
                                    listingType: e.target.checked
                                      ? [...listingTypeFilter, e.target.value]
                                      : listingTypeFilter.filter(
                                          (val) => val !== e.target.value
                                        ),
                                    offers: this.state.offers,
                                  });
                                }}
                                id={key + "listingtype"}
                                name={value}
                                value={value}
                              />
                              <label
                                className="custom-control-label custom-offers-label"
                                for={key + "listingtype"}
                              >
                                {" "}
                                {value}
                              </label>
                            </div>
                          );
                        })}
                        {!filteredListingTypeOptions.length && (
                          <p className="no-results">No Results Found</p>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="single-offer-filter modal-body-filters">
                    <button
                      className="offer-filter-collapsable-btn"
                      onClick={() =>
                        this.setState({
                          showStatusFilter: !this.state.showStatusFilter,
                        })
                      }
                    >
                      Status
                      <img
                        style={{
                          transform: this.state.showStatusFilter
                            ? "rotate(0deg)"
                            : "rotate(180deg)",
                        }}
                        src={require("../assets/icons/ChevronUp.svg")}
                      />
                    </button>
                    {this.state.showStatusFilter && (
                      <div>
                        <div className="offer-filters-search-container">
                          <input
                            className="offer-filters-search"
                            placeholder="Search Status"
                            onChange={(e) => this.handleStatusFilter(e)}
                          />
                          <img src={require("../assets/icons/Search.svg")} />
                        </div>
                        {!!filteredStatusOptions &&
                        filteredStatusOptions.length > 5 &&
                        !this.state.showMoreStatusFilterOptions
                          ? filteredStatusOptions
                              .slice(0, 5)
                              .map((status, key) => {
                                return (
                                  <div
                                    className="custom-control custom-checkbox m-vertical-7"
                                    key={key}
                                  >
                                    <input
                                      className="custom-control-input propertyFilterChkBox"
                                      type="checkbox"
                                      checked={offersStatusFilter.includes(
                                        status
                                      )}
                                      onClick={(e) => {
                                        this.setState({
                                          offersStatusFilter: e.target.checked
                                            ? [
                                                ...offersStatusFilter,
                                                e.target.value,
                                              ]
                                            : offersStatusFilter.filter(
                                                (val) => val !== e.target.value
                                              ),
                                        });
                                        this.filterOffers({
                                          status: e.target.checked
                                            ? [
                                                ...offersStatusFilter,
                                                e.target.value,
                                              ]
                                            : offersStatusFilter.filter(
                                                (val) => val !== e.target.value
                                              ),
                                          offers: this.state.offers,
                                        });
                                      }}
                                      id={key + "_status"}
                                      name={status}
                                      value={status}
                                    />
                                    <label
                                      className="custom-control-label custom-offers-label"
                                      for={key + "_status"}
                                    >
                                      {" "}
                                      {status}
                                    </label>
                                  </div>
                                );
                              })
                          : filteredStatusOptions.map((status, key) => {
                              return (
                                <div
                                  className="custom-control custom-checkbox m-vertical-7"
                                  key={key}
                                >
                                  <input
                                    className="custom-control-input propertyFilterChkBox"
                                    type="checkbox"
                                    checked={offersStatusFilter.includes(
                                      status
                                    )}
                                    onClick={(e) => {
                                      this.setState({
                                        offersStatusFilter: e.target.checked
                                          ? [
                                              ...offersStatusFilter,
                                              e.target.value,
                                            ]
                                          : offersStatusFilter.filter(
                                              (val) => val !== e.target.value
                                            ),
                                      });
                                      this.filterOffers({
                                        status: e.target.checked
                                          ? [
                                              ...offersStatusFilter,
                                              e.target.value,
                                            ]
                                          : offersStatusFilter.filter(
                                              (val) => val !== e.target.value
                                            ),
                                        offers: this.state.offers,
                                      });
                                    }}
                                    id={key + "_status"}
                                    name={status}
                                    value={status}
                                  />
                                  <label
                                    className="custom-control-label custom-offers-label"
                                    for={key + "_status"}
                                  >
                                    {" "}
                                    {status}
                                  </label>
                                </div>
                              );
                            })}
                        {filteredStatusOptions.length > 5 && (
                          <button
                            className="offers-filter-show-more-btn"
                            onClick={() =>
                              this.setState({
                                showMoreStatusFilterOptions:
                                  !this.state.showMoreStatusFilterOptions,
                              })
                            }
                          >
                            {this.state.showMoreStatusFilterOptions
                              ? "Show less"
                              : "Show more"}
                            <img
                              style={{
                                transform: this.state
                                  .showMoreStatusFilterOptions
                                  ? "rotate(180deg)"
                                  : "rotate(0deg)",
                              }}
                              src={require("../assets/icons/arrow-down-blue.svg")}
                            />
                          </button>
                        )}
                        {!filteredStatusOptions.length && (
                          <p className="no-results">No Results Found</p>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="single-offer-filter modal-body-filters">
                    <button
                      className="offer-filter-collapsable-btn"
                      onClick={() =>
                        this.setState({
                          showBuyersAgentFilter:
                            !this.state.showBuyersAgentFilter,
                        })
                      }
                    >
                      Buyer’s Agent
                      <img
                        style={{
                          transform: this.state.showBuyersAgentFilter
                            ? "rotate(0deg)"
                            : "rotate(180deg)",
                        }}
                        src={require("../assets/icons/ChevronUp.svg")}
                      />
                    </button>
                    {this.state.showBuyersAgentFilter && (
                      <div>
                        <div className="offer-filters-search-container">
                          <input
                            className="offer-filters-search"
                            placeholder="Search Buyer’s Agent"
                            onChange={(e) => this.handleBuyerAgentFilter(e)}
                          />
                          <img src={require("../assets/icons/Search.svg")} />
                        </div>

                        {!!filteredBuyersAgentOptions &&
                        filteredBuyersAgentOptions.length > 5 &&
                        !this.state.showMoreBuyersAgentFilterOptions
                          ? filteredBuyersAgentOptions
                              .slice(0, 5)
                              .sort()
                              .map((value, key) => {
                                return (
                                  <div
                                    className="custom-control custom-checkbox m-vertical-7"
                                    key={key}
                                  >
                                    <input
                                      className="custom-control-input propertyFilterChkBox"
                                      type="checkbox"
                                      checked={buyersAgentFilter.includes(
                                        value
                                      )}
                                      onClick={(e) => {
                                        this.setState({
                                          buyersAgentFilter: e.target.checked
                                            ? [
                                                ...buyersAgentFilter,
                                                e.target.value,
                                              ]
                                            : buyersAgentFilter.filter(
                                                (val) => val !== e.target.value
                                              ),
                                        });
                                        this.filterOffers({
                                          buyersAgent: e.target.checked
                                            ? [
                                                ...buyersAgentFilter,
                                                e.target.value,
                                              ]
                                            : buyersAgentFilter.filter(
                                                (val) => val !== e.target.value
                                              ),
                                          offers: this.state.offers,
                                        });
                                      }}
                                      id={key + "_buyer’sAgent"}
                                      name={value}
                                      value={value}
                                    />
                                    <label
                                      className="custom-control-label custom-offers-label"
                                      for={key + "_buyer’sAgent"}
                                    >
                                      {" "}
                                      {value}
                                    </label>
                                  </div>
                                );
                              })
                          : filteredBuyersAgentOptions
                              .sort()
                              .map((value, key) => {
                                return (
                                  <div
                                    className="custom-control custom-checkbox m-vertical-7"
                                    key={key}
                                  >
                                    <input
                                      className="custom-control-input propertyFilterChkBox"
                                      type="checkbox"
                                      checked={buyersAgentFilter.includes(
                                        value
                                      )}
                                      onClick={(e) => {
                                        this.setState({
                                          buyersAgentFilter: e.target.checked
                                            ? [
                                                ...buyersAgentFilter,
                                                e.target.value,
                                              ]
                                            : buyersAgentFilter.filter(
                                                (val) => val !== e.target.value
                                              ),
                                        });
                                        this.filterOffers({
                                          buyersAgent: e.target.checked
                                            ? [
                                                ...buyersAgentFilter,
                                                e.target.value,
                                              ]
                                            : buyersAgentFilter.filter(
                                                (val) => val !== e.target.value
                                              ),
                                          offers: this.state.offers,
                                        });
                                      }}
                                      id={key + "_buyer’sAgent"}
                                      name={value}
                                      value={value}
                                    />
                                    <label
                                      className="custom-control-label custom-offers-label"
                                      for={key + "_buyer’sAgent"}
                                    >
                                      {" "}
                                      {value}
                                    </label>
                                  </div>
                                );
                              })}
                        {filteredBuyersAgentOptions.length > 5 && (
                          <button
                            className="offers-filter-show-more-btn"
                            onClick={() =>
                              this.setState({
                                showMoreBuyersAgentFilterOptions:
                                  !this.state.showMoreBuyersAgentFilterOptions,
                              })
                            }
                          >
                            {this.state.showMoreBuyersAgentFilterOptions
                              ? "Show less"
                              : "Show more"}
                            <img
                              style={{
                                transform: this.state
                                  .showMoreBuyersAgentFilterOptions
                                  ? "rotate(180deg)"
                                  : "rotate(0deg)",
                              }}
                              src={require("../assets/icons/arrow-down-blue.svg")}
                            />
                          </button>
                        )}
                        {!filteredBuyersAgentOptions.length && (
                          <p className="no-results">No Results Found</p>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="single-offer-filter modal-body-filters">
                    <button
                      className="offer-filter-collapsable-btn"
                      onClick={() =>
                        this.setState({
                          showPropertyAddressFilter:
                            !this.state.showPropertyAddressFilter,
                        })
                      }
                    >
                      Property Address
                      <img
                        style={{
                          transform: this.state.showPropertyAddressFilter
                            ? "rotate(0deg)"
                            : "rotate(180deg)",
                        }}
                        src={require("../assets/icons/ChevronUp.svg")}
                      />
                    </button>
                    {this.state.showPropertyAddressFilter && (
                      <div>
                        <div className="offer-filters-search-container">
                          <input
                            className="offer-filters-search"
                            placeholder="Search Property Address"
                            onChange={(e) =>
                              this.handlePropertyAddressFilter(e)
                            }
                          />
                          <img src={require("../assets/icons/Search.svg")} />
                        </div>
                        {!!filteredAddressOptions &&
                        filteredAddressOptions.length > 5 &&
                        !this.state.showMorePropertyAddressFilterOptions
                          ? filteredAddressOptions
                              .slice(0, 5)
                              .map((value, key) => {
                                return (
                                  <div
                                    className="custom-control custom-checkbox m-vertical-7"
                                    key={key}
                                  >
                                    <input
                                      className="custom-control-input propertyFilterChkBox"
                                      type="checkbox"
                                      checked={propertyAddressFilter.includes(
                                        value
                                      )}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        this.setState({
                                          propertyAddressFilter: e.target
                                            .checked
                                            ? [
                                                ...propertyAddressFilter,
                                                e.target.value,
                                              ]
                                            : propertyAddressFilter.filter(
                                                (val) => val !== e.target.value
                                              ),
                                        });
                                        this.filterOffers({
                                          propertyAddress: e.target.checked
                                            ? [
                                                ...propertyAddressFilter,
                                                e.target.value,
                                              ]
                                            : propertyAddressFilter.filter(
                                                (val) => val !== e.target.value
                                              ),
                                          offers: this.state.offers,
                                        });
                                      }}
                                      id={key + "property_address"}
                                      name={value}
                                      value={value}
                                    />
                                    <label
                                      className="custom-control-label custom-offers-label"
                                      for={key + "property_address"}
                                    >
                                      {" "}
                                      {value}
                                    </label>
                                  </div>
                                );
                              })
                          : filteredAddressOptions.map((value, key) => {
                              return (
                                <div
                                  className="custom-control custom-checkbox m-vertical-7"
                                  key={key}
                                >
                                  <input
                                    className="custom-control-input propertyFilterChkBox"
                                    type="checkbox"
                                    checked={propertyAddressFilter.includes(
                                      value
                                    )}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      this.setState({
                                        propertyAddressFilter: e.target.checked
                                          ? [
                                              ...propertyAddressFilter,
                                              e.target.value,
                                            ]
                                          : propertyAddressFilter.filter(
                                              (val) => val !== e.target.value
                                            ),
                                      });
                                      this.filterOffers({
                                        propertyAddress: e.target.checked
                                          ? [
                                              ...propertyAddressFilter,
                                              e.target.value,
                                            ]
                                          : propertyAddressFilter.filter(
                                              (val) => val !== e.target.value
                                            ),
                                        offers: this.state.offers,
                                      });
                                    }}
                                    id={key + "property_address"}
                                    name={value}
                                    value={value}
                                  />
                                  <label
                                    className="custom-control-label custom-offers-label"
                                    for={key + "property_address"}
                                  >
                                    {" "}
                                    {value}
                                  </label>
                                </div>
                              );
                            })}
                        {filteredAddressOptions.length > 5 && (
                          <button
                            className="offers-filter-show-more-btn"
                            onClick={() =>
                              this.setState({
                                showMorePropertyAddressFilterOptions:
                                  !this.state
                                    .showMorePropertyAddressFilterOptions,
                              })
                            }
                          >
                            {this.state.showMorePropertyAddressFilterOptions
                              ? "Show less"
                              : "Show more"}
                            <img
                              style={{
                                transform: this.state
                                  .showMorePropertyAddressFilterOptions
                                  ? "rotate(180deg)"
                                  : "rotate(0deg)",
                              }}
                              src={require("../assets/icons/arrow-down-blue.svg")}
                            />
                          </button>
                        )}
                        {!filteredAddressOptions.length && (
                          <p className="no-results">No Results Found</p>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="single-offer-filter modal-body-filters">
                    <button
                      className="offer-filter-collapsable-btn"
                      onClick={() =>
                        this.setState({
                          showBrokerageNameFilter:
                            !this.state.showBrokerageNameFilter,
                        })
                      }
                    >
                      Brokerage Name
                      <img
                        style={{
                          transform: this.state.showBrokerageNameFilter
                            ? "rotate(0deg)"
                            : "rotate(180deg)",
                        }}
                        src={require("../assets/icons/ChevronUp.svg")}
                      />
                    </button>
                    {this.state.showBrokerageNameFilter && (
                      <div>
                        <div className="offer-filters-search-container">
                          <input
                            className="offer-filters-search"
                            placeholder="Search Brokerage Name"
                            onChange={(e) => this.handleBrokerageNameFilter(e)}
                          />
                          <img src={require("../assets/icons/Search.svg")} />
                        </div>
                        {!!filteredBrokerageNameOptions &&
                        filteredBrokerageNameOptions.length > 5 &&
                        !this.state.showMoreBrokerageNameFilterOptions
                          ? filteredBrokerageNameOptions
                              .slice(0, 5)
                              .sort()
                              .map((value, key) => {
                                return (
                                  <div
                                    className="custom-control custom-checkbox m-vertical-7"
                                    key={key}
                                  >
                                    <input
                                      className="custom-control-input propertyFilterChkBox"
                                      type="checkbox"
                                      checked={brokerageNameFilter.includes(
                                        value
                                      )}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        const { search } = window.location;
                                        const params =
                                          queryString.parse(search);
                                        params["filterBrokerageName"] = value;
                                        window.history.pushState(
                                          null,
                                          "",
                                          window.location.pathname +
                                            "?" +
                                            queryString.stringify(params)
                                        );
                                        this.setState({
                                          brokerageNameFilter: e.target.checked
                                            ? [
                                                ...brokerageNameFilter,
                                                e.target.value,
                                              ]
                                            : brokerageNameFilter.filter(
                                                (val) => val !== e.target.value
                                              ),
                                        });
                                        this.filterOffers({
                                          brokerageName: e.target.checked
                                            ? [
                                                ...brokerageNameFilter,
                                                e.target.value,
                                              ]
                                            : brokerageNameFilter.filter(
                                                (val) => val !== e.target.value
                                              ),
                                          offers: this.state.offers,
                                        });
                                      }}
                                      id={key + "_brokerageName"}
                                      name={value}
                                      value={value}
                                    />
                                    <label
                                      className="custom-control-label custom-offers-label"
                                      for={key + "_brokerageName"}
                                    >
                                      {" "}
                                      {value}
                                    </label>
                                  </div>
                                );
                              })
                          : filteredBrokerageNameOptions
                              .sort()
                              .map((value, key) => {
                                return (
                                  <div
                                    className="custom-control custom-checkbox m-vertical-7"
                                    key={key}
                                  >
                                    <input
                                      className="custom-control-input propertyFilterChkBox"
                                      type="checkbox"
                                      checked={brokerageNameFilter.includes(
                                        value
                                      )}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        const { search } = window.location;
                                        const params =
                                          queryString.parse(search);
                                        params["filterBrokerageName"] = value;
                                        window.history.pushState(
                                          null,
                                          "",
                                          window.location.pathname +
                                            "?" +
                                            queryString.stringify(params)
                                        );
                                        this.setState({
                                          brokerageNameFilter: e.target.checked
                                            ? [
                                                ...brokerageNameFilter,
                                                e.target.value,
                                              ]
                                            : brokerageNameFilter.filter(
                                                (val) => val !== e.target.value
                                              ),
                                        });
                                        this.filterOffers({
                                          brokerageName: e.target.checked
                                            ? [
                                                ...brokerageNameFilter,
                                                e.target.value,
                                              ]
                                            : brokerageNameFilter.filter(
                                                (val) => val !== e.target.value
                                              ),
                                          offers: this.state.offers,
                                        });
                                      }}
                                      id={key + "_brokerageName"}
                                      name={value}
                                      value={value}
                                    />
                                    <label
                                      className="custom-control-label custom-offers-label"
                                      for={key + "_brokerageName"}
                                    >
                                      {" "}
                                      {value}
                                    </label>
                                  </div>
                                );
                              })}
                        {filteredBrokerageNameOptions.length > 5 && (
                          <button
                            className="offers-filter-show-more-btn"
                            onClick={() =>
                              this.setState({
                                showMoreBrokerageNameFilterOptions:
                                  !this.state
                                    .showMoreBrokerageNameFilterOptions,
                              })
                            }
                          >
                            {this.state.showMoreBrokerageNameFilterOptions
                              ? "Show less"
                              : "Show more"}
                            <img
                              style={{
                                transform: this.state
                                  .showMoreBrokerageNameFilterOptions
                                  ? "rotate(180deg)"
                                  : "rotate(0deg)",
                              }}
                              src={require("../assets/icons/arrow-down-blue.svg")}
                            />
                          </button>
                        )}
                        {!filteredBrokerageNameOptions.length && (
                          <p className="no-results">No Results Found</p>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="single-offer-filter modal-body-filters">
                    <button
                      className="offer-filter-collapsable-btn"
                      onClick={() =>
                        this.setState({
                          showOfferTypeFilter: !this.state.showOfferTypeFilter,
                        })
                      }
                    >
                      Offer Type
                      <img
                        style={{
                          transform: this.state.showOfferTypeFilter
                            ? "rotate(0deg)"
                            : "rotate(180deg)",
                        }}
                        src={require("../assets/icons/ChevronUp.svg")}
                      />
                    </button>
                    {this.state.showOfferTypeFilter && (
                      <div>
                        <div className="offer-filters-search-container">
                          <input
                            className="offer-filters-search"
                            placeholder="Search Offer Type"
                            onChange={(e) => this.handleOfferTypeFilter(e)}
                          />
                          <img src={require("../assets/icons/Search.svg")} />
                        </div>
                        {filteredOfferTypeOptions.map((value, key) => {
                          return (
                            <div
                              className="custom-control custom-checkbox m-vertical-7"
                              key={key}
                            >
                              <input
                                className="custom-control-input propertyFilterChkBox"
                                type="checkbox"
                                checked={offerTypeFilter.includes(value)}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.setState({
                                    offerTypeFilter: e.target.checked
                                      ? [...offerTypeFilter, e.target.value]
                                      : offerTypeFilter.filter(
                                          (val) => val !== e.target.value
                                        ),
                                  });
                                  this.filterOffers({
                                    offerType: e.target.checked
                                      ? [...offerTypeFilter, e.target.value]
                                      : offerTypeFilter.filter(
                                          (val) => val !== e.target.value
                                        ),
                                    offers: this.state.offers,
                                  });
                                }}
                                id={key + "offertype"}
                                name={value}
                                value={value}
                              />
                              <label
                                className="custom-control-label custom-offers-label"
                                for={key + "offertype"}
                              >
                                {" "}
                                {value}
                              </label>
                            </div>
                          );
                        })}
                        {!filteredOfferTypeOptions.length && (
                          <p className="no-results">No Results Found</p>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="modal-body-filters">
                    <button
                      className="offer-filter-collapsable-btn"
                      onClick={() =>
                        this.setState({
                          showFinanceTypeFilter:
                            !this.state.showFinanceTypeFilter,
                        })
                      }
                    >
                      Finance Type
                      <img
                        style={{
                          transform: this.state.showFinanceTypeFilter
                            ? "rotate(0deg)"
                            : "rotate(180deg)",
                        }}
                        src={require("../assets/icons/ChevronUp.svg")}
                      />
                    </button>
                    {this.state.showFinanceTypeFilter && (
                      <div>
                        <div className="offer-filters-search-container">
                          <input
                            className="offer-filters-search"
                            placeholder="Search Finance Type"
                            onChange={(e) => this.handleFinanceTypeFilter(e)}
                          />
                          <img src={require("../assets/icons/Search.svg")} />
                        </div>
                        {!!filteredFinanceTypeOptions &&
                        filteredFinanceTypeOptions.length > 5 &&
                        !this.state.showMoreFinanceTypeFilterOptions
                          ? filteredFinanceTypeOptions
                              .slice(0, 5)
                              .sort()
                              .map((value, key) => {
                                return (
                                  <div
                                    className="custom-control custom-checkbox m-vertical-7"
                                    key={key}
                                  >
                                    <input
                                      className="custom-control-input propertyFilterChkBox"
                                      type="checkbox"
                                      checked={financeTypeFilter.includes(
                                        value
                                      )}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        this.setState({
                                          financeTypeFilter: e.target.checked
                                            ? [
                                                ...financeTypeFilter,
                                                e.target.value,
                                              ]
                                            : financeTypeFilter.filter(
                                                (val) => val !== e.target.value
                                              ),
                                        });
                                        this.filterOffers({
                                          financeType: e.target.checked
                                            ? [
                                                ...financeTypeFilter,
                                                e.target.value,
                                              ]
                                            : financeTypeFilter.filter(
                                                (val) => val !== e.target.value
                                              ),
                                          offers: this.state.offers,
                                        });
                                      }}
                                      id={key + "_financeType"}
                                      name={value}
                                      value={value}
                                    />
                                    <label
                                      className="custom-control-label custom-offers-label"
                                      for={key + "_financeType"}
                                    >
                                      {" "}
                                      {value}
                                    </label>
                                  </div>
                                );
                              })
                          : filteredFinanceTypeOptions
                              .sort()
                              .map((value, key) => {
                                return (
                                  <div
                                    className="custom-control custom-checkbox m-vertical-7"
                                    key={key}
                                  >
                                    <input
                                      className="custom-control-input propertyFilterChkBox"
                                      type="checkbox"
                                      checked={financeTypeFilter.includes(
                                        value
                                      )}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        this.setState({
                                          financeTypeFilter: e.target.checked
                                            ? [
                                                ...financeTypeFilter,
                                                e.target.value,
                                              ]
                                            : financeTypeFilter.filter(
                                                (val) => val !== e.target.value
                                              ),
                                        });
                                        this.filterOffers({
                                          financeType: e.target.checked
                                            ? [
                                                ...financeTypeFilter,
                                                e.target.value,
                                              ]
                                            : financeTypeFilter.filter(
                                                (val) => val !== e.target.value
                                              ),
                                          offers: this.state.offers,
                                        });
                                      }}
                                      id={key + "_financeType"}
                                      name={value}
                                      value={value}
                                    />
                                    <label
                                      className="custom-control-label custom-offers-label"
                                      for={key + "_financeType"}
                                    >
                                      {" "}
                                      {value}
                                    </label>
                                  </div>
                                );
                              })}
                        {filteredFinanceTypeOptions.length > 5 && (
                          <button
                            className="offers-filter-show-more-btn"
                            onClick={() =>
                              this.setState({
                                showMoreFinanceTypeFilterOptions:
                                  !this.state.showMoreFinanceTypeFilterOptions,
                              })
                            }
                          >
                            {this.state.showMoreFinanceTypeFilterOptions
                              ? "Show less"
                              : "Show more"}
                            <img
                              style={{
                                transform: this.state
                                  .showMoreFinanceTypeFilterOptions
                                  ? "rotate(180deg)"
                                  : "rotate(0deg)",
                              }}
                              src={require("../assets/icons/arrow-down-blue.svg")}
                            />
                          </button>
                        )}
                        {!filteredFinanceTypeOptions.length && (
                          <p className="no-results">No Results Found</p>
                        )}
                      </div>
                    )}
                  </div>

                  <button
                    className="clear-all-offer-filters-btn"
                    onClick={() => {
                      this.setState({
                        offersStatusFilter: [],
                        buyersAgentFilter: [],
                        propertyAddressFilter: [],
                        brokerageNameFilter: [],
                        offerTypeFilter: [],
                        financeTypeFilter: [],
                        listingTypeFilter: [],
                        filterDateRange: { start: null, end: null },
                      });
                      this.filterOffers({
                        offersStatus: [],
                        buyersAgent: [],
                        propertyAddress: [],
                        brokerageName: [],
                        offerType: [],
                        financeType: [],
                        listingType: [],
                        offers: this.state.offers,
                        dateRange: { start: null, end: null },
                      });
                    }}
                  >
                    Clear All Filters
                  </button>
                </div>

                <div className="mobile-filters-modal-footer">
                  <button
                    className="filter-modal-cancel-btn"
                    onClick={this.offerFiltersToggle}
                  >
                    Cancel
                  </button>
                  <button
                    className="filter-modal-apply-btn"
                    onClick={() =>
                      this.setState({
                        offerFiltersModal: !this.state.offerFiltersModal,
                      })
                    }
                  >
                    Apply Filters
                  </button>
                </div>
              </ModalBody>
            </Modal>

            <Modal
              isOpen={this.state.shareCSVModal}
              size="md"
              fade={false}
              toggle={() =>
                this.setState({
                  shareCSVModal: !this.state.shareCSVModal,
                })
              }
            >
              <ModalHeader
                className="modal-header"
                toggle={() =>
                  this.setState({
                    shareCSVModal: !this.state.shareCSVModal,
                  })
                }
              ></ModalHeader>
              <ModalBody className="modal-body">
                <Container>
                  <Row>
                    <Col>
                      <div className="form-content-box content-box-signupCheck">
                        <div className="details">
                          <form>
                            <div className="form-group">
                              <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                className="input-text"
                                onChange={(e) =>
                                  this.setState({
                                    emailToShare: e.target.value,
                                  })
                                }
                                value={this.state.emailToShare}
                              />
                            </div>
                            <p className="text-danger">
                              {this.state.registerError}
                            </p>
                            <div className="form-group mb-0">
                              <button
                                type="button"
                                className="btn-md button-theme btn-block mb-20"
                                onClick={this.shareXLSXViaEMail}
                              >
                                {this.state.sharingCSV ? "Sending..." : "Send"}
                              </button>
                              <a
                                onClick={() =>
                                  this.setState({
                                    shareCSVModal: false,
                                  })
                                }
                              >
                                No
                              </a>
                            </div>
                          </form>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
            </Modal>

            <Modal
              isOpen={this.state.smsBlast}
              size="md"
              fade={false}
              toggle={() =>
                this.setState({
                  smsBlast: !this.state.smsBlast,
                })
              }
            >
              <ModalHeader
                className="modal-header"
                toggle={() =>
                  this.setState({
                    smsBlast: !this.state.smsBlast,
                  })
                }
              ></ModalHeader>
              <ModalBody className="modal-body">
                <Container>
                  <Row>
                    <Col>
                      <div className="form-content-box content-box-signupCheck">
                        <div className="details">
                          <h3>Select listings to send</h3>
                          <p>
                            Audience:{" "}
                            <b>
                              {groupAudience == 0 && !selectedGroups.length
                                ? smsBlastAudience
                                : groupAudience}
                            </b>
                          </p>
                          {smsBlastListings.map((listing) => {
                            const seller = sellers.find(
                              (seller) => seller.id === listing
                            );
                            let numbers = Object.keys(receivedOffers)?.map(
                              (key) =>
                                receivedOffers[key]?.phoneNumber
                                  ?.match(/\d/g)
                                  ?.join("")
                            );
                            numbers = [...new Set(numbers)];

                            return;
                            {
                              /* <p className="text-left"><b>SMS Text: </b>{`You made an offer on one or more of ${user.realtor_name} with Ink Realty Group listings. They have a new listing you may be interested in at ${seller.UnparsedAddress}.`}<br />{`Details: `}<a href={`https://homematchx.com/sellers/${seller.ListingKey}`}>https://homematchx.com/sellers/{seller.ListingKey}</a></p> */
                            }
                          })}

                          <form>
                            {sellers.map((seller, i) => {
                              if (seller.MlsStatus.toLowerCase() !== "active")
                                return null;

                              return (
                                <div key={i} className="label-sms-blast">
                                  <label>
                                    <input
                                      type="checkbox"
                                      onChange={() => {
                                        let updatesSellers = [];
                                        if (
                                          smsBlastListings.includes(seller.id)
                                        )
                                          updatesSellers =
                                            smsBlastListings.filter(
                                              (id) => id !== seller.id
                                            );
                                        else
                                          updatesSellers = [
                                            ...smsBlastListings,
                                            seller.id,
                                          ];

                                        this.setState({
                                          smsBlastListings: updatesSellers,
                                        });
                                      }}
                                    />

                                    <span>
                                      {seller.UnparsedAddress ||
                                        ` ${seller.street_number} ${seller.street_name},  ${seller.state}, ${seller.zip_code}`}{" "}
                                    </span>
                                  </label>
                                </div>
                              );
                            })}

                            <FormGroup className="form-group pt-14">
                              <Label for="groups" className="label-font">
                                Select Group
                              </Label>
                              <Select
                                id="groups"
                                name="groups"
                                isMulti // Enable multi-select
                                value={this.state.selectedGroupsOption}
                                options={groupOptions}
                                onChange={this.handleGroupChange}
                              />
                              <Label for="templates" className="label-font">
                                Select Text Message Template
                              </Label>
                              <Select
                                isMulti={false}
                                name="templates"
                                options={smsTemplates}
                                onChange={this.handleTemplateChange}
                                value={{
                                  label: this.state.smsTxt,
                                  value: this.state.smsTxt
                                    .toLowerCase()
                                    .replace(" ", "_"),
                                }}
                              />

                              {this.state.smsTxt.endsWith("%deadline%.") && (
                                <>
                                  <Label for="deadline" className="label-font">
                                    Select Deadline
                                  </Label>
                                  <Input
                                    type="datetime-local"
                                    name="deadline"
                                    value={this.state.deadline}
                                    onChange={(e) =>
                                      this.setState({
                                        deadline: e.target.value,
                                      })
                                    }
                                  />
                                </>
                              )}

                              <Label for="comments" className="label-font">
                                Type Message
                              </Label>
                              <Input
                                className="textarea-follow-listing"
                                type="textarea"
                                name="comments"
                                value={this.state.smsTxt}
                                onChange={(e) =>
                                  this.setState({ smsTxt: e.target.value })
                                }
                                placeholder="Message"
                              />
                            </FormGroup>

                            <p className="text-danger">
                              {this.state.smsBlastError}
                            </p>
                            <div className="form-group mb-0">
                              <button
                                type="button"
                                className="btn-md button-theme btn-block mb-20"
                                onClick={this.sendSMSToAll}
                              >
                                {this.state.sendingSMS ? "Sending..." : "Send"}
                              </button>
                              <a
                                onClick={() =>
                                  this.setState({
                                    smsBlast: false,
                                  })
                                }
                              >
                                No
                              </a>
                            </div>
                          </form>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
            </Modal>
            {/* Bulk Share Modal */}
            <Modal
              isOpen={shareOffersModel}
              toggle={() =>
                this.setState({
                  shareOffersModel: !shareOffersModel,
                })
              }
              className="modal-danger"
            >
              <ModalHeader>Confirmation</ModalHeader>
              <ModalBody>
                <Label className="my-3">Select Listings</Label>
                <div className="m-0 p-0 share-offers-modal-container mb-4">
                  {this.state.addressFilterOptions.map((value, key) => {
                    return (
                      <div
                        className="custom-control custom-checkbox m-vertical-7"
                        key={key}
                      >
                        <input
                          className="custom-control-input propertyFilterChkBox"
                          type="checkbox"
                          checked={filteredListingsShare.includes(value)}
                          onClick={(e) => {
                            e.stopPropagation();
                            this.setState({
                              filteredListingsShare: e.target.checked
                                ? [...filteredListingsShare, e.target.value]
                                : filteredListingsShare.filter(
                                    (val) => val !== e.target.value
                                  ),
                              offersToShare: this.state.offers.filter((offer) =>
                                e.target.checked
                                  ? [
                                      ...filteredListingsShare,
                                      e.target.value,
                                    ].includes(offer.address)
                                  : filteredListingsShare
                                      .filter((val) => val !== e.target.value)
                                      .includes(offer.address)
                              ),
                            });
                          }}
                          id={key + "property_address_share"}
                          name={value}
                          value={value}
                        />
                        <label
                          className="custom-control-label custom-offers-label m-0"
                          for={key + "property_address_share"}
                        >
                          {" "}
                          {value}
                        </label>
                      </div>
                    );
                  })}

                  {!this.state.addressFilterOptions.length && (
                    <p className="no-results">No Results Found</p>
                  )}
                </div>
                {/* <Label className="my-3">Name</Label> */}
                <Input
                  type="text"
                  name="name"
                  className="input mb-4"
                  placeholder="Name"
                  onChange={(e) =>
                    this.setState({ shareOfferName: e.target.value })
                  }
                />

                {/* <Label className="my-3">Email</Label> */}
                <Input
                  type="text"
                  name="email"
                  className="input mb-4"
                  placeholder="Email address"
                  onChange={(e) =>
                    this.setState({ shareOfferEmail: e.target.value })
                  }
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  onClick={() =>
                    this.setState({
                      shareOffersModel: !shareOffersModel,
                    })
                  }
                >
                  Cancel
                </Button>{" "}
                <Button
                  color="success"
                  disabled={sendingOffers}
                  onClick={this.handleShareOffers}
                >
                  {sendingOffers ? "Sending..." : "Send"}
                </Button>
              </ModalFooter>
            </Modal>

            <Modal
              isOpen={this.state.aiPoweredModal}
              fade={false}
              toggle={this.toggleAiPoweredModal}
            >
              <ModalBody>
                <div className="ai-modal-container">
                  <div className="ai-modal-left">
                    <h1 class="gradient-heading">Your Smart Offer Scoring Assistant</h1>
                    {/* <img className="star-banner" src={require("../assets/icons/stars-banner.png")}/> */}
                    <p>
                    Leverage real-time insights and precision scoring with HomematchX AI to craft competitive offers and close deals faster. Start optimizing your offers now!
                    </p>
                    {this.state.showAiScore && (
                      <div className="weighted-score-toggle">
                        <Label className="d-block">
                          Show Weighted Score % Diff:
                        </Label>
                        <Switch
                          checked={this.state.showWeightedScorePercentage}
                          onChange={async (checked) => {
                            this.setState({ aiPoweredModalLoading: true });
                            const { user } = this.props;
                            const db = firebase.firestore();
                            const userRef = db.collection("users").doc(user.uid);
                            console.log(user.uid, "user.uid")
                            try {
                              await userRef.update({ showWeightedScorePercentage: checked });
                              this.setState({
                                showWeightedScorePercentage: checked,
                                aiPoweredModalLoading: false,
                              });
                              toast.success(
                                `Weighted Score Percentage ${
                                  checked ? "enabled" : "disabled"
                                } successfully!`
                              );
                            } catch (error) {
                              console.error("Error updating user data:", error);
                              this.setState({ aiPoweredModalLoading: false });
                              toast.error("Failed to update the setting. Please try again.");
                            }
                          }}
                          onColor="#28a745"
                          offColor="#ff214f"
                          checkedIcon={false}
                          uncheckedIcon={false}
                          height={22}
                          width={44}
                        />
                      </div>
                    )}
                    {this.state.showAiScore && (
                      <div className="weighted-score-toggle">
                        <Label className="d-block">Sort Offers (Highest to Lowest Weighted Scores):</Label>
                        <Switch
                          checked={this.state.aiSortingEnabled}
                          onChange={async (checked) => {
                            this.setState({ aiPoweredModalLoading: true });
                            const { user } = this.props;
                            const db = firebase.firestore();
                            const userRef = db.collection("users").doc(user.uid);
                            try {
                              await userRef.update({ aiSortingEnabled: checked });
                              this.setState({
                                aiSortingEnabled: checked,
                                aiPoweredModalLoading: false,
                              });
                              toast.success(
                                `Offer Sorting ${
                                  checked ? "enabled" : "disabled"
                                } successfully!`
                              );
                            } catch (error) {
                              console.error("Error updating user data:", error);
                              this.setState({ aiPoweredModalLoading: false });
                              toast.error("Failed to update the setting. Please try again.");
                            }
                          }}
                          onColor="#28a745"
                          offColor="#ff214f"
                          checkedIcon={false}
                          uncheckedIcon={false}
                          height={22}
                          width={44}
                        />
                      </div>
                    )}
                    <div className="ai-modal-footer">
                      <Button
                        className="ai-modal-close-btn"
                        color="secondary"
                        onClick={this.toggleAiPoweredModal}
                      >
                        Close
                      </Button>
                      <Button
                        className="homematchx-ai-button"
                        onClick={async () => {
                          this.setState({ aiPoweredModalLoading: true });
                          const { user } = this.props;
                          const db = firebase.firestore();
                          const userRef = db.collection("users").doc(user.uid);
                      
                          try {
                            const updatedState = !this.state.showAiScore;
                            await userRef.update({ showAiScore: updatedState, showWeightedScorePercentage: false });
                            this.setState({
                              showAiScore: updatedState,
                              aiPoweredModal: false,
                              aiPoweredModalLoading: false,
                              showWeightedScorePercentage: false,
                              aiSortingEnabled: false
                            });
                          } catch (error) {
                            console.error("Error updating user data:", error);
                            this.setState({
                              aiPoweredModalLoading: false
                            });
                            alert("Failed to activate Homematchx AI. Please try again.");
                          }
                        }}
                      >
                        <img 
                          className="mr-2 ai-icon"
                          src={require("../assets/icons/ai-new.png")}
                        />
                          {this.state.aiPoweredModalLoading 
                          ? "Please wait..." 
                          : this.state.showAiScore 
                            ? "Turn Off OfferAIde" 
                            : "Use Homematchx AI"}
                      </Button>
                    </div>
                  </div>
                </div>
              </ModalBody>
            </Modal>
            <ScoringModal
              showModal={this.state.showLearnScoringModal}
              hideModal={this.toggleLearnScoringModal}
            />
          </div>
        )}
      </>
    );
  }
}

export default Offers;
