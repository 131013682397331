import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import { toast } from "react-toastify";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import { Doughnut } from "react-chartjs-2";

const SellerNetSheetModal = (props) => {
  const { showModal, hideModal, offer } = props;

  const [activeTab, setActiveTab] = useState(offer?.net_a_seller_estimates?.length > 0 ? 2 : 1);
  const [isEditingNetAtClose, setIsEditingNetAtClose] = useState(false);
  const [customNetAtClose, setCustomNetAtClose] = useState("");
  const [calculatedData, setCalculatedData] = useState(null); 
  const [saveEstimateModal, setSaveEstimateModal] = useState(false);
  const [showResults, setShowResults] = useState(offer?.net_a_seller_estimates?.length > 0);
  const [showClosingCosts, setShowClosingCosts] = useState(false);
  const [estimateName, setEstimateName] = useState("");
  const [savingEstimate, setSavingEstimate] = useState(false);
  const [taxType, setTaxType] = useState("percent");
  const twoDaysLater = new Date();
  const [selectedEstimate, setSelectedEstimate] = useState(null);
  twoDaysLater.setDate(twoDaysLater.getDate() + 30);
  const formattedDate = twoDaysLater.toISOString().split("T")[0];
  
  const [formData, setFormData] = useState({
    calcType: "Conventional",
    price: "",
    sellerBF_value: "3",
    buyerBF_value: "3",
    BF_sellerbuyer_Split: "Seller",
    SellerConcession_seller: "0",
    repairs: "",
    miscCosts: "",
    taxPercent: "2.59",
    taxAmountAnnual: "",
    loanBalance: "0",
    interestRate: "4.25",
    closingDate: formattedDate,
    serviceContract: "0",
    miscellaneous: "0",
  });

  useEffect(() => {
    if (offer) {
      setFormData((prevData) => ({
        ...prevData,
        calcType: offer.financingType || "Conventional",
        price: offer.purchasePrice || "",
        BF_sellerbuyer_Split: offer.titlePolicyExpense || "Seller",
        closingDate: offer.closingDate || formattedDate,
      }));
    }
  }, [offer]);

  const toggleClosingCosts = () => {
    setShowClosingCosts(!showClosingCosts);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    const rawValue = value.replace(/,/g, "");
  
    setFormData({ ...formData, [name]: rawValue });
  };

  const handleSaveEstimate = async () => {
    if (!estimateName.trim()) {
      toast.error("Please enter an Estimate Name.");
      return;
    }
  
    if (!offer || !offer.id) {
      toast.error("Offer ID is missing. Cannot save estimate.");
      return;
    }
  
    setSavingEstimate(true);
  
    try {
      const db = firebase.firestore();
      const estimateData = {
        estimateName,
        ...formData,
        createdAt: Math.floor(Date.now() / 1000),
      };
      console.log(estimateData, "estimateData", "-2-2-2-2--2-2-2-2-2-2--2-2-2-2-2-2-2--2-2-2-2-2-")
      await db.collection("offers").doc(offer.id).update({
        net_a_seller_estimates: firebase.firestore.FieldValue.arrayUnion(estimateData),
      });
  
      toast.success("Estimate saved successfully!");
      toggleSaveEstimateModal();
    } catch (error) {
      console.error("Error saving estimate:", error);
      toast.error("Failed to save estimate. Please try again.");
    } finally {
      setSavingEstimate(false);
    }
  };
  

  const data = {
    datasets: [
      {
        data: [
          calculatedData?.netAtClose || 0,
          calculatedData?.closingCosts || 0,
          calculatedData?.propertyTaxes || 0
        ],
        backgroundColor: ["#3366FF", "#FFA500", "#6D4C41"],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    legend: {
      display: false
    },
    cutoutPercentage: 85,
    animation: {
      animationRotate: true,
      duration: 2000,
    },
    tooltips: {
      enabled: false,
    },
    plugins: {
      tooltip: { enabled: false },
      legend: { display: false },
    },
  };

  const switchTab = (tab) => {
    setActiveTab(tab);
  };
  
  const handleEditClick = () => {
    setCustomNetAtClose(calculatedData?.netAtClose?.toFixed(2) || "0.00");
    setIsEditingNetAtClose(true);
  };

  const handleNetAtCloseChange = (e) => {
    setCustomNetAtClose(e.target.value);
  };

  const handleNetAtCloseKeyPress = (e) => {
    if (e.key === "Enter") {
      setIsEditingNetAtClose(false);
  
      let desiredNetAtClose = parseFloat(e.target.value) || 0;
      console.log(desiredNetAtClose, "desiredNetAtClose");
  
      if (desiredNetAtClose <= 0) {
        alert("Net at Close must be a positive value.");
        return;
      }
  
      // Extract necessary values from formData
      const sellerBF = parseFloat(formData.sellerBF_value) / 100;
      const buyerBF = parseFloat(formData.buyerBF_value) / 100;
      const sellerConcession = parseFloat(formData.SellerConcession_seller) || 0;
      const loanBalance = parseFloat(formData.loanBalance) || 0;
      const serviceContract = parseFloat(formData.serviceContract) || 0;
      const miscellaneous = parseFloat(formData.miscellaneous) || 0;
      const taxRate = parseFloat(formData.taxPercent);
      const specialLoanFee = formData.calcType === "VA" ? 65 : formData.calcType === "Cash" ? 55 : 0;
  
      // Initial estimated Sales Price (start with the desired netAtClose)
      let estimatedSalesPrice = desiredNetAtClose;
      let titleInsurance = 0;
      let closingCosts = 0;
      let proratedTaxes = 0;
  
      // Iteratively adjust sales price to match the desired Net At Close
      for (let i = 0; i < 10; i++) { 
        // Calculate title insurance based on the estimated sales price
        titleInsurance = calculateTitleInsurance(estimatedSalesPrice);
  
        // Calculate prorated taxes
        proratedTaxes = calculateProratedTaxes(estimatedSalesPrice, taxRate, formData.closingDate, formData.taxAmountAnnual);
  
        // Calculate total closing costs
        closingCosts = 
          titleInsurance +
          525 + // Escrow Fee
          150 + 100 + // Attorney Fees
          65 + // Tax Certificate
          (estimatedSalesPrice * sellerBF) +
          (estimatedSalesPrice * buyerBF) +
          serviceContract +
          miscellaneous +
          specialLoanFee;
  
        // Adjust estimated Sales Price to match the entered Net at Close
        estimatedSalesPrice = desiredNetAtClose + closingCosts + sellerConcession + loanBalance + proratedTaxes;
      }
  
      console.log(`Final Estimated Sales Price: $${estimatedSalesPrice.toFixed(2)}`);
      console.log(`Final Closing Costs: $${closingCosts.toFixed(2)}`);
      console.log(`Final Prorated Taxes: $${proratedTaxes.toFixed(2)}`);
  
      // Update state with new calculated values
      setFormData((prev) => ({
        ...prev,
        price: estimatedSalesPrice.toFixed(2),
      }));
  
      // Trigger calculation with new sales price
      handleCalculateClick(estimatedSalesPrice);
    }
  };  
  
  const handleCalculateClick = (manualSalesPrice = null) => {
    let salesPrice = 
    typeof manualSalesPrice === "number" && !isNaN(manualSalesPrice)
      ? manualSalesPrice
      : parseFloat(formData.price);
    console.log(salesPrice, "salesPrice111111111111111111111")
    if (isNaN(salesPrice) || salesPrice === 0) {
      alert("Sales Price cannot be zero. Please enter a valid amount.");
      return;
    }
    let specialLoanFee = 0;
    let specialLoanFeeLabel = "";

    if (formData.calcType === "VA") {
        specialLoanFee = 65;
        specialLoanFeeLabel = "Termite Fee (VA Loan)";
    } else if (formData.calcType === "Cash") {
        specialLoanFee = 55;
        specialLoanFeeLabel = "Maintenance Certificate (Cash Loan)";
    }


    console.log(salesPrice, "salesPrice", manualSalesPrice, "================", formData.price)

    console.log(manualSalesPrice, "===========================================111111")
    const titleInsurance = calculateTitleInsurance(salesPrice);
    const escrowFee = 525;
    const attorneyFees = 150 + 100;
    const taxCertificate = 65;
  
    const titleFees = titleInsurance + escrowFee + attorneyFees + taxCertificate;
  
    const sellerBF = (salesPrice * parseFloat(formData.sellerBF_value)) / 100;
    const buyerBF = (salesPrice * parseFloat(formData.buyerBF_value)) / 100;
    const taxRate = parseFloat(formData.taxPercent);
    const proratedTaxes = calculateProratedTaxes(salesPrice, taxRate, formData.closingDate, formData.taxAmountAnnual);
    const otherFees =
      sellerBF +
      buyerBF +
      parseFloat(formData.serviceContract) +
      parseFloat(formData.miscellaneous) +
      specialLoanFee;
  
    const closingCosts = titleFees + otherFees;
    // let netAtClose = salesPrice - (closingCosts + parseFloat(formData.SellerConcession_seller) + parseFloat(formData.loanBalance));
    let netAtClose = salesPrice - (closingCosts + parseFloat(formData.SellerConcession_seller) + parseFloat(formData.loanBalance) + proratedTaxes);

    console.log(netAtClose, "net------a-------close")

    setCalculatedData({ 
      netAtClose,
      closingCosts, 
      propertyTaxes: proratedTaxes,
      titleFees, 
      otherFees, 
      sellerBF, 
      buyerBF,
      titleInsurance,
      specialLoanFee,
      specialLoanFeeLabel
    });
    setCustomNetAtClose(netAtClose.toFixed());
    setShowResults(true);
  }; 

  // const calculateProratedTaxes = (salesPrice, taxRate, closingDate, taxAmountAnnual) => {
  //   if (!closingDate) return 0;

  //   const closingDateObj = new Date(closingDate);
  //   const yearStart = new Date(closingDateObj.getFullYear(), 0, 1); // January 1st

  //   // ✅ Determine Days Owed (Seller's Responsibility)
  //   const daysOwed = Math.floor((closingDateObj - yearStart) / (1000 * 60 * 60 * 24));

  //   let annualPropertyTax = 0;

  //   // ✅ Use taxAmountAnnual if provided, otherwise calculate using taxRate
  //   if (taxAmountAnnual && parseFloat(taxAmountAnnual) > 0) {
  //       annualPropertyTax = parseFloat(taxAmountAnnual);
  //   } else if (taxRate && parseFloat(taxRate) > 0) {
  //       annualPropertyTax = salesPrice * (parseFloat(taxRate) / 100);
  //   } else {
  //       return 0; // If neither taxAmountAnnual nor taxRate is provided, return 0
  //   }

  //   // ✅ Compute Prorated Taxes (Fixes rounding issues)
  //   const proratedTaxes = ((annualPropertyTax / 365) * daysOwed).toFixed(2);

  //   console.log(`Annual Property Tax: $${annualPropertyTax}`);
  //   console.log(`Days Seller Owes: ${daysOwed}`);
  //   console.log(`Prorated Taxes Owed: $${proratedTaxes}`);

  //   return parseFloat(proratedTaxes); // Return as a number
  // };


  const calculateProratedTaxes = (salesPrice, taxRate, closingDate, taxAmountAnnual) => {
    if (!closingDate) return 0;

    const closingDateObj = new Date(closingDate);
    const yearStart = new Date(closingDateObj.getFullYear(), 0, 1); // January 1st

    // ✅ Determine if it's a leap year (to correctly handle February)
    const isLeapYear = (closingDateObj.getFullYear() % 4 === 0 && closingDateObj.getFullYear() % 100 !== 0) || (closingDateObj.getFullYear() % 400 === 0);
    const febDays = isLeapYear ? 29 : 28; // Set February days accordingly

    // ✅ Manually count the days from January 1st to Closing Date
    let daysOwed = 0;
    for (let month = 0; month < closingDateObj.getMonth(); month++) {
        daysOwed += [31, febDays, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
    }
    daysOwed += closingDateObj.getDate();

    // ✅ Use `taxAmountAnnual` first, otherwise calculate using `taxRate`
    let annualPropertyTax = 0;
    if (taxAmountAnnual && parseFloat(taxAmountAnnual) > 0) {
        annualPropertyTax = parseFloat(taxAmountAnnual);
    } else if (taxRate && parseFloat(taxRate) > 0) {
        annualPropertyTax = salesPrice * (parseFloat(taxRate) / 100);
    } else {
        return 0; // If neither taxAmountAnnual nor taxRate is provided, return 0
    }

    // ✅ Compute Prorated Taxes (Fixes rounding issues)
    const proratedTaxes = ((annualPropertyTax / (isLeapYear ? 366 : 365)) * daysOwed).toFixed(2);

    console.log(`Annual Property Tax: $${annualPropertyTax}`);
    console.log(`Days Seller Owes: ${daysOwed}`);
    console.log(`Prorated Taxes Owed: $${proratedTaxes}`);

    return parseFloat(proratedTaxes); // Return as a number
  };

  
  // const calculateTitleInsurance = (salesPrice) => {
  //   if (salesPrice <= 100000) return 832;
  //   if (salesPrice > 100000 && salesPrice <= 1000000) {
  //     return 832 + (salesPrice - 100000) * 0.00527;
  //   }
  //   return 832 + (900000 * 0.00527);
  // };

  const calculateTitleInsurance = (salesPrice) => {
    if (salesPrice <= 100000) return 832;
    let base = 832;
    let extra = 0;
    if (salesPrice > 100000 && salesPrice <= 1000000) {
      extra = (salesPrice - 100000) * 0.00527;
    } else if (salesPrice > 1000000) {
      extra = (900000 * 0.00527) + ((salesPrice - 1000000) * 0.00432);
    }
    return Math.round(base + extra);
  };


  const resetForm = () => {
    setFormData({
      calcType: "Conventional",
      price: "",
      sellerBF_value: "3",
      buyerBF_value: "3",
      BF_sellerbuyer_Split: "Seller",
      SellerConcession_seller: "0",
      repairs: "",
      miscCosts: "",
      taxPercent: "2.59",
      taxAmountAnnual: "953.15",
      loanBalance: "0",
      interestRate: "4.25",
      closingDate: formattedDate,
      serviceContract: "0",
      miscellaneous: "0",
    });
  
    setShowResults(false);
    setCalculatedData(null);
    setCustomNetAtClose("");
  };

  const toggleSaveEstimateModal = () => {
    setSaveEstimateModal(!saveEstimateModal);
    setEstimateName("");
  };

  const handleEstimateSelection = (estimate) => {
    setSelectedEstimate(estimate);
    setFormData(estimate);
    setShowResults(true);
    setActiveTab(1);
  };

  
  return (
    <>
      <Modal isOpen={showModal} toggle={hideModal} size="lg">
        <ModalHeader toggle={hideModal}>Seller Net Sheet</ModalHeader>
        <ModalBody>
          <Row>
            <p className="seller-net-sheet-description">See how much cash you could be walking with after selling your home!</p>
            <Col 
              className="seller-net-mob-form-container" 
              md={{ order: 1, size: showResults ? 6 : 8, offset: showResults ? 0 : 2 }} 
              xs={{ order: 2, size: 12 }}>
              <div className="seller-net-sheet-form-container">
                <form onSubmit={(e) => e.preventDefault()}>
                  <FormGroup>
                    <Label>Loan Type</Label>
                    <Input type="select" name="calcType" value={formData.calcType} onChange={handleChange}>
                      <option value="Cash">Cash</option>
                      <option value="USDA">USDA</option>
                      <option value="Conventional">Conventional</option>
                      <option value="FHA">FHA</option>
                      <option value="VA">VA</option>
                      <option value="Seller Finance">Seller Finance</option>
                    </Input>
                  </FormGroup>

                  <FormGroup>
                    <Label>Sales Price</Label>
                    <Input 
                      type="text" 
                      name="price" 
                      value={formData.price ? Number(formData.price).toLocaleString("en-US") : ""} 
                      onChange={handleChange}
                      placeholder="Required"
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>Seller Broker Fee (%)</Label>
                    <Input type="text" name="sellerBF_value" value={formData.sellerBF_value} onChange={handleChange} />
                  </FormGroup>

                  <FormGroup>
                    <Label>Buyer Broker Fee (%)</Label>
                    <Input type="text" name="buyerBF_value" value={formData.buyerBF_value} onChange={handleChange} />
                  </FormGroup>

                  <FormGroup>
                    <Label>Broker Fee Split</Label>
                    <Input type="select" name="BF_sellerbuyer_Split" value={formData.BF_sellerbuyer_Split} onChange={handleChange}>
                      <option value="Buyer">Buyer</option>
                      <option value="Seller">Seller</option>
                      <option value="Split">Split</option>
                    </Input>
                  </FormGroup>

                  <FormGroup>
                    <Label>Seller Concession</Label>
                    <Input type="text" name="SellerConcession_seller" value={formData.SellerConcession_seller} onChange={handleChange} />
                  </FormGroup>

                  <FormGroup>
                    <Label>Repairs</Label>
                    <Input type="text" name="repairs" value={formData.repairs} onChange={handleChange} />
                  </FormGroup>

                  <FormGroup>
                    <Label>Misc. Costs</Label>
                    <Input type="text" name="miscCosts" value={formData.miscCosts} onChange={handleChange} />
                  </FormGroup>

                  <div style={{marginBottom: 16}}>
                    <FormGroup className="mb-0">
                      <Label className="label-font">Tax Type:</Label>
                    </FormGroup>
                    <FormGroup check className="radio-btn">
                      <Label>
                        <Input
                          type="radio"
                          name="taxType"
                          value="percent"
                          checked={taxType === "percent"}
                          onChange={() => {
                            setTaxType("percent");
                            setFormData({ ...formData, taxAmountAnnual: "" });
                          }}
                        />{" "}
                        <span>Percentage (%)</span>
                      </Label>
                    </FormGroup>
                    <FormGroup check className="radio-btn">
                      <Label>
                        <Input
                          type="radio"
                          name="taxType"
                          value="amount"
                          checked={taxType === "amount"}
                          onChange={() => {
                            setTaxType("amount");
                            setFormData({ ...formData, taxPercent: "" });
                          }}
                        />{" "}
                        <span>Annual ($)</span>
                      </Label>
                    </FormGroup>
                  </div>

                  <FormGroup>
                    <Label>Property Taxes (%)</Label>
                    <Input type="text" name="taxPercent" value={formData.taxPercent} disabled={taxType !== "percent"} onChange={handleChange} />
                  </FormGroup>

                  <FormGroup>
                    <Label>Annual Tax Amount</Label>
                      <Input type="text" name="taxAmountAnnual" value={formData.taxAmountAnnual} disabled={taxType !== "amount"} onChange={handleChange} />
                  </FormGroup>

                  <FormGroup>
                    <Label>Seller Loan Balance</Label>
                    <Input type="text" name="loanBalance" value={formData.loanBalance} onChange={handleChange} />
                  </FormGroup>

                  <FormGroup>
                    <Label>Seller Interest Rate</Label>
                    <Input type="text" name="interestRate" value={formData.interestRate} onChange={handleChange} />
                  </FormGroup>

                  <FormGroup>
                    <Label>Closing Date</Label>
                    <Input type="date" name="closingDate" value={formData.closingDate} onChange={handleChange} />
                  </FormGroup>

                  <Button type="submit" onClick={handleCalculateClick}>Calculate</Button>
                  {showResults && <button className="save-estimate-btn" onClick={toggleSaveEstimateModal}>Save Estimate</button>}
                </form>
              </div>
            </Col>
            {showResults && <Col md={{ order: 2, size: 6 }} xs={{ order: 1, size: 12 }} className="pr-0">
              <div className="tab-button-group">
                <button 
                  className={`tab-button ${activeTab === 0 ? "active" : ""}`} 
                  onClick={() => switchTab(0)}
                >
                  Multiple Offers
                </button>
                <button 
                  className={`tab-button ${activeTab === 1 ? "active" : ""}`} 
                  onClick={() => {
                    handleCalculateClick()
                    switchTab(1)}
                  }
                >
                  Net Sheet
                </button>
                <button 
                  className={`tab-button ${activeTab === 2 ? "active" : ""}`} 
                  onClick={() => switchTab(2)}
                >
                  Saved Estimates
                </button>
              </div>
              <div className="net-sheet-result-container"> 
                {activeTab === 1 && <div className="seller-net-chart-container text-center">
                  <h4 className="mb-4">Estimated Proceeds</h4>
                  <img className="refresh-icon" onClick={resetForm} src={require("../../src/assets/icons/refresh.png")}/>
                  <div className="seller-net-doughnut-container">
                    <Doughnut data={data} options={options}  width={160} height={160} />
                    <div style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        textAlign: "center",
                    }}>
                      {isEditingNetAtClose ? (
                        <input 
                          type="text"
                          className="net-sheet-edit-input"
                          value={customNetAtClose}
                          onChange={handleNetAtCloseChange}
                          onKeyDown={handleNetAtCloseKeyPress}
                          onBlur={() => {
                            handleNetAtCloseKeyPress({ key: "Enter" });
                          }}
                          autoFocus
                        />
                      ) : (
                        <strong className="net-sheet-result">
                          ${Number(customNetAtClose).toLocaleString()}
                        </strong>
                      )}
                      {!isEditingNetAtClose && (
                        <p 
                          className="net-sheet-edit-btn"
                          onClick={handleEditClick}
                        >Edit</p>
                      )}
                    </div>
                  </div>
                  <div className="net-sheet-summary mt-4">
                    <div className="net-sheet-row">
                      <div className="net-sheet-label">
                        <span className="dot" style={{ background: "#D3D3D3" }}></span>
                        Sales Price
                      </div>
                      <div className="net-sheet-value">${Number(formData?.price).toLocaleString()}</div>
                    </div>

                    <div className="net-sheet-row">
                      <div className="net-sheet-label">
                        <span className="dot" style={{ background: "#32CD32" }}></span>
                        Loan Balance
                      </div>
                      <div className="net-sheet-value">${Number(formData?.loanBalance).toLocaleString()}</div>
                    </div>

                    <div className="net-sheet-row">
                      <div className="net-sheet-label">
                        <span className="dot" style={{ background: "#6D4C41" }}></span>
                        Prorated Tax Owed
                      </div>
                      <div className="net-sheet-value">${Number(calculatedData?.propertyTaxes).toLocaleString(undefined, { minimumFractionDigits: 1 })}</div>
                    </div>

                    <div className="net-sheet-row">
                      <div className="net-sheet-label">
                        <span className="dot" style={{ background: "#FFA500" }}></span>
                        Closing Costs
                      </div>
                      <div className="net-sheet-value">${Number(calculatedData?.closingCosts).toLocaleString(undefined, { minimumFractionDigits: 1 })}</div>
                    </div>

                    <div className="net-sheet-row">
                      <div className="net-sheet-label">
                        <span className="dot" style={{ background: "#FF4500" }}></span>
                        Seller Concessions
                      </div>
                      <div className="net-sheet-value">
                        ${Number(formData?.SellerConcession_seller).toLocaleString()}
                      </div>
                    </div>

                    <div className="net-sheet-row">
                      <div className="net-sheet-label">
                        <span className="dot" style={{ background: "#00BFFF" }}></span>
                        Service Contract
                      </div>
                      <div className="net-sheet-value">
                      ${Number(formData?.serviceContract).toLocaleString()}
                      </div>
                    </div>

                    <div className="net-sheet-row net-sheet-total">
                      <div className="net-sheet-label">
                        <span className="dot" style={{ background: "#3366FF" }}></span>
                        Net at Close
                      </div>
                      <div className="net-sheet-value">${Number(calculatedData?.netAtClose).toLocaleString()}</div>
                    </div>
                    <Button
                      className="view-scoring-details-btn"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleClosingCosts();
                      }}
                    >
                      {showClosingCosts ? "Hide Detailed Closing Costs" : "View Detailed Closing Costs"}
                      <img
                        className={showClosingCosts ? "arrow-down-flipped" : "arrow-down-unflipped"}
                        src={require("../assets/icons/list-arrow-down-green.png")}
                      />
                    </Button>
                    {showClosingCosts && (
                      <div className="detailed-closing-costs">
                        <h5 className="mt-3">Detailed Closing Costs</h5>
                        <div className="cost-item" style={{borderBottomWidth: 0}}><span>Total Closing Costs:</span> <span>${parseFloat(calculatedData?.closingCosts).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</span></div>
                        {/* Title Fees Section */}
                        <div className="closing-cost-section">
                          <h6>Title Fees</h6>
                          <div className="cost-item"><span>Owner's Title Policy:</span> <span>${parseFloat(calculatedData?.titleInsurance).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></div>
                          <div className="cost-item"><span>Escrow Fee:</span> <span>$525.00</span></div>
                          <div className="cost-item"><span>Attorney Fee (Deed):</span> <span>$150.00</span></div>
                          <div className="cost-item"><span>Attorney Fee (Release of Lien):</span> <span>$100.00</span></div>
                          <div className="cost-item"><span>Tax Certificate:</span> <span>$65.00</span></div>
                        </div>
                        {console.log(calculatedData?.specialLoanFee, "calculatedData?.specialLoanFee")}
                        {/* Other Fees Section */}
                        <div className="closing-cost-section mt-3">
                          <h6>Other Fees</h6>
                          <div className="cost-item"><span>Buyer Broker Fee:</span> <span>${parseFloat(calculatedData?.buyerBF).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></div>
                          <div className="cost-item"><span>Seller Broker Fee:</span> <span>${parseFloat(calculatedData?.sellerBF).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></div>
                          <div className="cost-item"><span>Service Contract:</span> <span>${parseFloat(formData.serviceContract).toLocaleString()}</span></div>
                          <div className="cost-item"><span>Miscellaneous:</span> <span>${parseFloat(formData.miscellaneous).toLocaleString()}</span></div>
                          {calculatedData?.specialLoanFee > 0 && (
                            <div className="cost-item">
                              <span>{calculatedData?.specialLoanFeeLabel}:</span>
                              <span>${parseFloat(calculatedData?.specialLoanFee).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>}
                {activeTab === 2 && (
                  <div>
                    {offer.net_a_seller_estimates?.length ? offer.net_a_seller_estimates?.map((estimate, index) => (
                      <div key={index} className="estimate-item" onClick={() => handleEstimateSelection(estimate)}>
                        <p><strong>{estimate.estimateName}</strong></p>
                        <p>Offer Price: ${Number(estimate.price).toLocaleString()}</p>
                        <p>Net at Close: ${Number(estimate.netAtClose || 0).toLocaleString()}</p>
                      </div>
                    )) : <p>No estimates found</p>}
                  </div>
                )}
              </div>
            </Col>}
          </Row>
        </ModalBody>
        <ModalFooter className="seller-net-footer">
          <Button color="secondary" onClick={hideModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
      
      {/* Save Estimate Modal */}
      <Modal isOpen={saveEstimateModal} toggle={toggleSaveEstimateModal}>
        <ModalHeader toggle={toggleSaveEstimateModal}>Save Estimate</ModalHeader>
        <ModalBody>
          <p>
            Save this estimate for future reference and easy comparison. 
            This allows you to revisit your projected net proceeds, closing costs, 
            and other key financial details at any time. 
            Keep track of multiple scenarios and make informed decisions when selling your home.
          </p>
          <FormGroup>
            <Label>Estimate Name</Label>
            <Input
              type="text"
              placeholder="Enter estimate name"
              value={estimateName}
              onChange={(e) => setEstimateName(e.target.value)}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleSaveEstimateModal}>Cancel</Button>
          <Button color="primary" onClick={handleSaveEstimate} disabled={savingEstimate}>
            {savingEstimate ? "Saving..." : "Save"}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default SellerNetSheetModal;